/*

  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  copyright - CONECTERE.

*/

/* FONTS */


@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Abel&family=Montserrat:wght@100;200;300;400&family=Patua+One&display=swap'); 
@import url('https://fonts.googleapis.com/css?family=Montserrat:400,600,700&display=swap');  
@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');

/* Weback config */
iframe #webpack-dev-server-client-overlay{display:none!important}  /* Suppress error message 'ResizeObserver loop completed with undelivered notifications.'  */

	/* 
	
	Copy these into CSS where needed 
	
	font-family: 'Abel', sans-serif;
	font-family: 'Montserrat', sans-serif;            
	font-family: 'Inter', sans-serif;
	font-family: 'Sora', sans-serif;
	font-family: 'Inter', sans-serif; 
	font-family: 'Raleway', sans-serif; 



Leader Board: Montserrat
Text and headers: Abel
*/



/*. Our Main Ring Colors:
5DADE2 - Balance (blue)
BB8FCE - Team (purple)
82E0AA - Social (green)
F7DC6F - Personal (yellow)

Our Main Ring Colors (RGB):

93, 173, 226  - Balance (blue)
187,143,206 - Team (purple)
130, 224, 170  - Social (green)
247, 220, 111 - Personal (yellow)
64, 156, 247 - Blue Section Header (#409cf7)

Main Section Color
#409cf7  (blue for headers)
#fafafa (Very light gray for backgrounds)
#f7f7f7 - slightly darker "light gray" for messaging area, etc.
#d5d6db -  useful medium gray background for disabled

Primary text colors 
#409cf7    (blue)
#9AC7F4    (light blue)
var(--grey-blue)    (grey blue)
#a1a6b5    (grey)
#36454f    (charcoal)
#414a4c    (soft black)
#e1e1e1    (silver)

#d4af37    COLOR_GOLD
#aaa9ad    COLOR_SILVER
#b08d57    COLOR_BRONZE

#116e02 Community Service Green
#f203ff DEI Purple


MEDALS
d4af37     GOLD
aaa9ad     SILVER
b08d57    BRONZE

Bow shadowing
	-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
	box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);
	
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	-webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

	
Text Shadowing
	text-shadow: 7px 8px 20px rgba(93,173,226,0.53);

*/

/* Z-Index Architecture

	
	9999 Spinners
	999 Modals NoBackground & NoBackgroundFixed
	900 Special Events Announcements Banners

*/


/* Default CSS for each component */
:root{
	--page-background: #fff;                        /* Background for pages, redefined automatically in Dark Mode */
	--card-background: #fff;                        /* Background for cards, redefined automatically in Dark Mode */
	--page-text: #000;
	--header-background: #f7f8fa;

	/* --light-mode-background:#f7f8fa; */
	--light-mode-background:#fff;
	--dark-mode-background:#000;
	/* --dark-mode-card-background:#554a4a9d; */
	--dark-mode-card-background:#2a2e33;
	--dark-mode-header-background: #414750;
	--dark-mode-border-color: #414750;
	--light-mode-card-background:#fff;
	--rich-black:#0A0A0A;
	--rich-black_v2:#121212;
	--white-smoke: #F5F6FA;
	--white-smoke-V2: #F2F4F7;
	--soft-grey:#f7f8fa;
	--dark-grey:#373737;
	--davy-grey:#555555;
	--dust-grey: #a09fa3;
	--light-grey:#d5d6db;
	--white:#fff;
	--black:#000;   
	--dust:#9B9B9B;
	--pictonBlue : #5EACE1;
	--grey-blue: #6495ED;
	--paleTeal: #71CD98;
	--lightYellow:#ECD367;
	--HEADER-blue2: #409cf7;
	--HEADER-blue: #5EACE1;            /* 3.10 changed away from HEADER blue to pictonBlue which is the same as web */
	--BALANCE-blue:#5DADE2;
	--LIGHT-purple: #F5EEF8;
	--TEAM-purple:#BB8FCE;
	--SOCIAL-green:#82E0AA;
	--GROWTH-yellow:#F7DC6F;
	--DEI-purple: #b37fca;
	--CS-green: #2cbc6c;
	--Malachite: #00CD15;
	--gold: #d4af37;
	/*--gold:#f3c328;*/
	--silver:#aaa9ad;
	--bronze:#CC8D33;
	--orange:#FF7700;
	--ALERT-red:#cc3232;
	--ALERT-red-opaque:"rgba(204,50,50,0.5)";
	--WARNING-yellow:#fde52f;
	--WARNING-yellow-opaque:"rgba(253,229,47,0.5)";
	--saffron: #F3C025;

	--border-color-V2: #d5d6db;
	--gloomy-purple: #7F56D9;
}

.darkMode {
	--card-background:#2a2e33;
	--page-background:#000;
	--page-text: #fff;
	--header-background:#414750;
	--border-color-V2: #ffffff;
	--white-smoke-V2: #000000;
}

body{
	overflow-x: hidden; /* Hide horizontal scrollbar */
	font-family: 'Inter', sans-serif ;
	font-size: 18px;
	/*line-height: 28px;*/
	font-weight: 400;
}

.boxShadow {
	box-shadow: rgba(255, 255, 255, 0.4) 1px 2px 10px -1px, rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.noBoxShadow {
	box-shadow:none !important
}
.borderRadius {
	border-radius: clamp(4px,0.4vw,10px);
}

.borderRadius-xs-V2 {
	border-radius: clamp(1.5px,0.3vw,3px) !important;
}

.borderRadius-V2 {
	border-radius: clamp(10px,0.6vw,12px) !important;
}

.bold {
	font-weight: 600 !important;
}

.bold-700 {
	font-weight: 700 !important;
}

.bold-800 {
	font-weight: 800 !important;
}

.padding-none {
	padding: 0 !important;
}

.margin-none {
	margin: 0 !important;
}

.border-none {
	border: none !important;
}

.italic {
	font-style: italic !important;
}
.underline {
	text-decoration: underline !important;
}
.opacity1 {
	opacity: 1 !important;
}
.pageWrapper{
	width:100%;
	min-height: 100vh;
	position:relative;
	font-family: 'Inter', sans-serif;
	font-weight: 400;    
	font-size: 18px;
	color: var(--HEADER-blue) ;
	/* background-color: var(--soft-grey) ; */
	background-color: var(--page-background);;
	/* padding-top: clamp(52px,4vh,80px);         /*Top padding matches the MAX HEIGHT setting in our Header*/

	/*padding-bottom:20vh;        /* General padding before our footer; using padding instead of margin so background matches dark mode setting of page wrapper */
	overflow-x: hidden;
	
}

.pageLayoutWrapper {
	height: 100vh;
	overflow: hidden;
	padding: 0;
	display: flex;
	flex-direction: column;
	background-color: var(--white);
}

.darkMode .pageLayoutWrapper {
	background-color: var(--black);
}

.pageLayoutInnerWrapper {
	display: flex;
	flex-grow: 1; /* Allow it to grow and take up available space */
	overflow: hidden;
}

.pageLayoutLeftContainerWrapper {
	padding: clamp(10px, 1.5vh, 15px);
	padding-right: 0;
	width: fit-content;
}

.pageLayoutRightContainerWrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    /* position: relative; */
    width: 100%;
    /* max-width: 1440px; */
    margin-left: auto;
    margin-right: auto
}

.pageLayoutRightContainerWrapperConectere {
	max-width: 95vw;
}
.pageLayoutContentContainerWrapper {
	padding-right: clamp(20px, 2vw, 25px);
	padding-left: clamp(20px, 2vw, 25px);
	padding-top: clamp(10px, 2vw, 15px);
}

.darkMode {
	background-color: var(--dark-mode-background);
	color:var(--white);
}

/* NAVIGATION CSS INCLUDING MAIN HEADER ON TOP OF EVERY PAGE */
/* Position is FIXED to remain always on TOP */

nav {
	display: flex;
	align-items: center;
	justify-content: center;
  
	/*vertical-align: bottom;*/
	/*min-height: 20vh;*/
	/*padding: 1px 1px;*/
	/*width: 90%;*/
	margin: auto;
	/*font-size: 5em;*/
	text-decoration: none;
	float: none;
	/*background-color: purple;*/
  }
  
  nav ul {
	display: flex;
	flex-wrap: wrap;    /*Allow center menu items to wrap*/
  
	/*flex: 1 1 40rem;*/
	align-items: center;
	justify-content: center;
	list-style: none;
	text-decoration: none;
	/*vertical-align: center;*/
	/*position: absolute;*/
	/*right: 0;*/
	  font-family: 'Inter', sans-serif;
	  /*background-color: red;*/
  
  }
  
  nav ul li {
	  /*float: left*/
	  /*flex: 1;*/
	  color: white;
	  font-family: 'Inter', sans-serif;
	  /*font-size: medium;*/
	  /*background-color: orange;*/
	  padding: 0 clamp(4px,2vw,14px) 0 clamp(4px,2vw,14px);
	text-decoration: none;
  
  }
  
  nav ul li a {
	/*display: block;*/
	color: white;
	text-decoration: none;
	text-align: center;
	/*padding: 14px 15px;      */
	/*vertical-align: bottom;*/
	font-family: 'Inter', sans-serif;
	font-size: larger;
	font-weight: bold;
	/*padding: 0 20px 0 20px;*/
	  /*background-color: yellow;*/
	/*float: left;*/
  }
  
  
  nav ul li:hover:not(.active) {
	  background-color: #6495ED;
	  color: white;
	  border-radius: 15px;
	  text-decoration: none;
	  /*vertical-align: bottom;*/
	}

/* Editor */
.tox-tinymce-aux{z-index:99999999999 !important;}   /* To ensure the AUX div for the toolbar is above everything */

.topBar {
	position:fixed;
	top:0;
	font-family: 'Inter', sans-serif;
	background-color: var(--soft-grey);
	/* background-color: var(--white); */
	/* background-color: yellow; */
	display: flex;
	flex-wrap: nowrap;    /*No wrap for the three elements (Left, Center, Right) although they can wrap internally*/
	align-items: center;
	justify-content: space-between;
	max-height: clamp(52px,4vh,80px);         /*Top padding in PageWrapper matches this MAX HEIGHT setting in our Header*/

	/*overflow-x:scroll;*/
	/*overflow-y: visible;    /* Needed to show Sidebar Menu, which is positioned relative to this element's childred but oustside the top bar */
	width: 100vw; 
	z-index: 9000;          /* Modals at 9999 will pass abovr this; opaque containers as 9999 will be above this */
	/*height:80px;   */
	height:auto;         /*Allow to respond dynamically to child elements */
	/*border-bottom-color: #6495ED;*/
	/*border-bottom-width: 5px;  */
	/*border-style: solid;*/
  }
  .darkMode .topBar{
	background-color: var(--dark-mode-background);
  }

.navBar {
	width: 100%;
	position: sticky;
	padding: clamp(20px, 3vh, 25px) clamp(20px, 3vw, 25px);
	background-color: var(--white);
	z-index: 100;
}

.darkMode .navBar{
	background-color: var(--black);
}

.headerChildLeft {
	flex-shrink: 0;
	flex-grow:0;
	display: flex;    /*flex within this container */
	/*flex-shrink:none;*/
	/*padding: 0px 3px 0px 3px;*/
	align-items: center;
	justify-content: flex-start;
	height:100%;
	/*background-color: yellow;*/
	min-width: clamp(200px,14vw,380px);
	position: relative;
	
	/*margin-bottom: 2px;*/
	
}

.headerChildCenter {
	flex-shrink: 1;
	flex-grow:1;
	display:flex;
  align-items: center;
  justify-content: center;
  height:100%;
  width:100%;

  margin-bottom: 2px;
 
	/*padding: 0px 3px 0px 3px;*/
  /*background-color: green;*/
}


.headerChildRight {
	flex-shrink: 0;
	flex-grow:0;   
	display: flex;    /*flex within this container */
	/*flex-shrink:none;*/
	align-items: center;
	justify-content: flex-end; 
	flex-wrap:nowrap;
	min-width: 14vw;
	height:100%;
	/* padding-right:clamp(4px,0.5vw,8px); /* Padding for vertical scroll bar on some browsers */
}

.navbarRightContainerSpacing {
	gap: 15px;
}

.headerChildRightInnerWrap {
	display: flex;    /*flex within this container */
	/*flex-shrink:none;*/
	align-items: center;
	justify-content: space-around; 
	flex-wrap:nowrap;
	height:auto;
	padding: clamp(2px,0.5vw,5px) clamp(4px,1vw,10px);
	/* min-width: 14vw; */
	min-height:50px;
	background-color: var(--HEADER-blue);
	border-radius: 0.2vw;
	/*background-color: violet;*/
	/*margin-right:20px; */
	/*margin-bottom: 4px;*/
	/*margin-top:10px;*/
	/*margin-left: 22px;*/
	/* overflow: hidden;     */
	
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.headerAwardWrapper {
	border-top-left-radius: clamp(4px,1vw,10px);
	border-bottom-left-radius: clamp(4px,1vw,10px);
	padding-left:clamp(4px,1vw,14px);
	padding-right:clamp(4px,1vw,14px);
	transform:translate(clamp(4px,1vw,14px), 0);
}

.navbarAwardWrapper {
	border-top-right-radius: clamp(4px,1vw,10px);
	border-bottom-right-radius: clamp(4px,1vw,10px);
	padding-left: clamp(16px, 1.3vw, 25px);
	padding-right: clamp(4px,1vw,14px);
	padding-top: clamp(1.5px, 0.3vw, 3px);
	padding-bottom: clamp(1.5px, 0.3vw, 3px);
	transform:translate(clamp(-16px,-2vw,-28px), 0);
	min-width: clamp(30px, 5vw, 50px);
	text-align: center;
	box-shadow: 0px 4px 24px -8px rgba(0, 0, 0, 0.5);
}

.userCredentialFont {
  font-family: 'Inter', sans-serif;
  font-size: large;
  font-weight: bold; 
}

.signoutStyle {/*text-align:right;*/ width: 12rem }

/* Let's specify CSS for the navigation bar */


.topBarNav {
  /*display: flex;*/
  /*flex-wrap: wrap;*/
  /*align-items: center;*/
  /*vertical-align: bottom;*/
  /*min-height: 20vh;*/
  /*padding: 1px 1px;*/
  /*width: 90%;*/
  margin: auto;
  font-size: 3em;
  text-decoration: none;
  float: left;
}

.homeLogoLink {
	color: white;
	text-decoration: none;   
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-size: 18px;
	font-weight: 400;
	padding: 0 14px 0 14px;
	height:100%;
	/*background-color: white;*/
}

.homeLogoLink:hover:not(.active) {
	/*background-color: white;*/
	color: #409cf7;
	/*border-radius: 5px;*/
	text-decoration: none;  
	font-weight: bold;
	text-shadow: 5px 4px 15px rgba(93,173,226,0.53);
	/*Let's highlight the active item and when and item is hovered over 
	
	/*border-bottom: 2px solid currentColor;*/
}

/*Flex box for contaiing a link; it will center and style any text and will provide relative positioning for any badges*/
.headerLink {
	position:relative;
	display:flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;   
	text-align: center;
	color: var(--white);
	font-weight: 500;
	font-size: clamp(0.8rem, 1vw, 1.3rem);
	font-family: 'Raleway', sans-serif;
	margin: 0 clamp(2px,0.4vw,4px);
	min-height:clamp(16px,2vh,20px);
	padding: clamp(2px,0.3vw,4px) clamp(2px,0.3vw,4px);

}

.headerLinkDarkMode {
	color: var(--HEADER-blue);
}

.headerLinkSelected {
	border-bottom: 2px solid white;
}

.headerLinkSelectedDarkMode {
	border-bottom: 2px solid var(--BALANCE-blue);
}

.headerLink:hover:not(.active) {
	background-color: white;
	color:var(--BALANCE-blue) ;
	border-radius: 5px;
	text-decoration: none;  
	/*margin-bottom: 10px;*/
	/*margin-top: clamp(4px,1vh,10px);*/
	height:80%;
}

.headerLinkDarkMode:hover:not(.active) {
   color: white;
   background-color: var(--BALANCE-blue) !important;
}

/* headerLinkIcon.{
	display:none;
} */
.headerLinkButton {
	color: white;
	text-decoration: none;   
	text-align: center;
	font-family: 'Inter', sans-serif;
	font-size: larger;
	font-weight: bold;
	margin:0px 16px;
	/*height:60%;*/
}

.headerLinkText {
	padding-left:clamp(3px,0.5vw,8px);
}

.headerLinkHoverText {
	visibility:hidden;
}

.headerChildBadges {
	 color: gold;
	 font-family: 'Inter', sans-serif;
	 font-size: 1em;    
}

.headerMenuCompressedContainer {
	position:relative;
	display:none;
}

.headerDarkModeContainer {
	transform:translate(-25%, +25%);
}

/* USER ACCOUNT PROFILE */


.ProfileModalContainer {
	position: absolute;
	top:0;
	left:0;
	height:100vw;
	width:100vw;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 400px;

	/*background:red*/
}

.ProfileBackgroundContainer {
	z-index: 99;
	position:absolute;
	/*background-color: red;*/
	top:0px;
	left:0px;
	min-width :100%;
	min-height:100%;
	opacity: 0.3;
}

.ProfileAvatarUploadContainer {
	flex: 1;
	display:flex;
	flex-direction:column;
	height: 100%;
	width: 100%;
	align-items: center;
	justify-content: center;
}

.ProfileIcon {
	font-size:18px;
	font-weight: 700;
	width:20px;
}


.ProfileNameDivider {
	background-color: var(--TEAM-purple);;
	color: var(--TEAM-purple);;
	border-bottom-color: white;
	border-bottom-width: clamp(2px,1vw,5px); 
	border-radius: 2px;
	border-style: solid;
	opacity: 0.7;
	width:80%;
}


.headerBalances {
	position:relative;
	text-align: center; 
	color: var(--HEADER-blue);
	margin: 0px;
	padding: 0px 10px; 
}


.ContainerChildBadges {
	 color: gold;
	 font-family: 'Inter', sans-serif;
	 font-size: 28px;
	 font-weight: 600;
	 /*align-items: center;*/
	/*justify-content: center;*/

}


.headerButtonStyle1 {
	/*border: 1px solid var(--TEAM-purple);;*/
	border:none;
	cursor: pointer;
	color:#fff;
	outline: none;
	border-radius: 0.2vw;
	/*margin-top: 1rem;*/
	background-color: #82E0AA;
	padding: clamp(2px,0.2vw,4px) clamp(2px,0.3vw,5px) ;
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 1.2rem;
}

.headerButtonStyle1:hover {
	color: #82E0AA;
	background-color: #fff;
	transition: all 0.5s;
	font-weight: 700;
}

.headerButtonStyle1:disabled {
	/*color: #000;*/
	color: #fff;
	/*background-color: rgba(187, 143, 206, 0.5);*/
	background-color: #a1a6b5;
	transition: all 0.5s;
	font-weight: 700;
	cursor: auto;
}

.headerButtonStyle2 {
	/*border: 1px solid var(--TEAM-purple);;*/
	border:none;
	cursor: pointer;
	color:#fff;
	outline: none;
	border-radius: 0.2vw;
	padding: clamp(2px,0.2vw,4px) clamp(2px,0.3vw,5px) ;
	/*margin-top: 1rem;*/
	background-color: #F7DC6F;
	/*background-color: rgba(187, 143, 206, 1);*/
	font-family: 'Inter', sans-serif;
	font-weight: 600;
	font-size: 1.2rem;

	
}

.headerButtonStyle2:hover {
	/*color: #000;*/
	color: #F7DC6F;
	/*background-color: rgba(187, 143, 206, 0.5);*/
	background-color: #fff;
	transition: all 0.5s;
	font-weight: 700;
}

.notificationBubble {
	/*padding-right:5px; */
	border-radius:50%; 
	background-color:yellow; 
	color:black;
	position: absolute; 
	width: 18px; 
	height: 18px; 
	right:-5px;
	top:0px;
	display:flex;
	align-items:center;
	justify-content: center;
	overflow: hidden;
	font-size:12px;
	font-weight: 700;
}

/* PAGES */

.adminPage {
	max-width:100vw;
	display:flex;
	flex-direction: column;
	align-items:center;
	justify-content:center;
	margin: 20px auto;
}

/* HTML TAG OVERLOADS */
p {
   text-align: left;
}

/* Overload the Anchor tag as Boostrap was adding underline */
a {
	text-decoration: none !important;
}

li {
	text-align: left;
}

/* END TAG OVERLOADS */

.position-relative {
	position: relative;
}

.position-absolute {
	position: absolute;
}

.position-fixed {
	position: fixed;
}

.overflow-visible {
	overflow: visible !important;
}

.overflow-hidden {
	overflow: hidden !important;
}

/* COLOR CLASSES */

.headerBlue, .headerBlue-text {
	color: var(--HEADER-blue) !important;
}

.blue, .balance, .blue-text {
	color: var(--BALANCE-blue) !important;
}
.purple, .team, .purple-text{
	color: var(--TEAM-purple) !important;
}
.green, .green-text, .social{
	color: var(--SOCIAL-green) !important;
}
.yellow, .growth{
	color: var(--GROWTH-yellow) !important;
}

.light-yellow {
	color: var(--WARNING-yellow) !important;
}

.gold{
	color: var(--gold) !important;
}

.orange{
	color: var(--orange) !important;
}

.lightgold {
	color: #FFE26F !important;
}

.white, .white-text {
	color: white !important;
}

.black, .black-text {
	color: black !important;
}

.red{
	color: var(--ALERT-red) !important;
}

.grey, .grey-text {
	color: var(--davy-grey) !important;
}

.dark-grey{
	color:var(--dark-grey) !important;
}
.light-grey, .light-grey-text {
	color:var(--light-grey) !important;
}
.soft-grey, .soft-grey-text {
	color:var(--soft-grey) !important;
}
.dust-grey {
	color:var(--dust-grey) !important;
}
.silver {
	color:var(--silver) !important;
}

/* Text classes controlled by dark mode */

.darkMode {
	.grey-text, .white-text, .black-text, .blue-text, .green-text, .purple-text, .silver, .light-grey-text, .soft-grey-text {
		color:white !important;
	}
}

/* Backgrounds */

.opaque-background {
	background-color: rgba(0, 0, 0, 0.0) !important;
}
.white-background{
	background-color: var(--white) !important;
}

.black-background{
	background-color: var(--black) !important;
}

.dark-grey-background{
	background-color:var(--dark-grey) !important;
}

.header-blue-background{
	background-color: var(--HEADER-blue) !important;
}
.blue-background{
	background-color: var(--BALANCE-blue) !important;
}
.purple-background{
	background-color: var(--TEAM-purple) !important;
}
.green-background{
	background-color: var(--SOCIAL-green) !important;
}
.yellow-background{
	background-color: var(--GROWTH-yellow) !important;
}

.light-grey-background{
	background-color: var(--light-grey) !important;
}
.very-light-grey-background {
	background-color: var(--soft-grey) !important;
}

.very-white-smoke-background{
	background-color: var(--white-smoke) !important;
}

.darkMode {
	.white-background, .very-light-grey-background, .light-grey-background, .blue-background, .purple-background, .green-background, .yellow-background, .header-blue-background{
		background-color: var(--dark-mode-background) !important;
	}
}

.noBackground {
	background:none !important;
}

.darkMode .isCard {
	background-color: var(--dark-mode-card-background) !important;
}

.darkMode .isHeader {
	background-color: var(--dark-mode-header-background) !important;
}

/* End Backgrounds */


/* Borders */
.white-border{
	border-color: var(--white) !important;
}

.very-light-grey-border{
	border-color: var(--soft-grey) !important;
}

.light-grey-border {
	border-color: var(--light-grey) !important;
}

.blue-border{
	border-color: var(--BALANCE-blue) !important;
}
.purple-border{
	border-color: var(--TEAM-purple) !important;
}
.green-border{
	border-color: var(--SOCIAL-green) !important;
}
.yellow-border{
	border-color: var(--GROWTH-yellow) !important;
}
.header-blue-border{
	border-color: var(--HEADER-blue) !important;
}

.connectivity-card-blue-border{
	border-color: var(--BALANCE-blue) !important;
}
.connectivity-card-purple-border{
	border-color: var(--TEAM-purple) !important;
}
.connectivity-card-green-border{
	border-color: var(--SOCIAL-green) !important;
}
.connectivity-card-yellow-border{
	border-color: var(--GROWTH-yellow) !important;
}
.cardBG {
	background-color: var(--card-background) !important;
}

.darkMode {
	.white-border, .very-light-grey-border, .blue-border, .purple-border, .green-border, .yellow-border, .header-blue-border{
		border-color: var(--dark-mode-border-color) !important;
	}
}

.noBorder {
	border:none !important;
}
/* End Borders */

/* switch v2 style start */

.switch-V2 {
	width: 44px !important;
	height: 24px !important;
	padding: 0 !important;
	display: flex !important;
	align-items: center !important;
}

.switch-V2 .MuiSwitch-switchBase {
	padding: 2px !important;
}

.switch-V2 .MuiSwitch-switchBase.Mui-checked {
	transform: translateX(20px) !important;
	color: #fff !important;
}

.switch-V2 .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
	opacity: 1 !important;
	background-color: var(--gloomy-purple) !important;
}

.darkMode .switch-V2 .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track  {
	background-color: var(--white) !important;
}

.switch-V2 .MuiSwitch-thumb {
	box-shadow: 0 1px 2px 0 rgba(16, 24, 40, 0.06), 0 1px 3px 0 rgba(16, 24, 40, 0.06) !important;
	width: 20px !important;
	height: 20px !important;
	border-radius: 50% !important;
	background-color: #fff !important; 
	transition: width 200ms, transform 200ms !important;
}

.darkMode .switch-V2 .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb {
	background-color: #000 !important;
} 

.switch-V2 .MuiSwitch-track {
	border-radius: 13px !important;
	height: 24px !important;
	opacity: 1 !important;
	background-color: var(--white-smoke-V2) !important;
	box-sizing: border-box !important;
	transition: background-color 200ms !important;
}

.switch-V2:active .MuiSwitch-thumb {
	width: 20px;
}

.switch-V2:active .MuiSwitch-switchBase.Mui-checked {
	transform: translateX(20px);
}

/* switch v2 style end */

.footer {
	position:relative;
	font-family: 'Inter', sans-serif;
	display: flex;
	flex-direction:row;
	align-items: flex-start;
	justify-content: center;
	width: 100vw; 
	height: clamp(50px, 5vh, 100px);
	/*margin-top:20vh;*/
	z-index: 99;
  }

.footer-column{
	width: 25%;
	height: 100%;
	opacity: 0.3;
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	padding: 0px 10px;
	text-align:center;
}


/* BUTTONS */

.btn {
	display:flex !important;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	flex-wrap:nowrap;
}

.btn-primary{
	color: var(--white) !important;
	/*background-color: var(--HEADER-blue) !important;*/
	background-color: var(--BALANCE-blue) !important;;

	border: 2px solid var(--BALANCE-blue) !important;
	/*border: 2px solid var(--HEADER-blue) !important;*/
	font-weight: 600 !important;
	font-size: 1.0rem !important;
	line-height: 1.0rem !important;
	/*padding: 1.563rem 2rem 1.563rem 3.75rem  !important;*/
	/*border: 0 !important;*/
	border-radius: 0.3vw !important;
	transition: 0.3s all ease-in-out !important;
}
.btn-primary:hover{
	/*color: var(--HEADER-blue) !important;*/
	color: var(--BALANCE-blue) !important;
	background-color: var(--white) !important;
	/*transform: scale(1.1);*/
	
	/*padding-left: 3.2rem !important;*/
}

.btn-primary svg{
	transition: 0.3s all ease-in-out;
	margin-left: 5px;
	margin-right: 5px;
}

.btn-primary svg line{
	stroke:#FFF !important;
}
.btn-primary:hover svg line{
	stroke:var(--BALANCE-blue) !important;
}
.btn-primary svg path{
	fill:#FFF !important;
}
.btn-primary:hover svg path {
	fill: var(--BALANCE-blue) !important;
}

.btn-secondary{
	background-color: var(--davy-grey) !important;
	color: var(--white) !important;
	border: 2px solid var(--davy-grey) !important;
	font-weight: 600 !important;
	font-size: 1.0rem !important;
	line-height: 1.0rem !important;
	/*padding: 1.563rem 2rem 1.563rem 3.75rem  !important;*/
	/*border: 0 !important;*/
	border-radius: 0.3vw !important;
	transition: 0.3s all ease-in-out !important;
}
.btn-secondary:hover{
	color: var(--davy-grey) !important;
	background-color: var(--white) !important;
	transform: scale(1.1);
	/*padding-left: 3.2rem !important;*/
}

.btn-secondary svg{
	transition: 0.3s all ease-in-out !important;
	margin-left: 15px !important;
}

.btn-secondary:hover svg{
	width: 30px !important;
}

.btn-info{
	background-color: var(--TEAM-purple) !important;
	color: var(--white) !important;
	border: 2px solid var(--TEAM-purple) !important;
	font-weight: 600 !important;
	font-size: 1.0rem !important;
	line-height: 1.0rem !important;
	/*padding: 1.563rem 2rem 1.563rem 3.75rem  !important;*/
	/*border: 0 !important;*/
	border-radius: 0.3vw !important;
	transition: 0.3s all ease-in-out !important;
}
.btn-info:hover{
	color: var(--TEAM-purple) !important;
	background-color: var(--white) !important;
	transform: scale(1.1);
	/*padding-left: 3.2rem !important;*/
}

.btn-info svg{
	transition: 0.3s all ease-in-out !important;
	margin-left: 15px !important;
}

.btn-info:hover svg{
	width: 30px !important;
}

.btn-close {
	color: white !important;
}

.btn-community {
	position:relative;
	flex-wrap:nowrap !important;
	height:auto !important;
	padding: 5px 10px !important;
	
	font-size: clamp(0.8rem, 1vw, 1.3rem) !important;
	font-family: 'Raleway', sans-serif !important;
	margin: 0 0.3rem !important;
	min-height:50px  !important;
	
	background:black !important;
	border-radius: 0.2vw !important;
	/*border: 4px solid black !important;*/
	/*transition: 0.3s all ease-in-out !important;*/
	/*margin-left: 5px !important;*/
	/*margin-right: 5px !important;*/
	/*padding: 8px !important;*/
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	/*font-weight: 500 !important;*/
	/*font-size: clamp(0.8rem, 1vw, 1.3rem) !important;*/
	/*font-family: 'Raleway', sans-serif !important;*/
	transition: unset !important;
	
}

.btn-community:hover {
	/*border: 4px solid var(--HEADER-blue) !important;*/
	background:white !important;
}

.btn-gradient-community {
	border: 2px solid transparent;
	border-radius: clamp(12px, 0.5vw, 14px);
	padding-left: clamp(20px, 1vw, 30px);
	padding-right: clamp(20px, 1vw, 30px);
	padding-top: clamp(6px, 0.5vw, 8px);
	padding-bottom: clamp(6px, 0.5vw, 8px);
	cursor: pointer;
	background-image: linear-gradient(var(--white), var(--white)), 
					  linear-gradient(25deg, var(--TEAM-purple), var(--BALANCE-blue), var(--SOCIAL-green), var(--GROWTH-yellow));
	background-origin: border-box;
	background-clip: padding-box, border-box;
}

.darkMode .btn-gradient-community {
	background-image: linear-gradient(var(--dark-mode-background), var(--dark-mode-background)), 
					  linear-gradient(25deg, var(--TEAM-purple), var(--BALANCE-blue), var(--SOCIAL-green), var(--GROWTH-yellow));
}

.buttonStyle1 {
	display:flex !important;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	flex-wrap:nowrap;
	cursor: pointer;
	color:var(--white);
	border-radius: 0.3vw;
	margin-right:clamp(2px,0.3vw,4px);
	margin-left:clamp(2px,0.3vw,4px);   
	padding-left: clamp(4px,0.5vw,6px);
	padding-right: clamp(4px,0.5vw,6px);
	padding-top: clamp(2px,0.3vw,5px);
	padding-bottom: clamp(2px,0.3vw,5px);
	font-weight: 400;
	font-size: 0.8em;
	white-space: nowrap;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.buttonStyle1:disabled {
	background-color: var(--light-grey) !important;
	border: 2px solid var(--light-grey) !important;
	cursor:default;
	pointer-events: none;
}

.buttonStyle1HeaderBlue {
	border: 2px solid var(--HEADER-blue);
	background-color: var(--HEADER-blue);
}


.buttonStyle1HeaderBlue:hover {
	color: var(--HEADER-blue);
	background-color: var(--white);
	/*transform: scale(1.15);   */
	/*font-weight: 500;*/
}

.buttonStyle1-V2 {
	display:flex !important;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	flex-wrap:nowrap;
	cursor: pointer;
	color:var(--white);
	border-radius: 0.55vw;
	margin-right:clamp(2px,0.3vw,4px);
	margin-left:clamp(2px,0.3vw,4px);   
	padding-left: clamp(4px,0.5vw,6px);
	padding-right: clamp(4px,0.5vw,6px);
	padding-top: clamp(2px,0.3vw,5px);
	padding-bottom: clamp(2px,0.3vw,5px);
	font-weight: 400;
	font-size: 0.9em;
	white-space: nowrap;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.buttonStyle1CommonBlueBtn {
	border: 1px solid var(--HEADER-blue);
	background-color: var(--HEADER-blue);
	color: var(--white);
	border-radius: 0.55vw !important;
	/* padding: clamp(4px, 1.5vw, 8px) clamp(8px, 1.5vw, 15px); */
}

.buttonStyle1CommonBlueBtn:hover {
	color: var(--HEADER-blue);
	background-color: var(--white);
}

.buttonStyle1HeaderBlack {
	font-size: 0.75rem !important;
	font-weight: 600 !important;
	border: 1px solid var(--black);
	padding: 6px 12px;
	background-color: white;
	color: var(--black);
	border-radius: 8px !important;
	margin: 0 !important;
}


.buttonStyle1HeaderBlack:hover {
	color: var(--white);
	background-color: var(--black);
	/*transform: scale(1.15);   */
	/*font-weight: 500;*/
}

.connectivity-markAsDoneBtn {
	border: 1px solid var(--Malachite);
	padding: 6px 12px;
	background-color: transparent;
	color: var(--Malachite);
	border-radius: 8px !important;
}

.connectivitySkipButton {
	font-size: 0.75rem !important;
	color: var(--DEI-purple) !important;
}

.buttonStyle1Purple {
	border: 2px solid var(--TEAM-purple);
	background-color: var(--TEAM-purple);
}


.buttonStyle1Purple:hover {
	color: var(--TEAM-purple);
	background-color: var(--white);
	/*transform: scale(1.15);   */
	/*font-weight: 500;*/
}


.buttonStyle1Yellow {
	border: 2px solid var(--GROWTH-yellow);
	background-color: var(--GROWTH-yellow);
}


.buttonStyle1Yellow:hover {
	color: var(--GROWTH-yellow);
	background-color: var(--white);
	/*transform: scale(1.15);   */
	/*font-weight: 500;*/
}

.buttonStyle1Green {
	border: 2px solid var(--SOCIAL-green);
	background-color: var(--SOCIAL-green);
}


.buttonStyle1Green:hover {
	color: var(--SOCIAL-green);
	background-color: var(--white);

}


.buttonStyle1DavyGray {
	border: 2px solid var(--davy-grey);
	background-color: var(--davy-grey);
}


.buttonStyle1DavyGray:hover {
	color: var(--davy-grey);
	background-color: var(--white);
}

.buttonStyle1Red {
	border: 2px solid var(--ALERT-red);
	background-color: var(--ALERT-red);
}


.buttonStyle1Red:hover {
	color: var(--ALERT-red);
	background-color: var(--white);

}

.buttonStyle1small {
   border-radius: 0.3vw;
	margin-right:5px;
	margin-left:5px;   
	padding-left: clamp(2px,0.5vw, 6px);
	padding-right: clamp(2px,0.5vw, 6px);
	padding-top: clamp(2px,0.3vw, 4px);
	padding-bottom: clamp(2px,0.3vw, 4px);
	font-size: 0.7rem;
 }

.buttonStyle2 {
	position:relative;
	display:flex !important;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	flex-wrap:nowrap;

	/*border: 1px solid #BB8FCE;*/
	/* border:none; */
	border: 2px solid rgba(256, 256, 256, 0);
	cursor: pointer;
	color:var(--HEADER-blue);
	outline: none;
	border-radius: 0.3vw;
	margin-right:clamp(2px,0.3vw,4px);
	margin-left:clamp(2px,0.3vw,4px);   
	padding-left: clamp(4px,0.5vw,6px);
	padding-right: clamp(4px,0.5vw,6px);
	padding-top: clamp(2px,0.3vw,5px);
	padding-bottom: clamp(2px,0.3vw,5px);
	/*background-color:#fffede;*/
	background-color: rgba(256, 256, 256, 0);
	/*font-family: 'Abel', sans-serif;*/
	font-weight: 400;
	font-size: 1.0rem;
	transition: all 0.2s;
	transform:scale(1.0);
	white-space: nowrap;
	text-align: center;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.buttonStyle2:hover {
	transform: scale(1.15); 
	transition: all 0.2s;
	font-weight: 500;
}

.buttonStyle2:disabled {
	background-color: var(--light-grey);
	cursor:default;
	pointer-events: none;
}

.buttonStyle3 {
	/*border: 1px solid #BB8FCE;*/
	border:none;
	cursor: pointer;
	color:#fff;
	outline: none;
	border-radius: 0.3vw;
	margin-right:clamp(2px,0.3vw,4px);
	margin-left:clamp(2px,0.3vw,4px);   
	padding-left: clamp(4px,0.5vw,6px);
	padding-right: clamp(4px,0.5vw,6px);
	padding-top: clamp(2px,0.3vw,5px);
	padding-bottom: clamp(2px,0.3vw,5px); 
	background-color: var(--HEADER-blue);
	/*background-color: #82E0AA;*/
	/*background-color: rgba(187, 143, 206, 1);*/
	font-family: 'Abel', sans-serif;
	font-weight: 400;
	font-size: 1.3rem;
	transition: all 0.2s;
	color:white;
	
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.buttonStyle3:hover {
	transform: scale(1.15);   
	font-weight: 500;
}

.buttonStyle3:disabled {
	background-color: var(--light-grey);
	cursor:default;
	pointer-events: none;
}

.buttonStyle4 {
	text-align: center;
	flex-wrap:nowrap;
	cursor: pointer;
	color:var(--white);
	border-radius: 0.3vw;
	margin-right:clamp(2px);
	margin-left:clamp(2px);   
	overflow: hidden;
	transition: width 0.7s ease-in, opacity 0.7s ease-in, padding 0.7s ease-in, background 0.1s linear, color 0.1s linear;
	/*transition: all 0.5s;    */
	transition-timing-function: ease-in;
	white-space: nowrap;
	width:auto;
	padding-top: clamp(2px,0.5vw,8px); 
	padding-bottom: clamp(2px,0.5vw,8px); 
	padding-left: clamp(2px,0.5vw,8px);
	padding-right: clamp(2px,0.5vw,8px);  
	border: 0px;
	opacity: 1;
	border: clamp(1px,0.2vw,4px) solid rgba(0,0,0,0);
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.buttonStyle4:hover {
	border: 4px solid var(--HEADER-blue2) !important;
	/*text-decoration: underline;*/
	/*font-size: 1.0rem !important;*/
	/*font-weight: 600 !important;*/
	/*background-color: var(--HEADER-blue) !important;*/
	/*color: var(--white) !important;*/
}

.btn-invite-V2 {
	padding: 8px 16px;
	border-radius: 8px;
	gap: 8px;
	font-size: 0.875rem;
	font-weight: 600;
	margin: 0;
}

/* END BUTTONS */

/* CONTAINERS */

.homeContainer {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	font-size: 128px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.homeContainer2 {
	display:flex;
	flex-wrap: nowrap;
	align-items: flex-end;
	justify-content: center;
	font-size: 48px;
}

.homeContainer3 {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	/*padding: 20 20 20 20px;*/
	/*background-color: red;*/

}

.Container {
	display:flex;
	flex-wrap: nowrap;
	/*align-items: flex-start;*/
	/*justify-content: center;*/
}

.ContainerNoHeight {
	display:flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: center;
}

.ContainerNoHeightCenter {
	display:flex;
	flex-direction:row;
	/*flex-wrap: nowrap;*/
	align-items: center;
	justify-content: center;
}

.ContainerNoHeightSpaceBetween {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
}

.ContainerNoHeightSpaceAround{
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-around;
}

.ContainerNoHeightFlexEnd {
	display:flex;
	flex-wrap: nowrap;
	align-items: flex-end;
	justify-content: flex-end;
}

.ContainerNoHeightFlexEndCentered {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
}

.ContainerNoHeightFlexLeft {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
	/*background: yellow;*/
}


.ContainerNoHeightFlexLeftWrap {
	display:flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-start;
	/*background: yellow;*/
}

.ContainerNoHeightFlexLeftFlexStart {
	display:flex;
	flex-direction:row;
	flex-wrap: wrap;
	align-items:flex-start;
	justify-content: flex-start;
	/*background: yellow;*/
}

.ContainerRowLeftStretch {
	display:flex;
	flex-direction:row;
	flex-wrap: nowrap;
	align-items:stretch;            /*Stretch heights of child divs in the row */
	justify-content: flex-start;
}

.ContainerNoHeightFlexLeftTop {
	display:flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: flex-start;
	/*background: yellow;*/
}

.ContainerNoHeightFlexRightTop {
	display:flex;
	flex-wrap: nowrap;
	align-items: flex-start;
	justify-content: flex-end;
	/*background: yellow;*/
}

.ContainerNoHeightWrap {
	display:flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
}

.ContainerMaxHeightCenterFullWidth {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	height:100%;
	width:100%;
}

.ContainerMaxHeightCenter {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: center;
	height:100%;
}

.ContainerMaxHeightSpaceBetween {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-between;
	height:100%;
}


.ContainerMaxHeightSpaceAround {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-around;
	height:100%;
}

.ContainerMaxHeightSpaceAroundFullWidth {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: space-around;
	height:100%;
	width:100%;
}

.ContainerMaxHeightFlexLeft{
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-start;
	height:100%;
}

.ContainerMaxHeightFlexEnd {
	display:flex;
	flex-wrap: nowrap;
	align-items: center;
	justify-content: flex-end;
	height:100%;
}



.ContainerVertical {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/*background-color: red;*/
}

@keyframes slideUp {
	0% {
		opacity: 0;
		transform: translateY(6px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

@keyframes slideDown {
	0% {
		opacity: 0;
		transform: translateY(-20px);
	}

	100% {
		opacity: 1;
		transform: translateY(0);
	}
}

.top-to-bottom {
	animation: slideDown 1s ease-in-out;
}

.bottom-to-top {
	animation: slideUp 1s ease-in-out;
}
.ContainerVerticalStart {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	/*background-color: red;*/
}

.ContainerVerticalBottomUp {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-end;
	/*background-color: red;*/
}

.ContainerVerticalStartStart {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	/*background-color: orange;*/
}

.ContainerVerticalStartEnd {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-end;
	/*background-color: orange;*/
}

.ContainerVerticalTopRight {
	display:flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-Start;
	/*background-color: orange;*/
}

.ContainerVerticalCenter {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	/*background-color: red;*/
}

.ContainerVerticalCenterFullWidth {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width:100%;
	/*background-color: red;*/
}

.ContainerVerticalSpaceBetween {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	/*background-color: red;*/
}

.ContainerVerticalSpaceAround {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-around;
	/*background-color: red;*/
}

.ContainerVerticalLeft {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
	text-align: left;
	/*background-color: yellow;*/
}

.ContainerVerticalRightMaxWidth {
	display:flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: center;
	text-align: left;
	width:100%;
	/*background-color: pink;*/
}

.ContainerVerticalLeftStart {
	display:flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	text-align: left;
	/*background-color: red;*/
}

.ContainerChildBadges {
	 color: gold;
	 font-family: 'Inter', sans-serif;
	 font-size: 28px;
	 font-weight: 600;
	 /*align-items: center;*/
	/*justify-content: center;*/

}

.ContainerChild {
	/*flex: 1;*/
	padding: 0px 0px 0px 0px;
}

.fullWidth {
	width:100% !important;
}

.width-50 {
	width:50% !important;
}

.fullHeight {
	height: 100% !important;
}

.maxWidthSmall {
	max-width: clamp(300px, 50vw, 1200px);
}

.maxWidthLarge {
	max-width: clamp(600px, 80vw, 1800px);
}


.alignStart {
	align-items:flex-start !important;
}

.alignStretch {
	align-items:stretch !important;
}


.justifyStart {
	justify-content:flex-start !important;
}

.alignCenter{
	align-items:center !important;
}

.alignItems{
	align-items:center !important;
}

.alignEnd{
	align-items:flex-end !important;
}

.spaceAround {
	justify-content:space-around !important;
}

.justifyEnd {
	justify-content:flex-end !important;
}

.maxHeight {
	height:100% !important;
}

.wrap {
	flex-wrap:wrap !important;
}

.noWrap {
	flex-wrap:noWrap !important;
}

.noWordWrap {
	white-space:nowrap !important;
}

.noBoxShadow {
	box-shadow:none !important;
}


.scrollX {
	  overflow-x: scroll !important;
}

.scrollY {
	  overflow-y: scroll !important;
}

.positionRelative {
	position:relative;
}

.pointer {
	cursor:pointer !important;
}

.noPointer {
	pointer-events: none !important;
	cursor: auto !important;
}

.overflowVisible {
	overflow:visible !important;
}

.overflowHidden {
	overflow:hidden !important;
}

.hideShadow{
	box-shadow:none !important;
}

.textAlignCenter{
	text-align: center;
}

.textAlignLeft{
	text-align: left;
}

.noBorder {
	border:none !important;
}

.heavy {
	font-weight: 600 !important;
}

/* END CONTAINERS */

/* HEADERS */

h1{
	font-family: 'Inter', sans-serif !important; 
	font-size: 72px !important;
	line-height: 72px !important;
	font-weight: 600 !important;
	text-align:center;
	margin-top: 0.6rem !important;
	margin-bottom: 0.6rem !important;
}

h2{
	font-family: 'Inter', sans-serif !important;
	font-size: 44px !important;
	line-height: 44px !important;
	font-weight: 550 !important;
	text-align:center;
	margin-top: 0.6rem !important;
	margin-bottom: 0.6rem !important;
}

h3{
	font-family: 'Inter', sans-serif !important;
	font-size: 32px !important;
	line-height: 32px !important;
	font-weight: 500 !important;
	text-align:center;
	margin-top: 0.6rem !important;
	margin-bottom: 0.6rem !important;
}

h4{
	font-family: 'Inter', sans-serif !important;
	font-size: 24px !important;
	line-height: 24px !important;
	font-weight: 450 !important;
	text-align:center;
	margin-top: 0.6rem !important;
	margin-bottom: 0.6rem !important;
}


h5{
	font-family: 'Inter', sans-serif !important; 
	font-size: 18px !important;
	/*line-height: 18px !important;*/
	font-weight: 450 !important;
	text-align:center;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

h6{
	font-family: 'Inter', sans-serif !important; 
	font-size: 12px !important;
	/*line-height: 18px !important;*/
	font-weight: 400 !important;
	text-align:center;
	margin-top: 0.5rem !important;
	margin-bottom: 0.5rem !important;
}

  .sectionHeader {     font-family: 'Inter', sans-serif; height: 40px; background-color:var(--HEADER-blue); color: white;  text-align: center; margin: 10px 20px 20px 20px}

/* END HEADERS */

/* INPUT FORMS */

	.inputFormRowWrapper{
		padding: 10px 20px 0px 20px;
		width: 100%;
		flex-wrap:wrap;
		/*background-color: yellow;*/
	}
	
	.inputFormVerticalLeftInputField  {
		flex: 1 1 0px;    /*Setting 'basis' to zero so they all grow to the same size     https://stackoverflow.com/questions/29503227/how-to-make-flexbox-items-the-same-size*/
		display:flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-start;
		font-family: 'Abel', sans-serif;
		font-size: 18px;
		font-weight: 800;
		color:var(--HEADER-blue);
		margin-right: 20px;
		margin-top: 20px;
		/*border: 5px solid red*/
		/*background-color: pink;*/
	}    

	.textInputElement {
		display:block;
		outline: none;
		border: 2px solid transparent;
		padding-left:10px;
		border-radius: 5px;
		width:100%;
		box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
		background-color: white !important;
		background-clip: padding-box;
		transition: border-color .25s ease-out, box-shadow .25s ease-in;
		/*opacity: 0;*/
		/*width: 0%;*/
		/*transition-timing-function: ease-in;*/
		/*font-size:1.3rem;*/
		/*font-weight: 600;*/
	}

	.textInputElement:focus  {
		border: 2px solid rgba(93, 173, 226,0.7) !important;
	}

	.textInputElementRequired {
		border: 2px solid rgba(247, 5, 5, 0.5) !important;
	}

  /*Styles for input forms for adding customers, users, etc.*/
  .formStyle {background-color:#bfffda; table-layout: auto; }
  .formStyleGrey {background-color:var(--soft-grey); table-layout: auto; color:var(--black)}
  .formStyleMaxWidth {background-color:white; table-layout: auto; width: 100%;}
  .formStyleMaxWidthGrey {background-color:var(--soft-grey); table-layout: auto; width: 100%;}
  .formDataStyle { padding: 10px 10px; color:var(--var(--black));  border: none }
  .formDataStyleGrey { padding: 10px; color:grey;  border: none ; font-size: medium}
  .formDataStyleTop { padding: 10px; color:var(--var(--black));  border: none ; vertical-align: top}
  .formHeaderStyle { background-color:var(--HEADER-blue); color: white; border: none}
  
  /*Styles for tables for editing records*/
  .recordTableStyle  {  border-collapse: collapse ; background-color:white; }
  .recordRowStyle { background-color:#FFF; padding: 10px;  border: none; border-collapse: collapse}
  .recordDataStyle { padding: 10px; color:var(--black);  border: none; border-collapse: collapse}
  /*.recordTableStyle tr:nth-child(even){background-color:#CCC}*/
  .recordTableStyle tr:hover {background-color:#f2f3f4 }

  /*Styles for pop-up forms for editing customers, users, etc.*/
  .popEditFormHeaderStyle { background-color:var(--HEADER-blue); color: white; border: white; text-align: center; padding: 5px; }
  .popEditFormDataStyle { padding: 10px; color:#8a8a8a;  border: none; font-weight: 500 }  

 
.checkMarkActive {
	color: #008000;
	font-size: 0.4rem;
}



/* General Image Control */
.imageContainerNoRadius {
	position: relative;
	vertical-align: middle;
	overflow: hidden;
	text-align:center;
}

.imageContainer {
	position: relative;
	vertical-align: middle;
	overflow: hidden;
	border-radius: 50%;
	text-align:center;
	/*background-color:white;*/
}

.imageCenterPortrait {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 65%;
  -webkit-transform: translate(-50%,-50%);
	  -ms-transform: translate(-50%,-50%);
		  transform: translate(-50%,-50%);
}

.ccImg{
  padding-left: 2px;
}

.ccImgXXXXXXXL{
	height:512px !important;    
}

.ccImgXXXXXXL{
	height:256px !important;    
}

.ccImgXXXXXL{
  height:192px !important;    
}

.ccImgXXXXL{
  height:96px !important;    
}

.ccImgXXXL{
  height:64px;    
}


.ccImgXXL{
  height:48px;    
}

.ccImgXL{
  height:32px;    
}


.ccImgLarge{
  height:24px;    
}

.ccImgMedium{
  height:18px;    
}

.ccImgSmall{
  height:12px;    
}

.connectivityStartIcon {
	height: 18px !important ;
}

.ccImgXXXXXLSquare{
  height:192px !important;    
  width:192px !important;    
}

.ccImgXXXXLSquare{
  height:96px !important;    
  width:96px !important;    
}

.ccImgXXXLSquare{
  height:64px   !important;  
  width:64px !important;    
}


.ccImgXXLSquare{
  height:48px !important;     
  width:48px !important;     
}

.ccImgXLSquare{
  height:28px !important;     
  width:28px !important;     
}


.ccImgLargeSquare{
  height:24px !important;     
  width:24px !important;     
}

.ccImgMediumSquare{
  height:18px !important;     
  width:18px !important;     
}

.ccImgSmallSquare{
  height:12px !important;     
  width:12px !important;     
}

.gifImg-V2 {
	height: 16px !important;
}

  /* Used to reduce the size of the image in the cirle.  Used primarily for logo images on modals */
  .ccImagePadding {
	padding: clamp(4px,1.2vw,16px);
}

 .buttonTextBlack
{
	display: inline-block; /*Display inline and maintain block characteristics.*/
	vertical-align: central; /*Makes sure all the divs are correctly aligned.*/
	white-space: normal; /*Prevents child elements from inheriting nowrap.*/
	color:var(--black);
	/*margin: 5px;*/
}


.buttonWrapperWithMargin {
	margin:clamp(10px,2vh,16px);
}

.awardImgXXL{
	height:86px;
}
  
.awardImgXL{
	height:68px;
}
  
.awardImgLarge{
	height:52px;
}
  
.awardImgMedium{
	height:40px;
}
  
.awardImgSmall{
	height:34px;
}
 
 
 /*Styles for uploading and previewing images*/
 
 .imageInputFormStyle {
   flex: 1 1 50rem;
   padding: 10px;
   border: 2px var(--SOCIAL-green) solid;
   width: 35rem;
   /*background-color: white;*/
}

.imageInputModalStyle {
   flex: 1 1 30rem;
   padding: 10px;
   /*border: 2px #c7c7c7 solid;*/
   width: 25rem;
   /*background-color: white;*/
}

.imageEditModalStyle {
   padding: 10px;
}

.dividerClass {
	height: 2px;
	border-bottom: 1px solid #abcfff;
	width: 100%;
	margin: 5px 0px;
}

.connectivityLauncherCard {
	max-width: 880px !important;
}

.conectivity-launcher-scheduling-portion {
	flex:1;
	/* width:50%;
	max-width:50%; */
	height:100%;
	padding: clamp(20px,1.9vw,30px) clamp(12px,1.2vw,18px);
	padding-bottom: 0;
}

.conectivity-launcher-preview-portion {
	flex:1;
	/* width:50%;
	max-width:50%; */
	height:100%;
}

.connectivityLauncherFooter {
	display:flex;
	flex-direction:column;
	align-items:flex-end;
	justify-content:space-around;
	background-color: var(--light-mode-card-background);
	flex-grow:0;  /*Will not expand; Push to bottom */
	border-radius: clamp(4px,0.4vw,10px);
	padding: clamp(20px,1.9vw,30px) clamp(12px,1.2vw,18px);
	padding-top: 0;
}

.darkMode .connectivityLauncherFooter{
	background-color: var(--dark-mode-card-background) !important;
}

.displayCardConectivityAvatar {
	background: #a77bba;
	border-radius: 50%;
	color:white;
	padding:5px;
	font-weight:400;
	font-size:medium;
	margin:5px;
}

.displayCardConectivityAvatarNoPadding {
	background: var(--black);
	border-radius: 50%;
	color:white;
	/*padding:5px;*/
	font-weight:400;
	font-size:medium;
	margin:3px;
}

.displayCardConectivityAvatarNoPaddingMedium {
	position:relative;
	background-color: white;
	border-radius: 50%;
	color:white;
	/*padding:5px;*/
	font-weight:400;
	font-size:medium;
	margin:3px;
	width: 38px;
	height: 38px;
	text-align:center;
	vertical-align: middle;

}

.displayCardConectivityAvatarNoPaddingSmall {
	position:relative;
	background-color: white;
	border-radius: 50%;
	color:white;
	/*padding:5px;*/
	font-weight:400;
	font-size:medium;
	margin:3px;
	width: 32px;
	height: 32px;
	text-align:center;
	vertical-align: middle;

}

.avatarContainer {
	position:relative;
}

/* Avatar text */
.avatarHoverText {
  visibility: hidden;
  width: auto;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  padding: 0.2vw;
  border-radius: 6px;
  font-size: 0.7rem;
 
  /* Position the hover text*/
  position: absolute;
  bottom:0px; /*Anchor at the bottom of the container */
  
  transform: translate(-20%, 100%); /*Move the hover text down 100% of its own height; shift left a bit */
  z-index: 1;

 /* Add a little depth to the pop-up */ 
 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
 
}


.displayCardConectivityAvatarWrapper:hover +.avatarHoverText  {
  visibility: visible;
}

.displayCardConectivityAvatar:hover +.avatarHoverText  {
  visibility: visible;
}

.displayCardConectivityAvatarNoPadding:hover +.avatarHoverText  {
  visibility: visible;
}

.displayCardConectivityAvatarNoPaddingMedium:hover +.avatarHoverText  {
  visibility: visible;
}

.displayCardConectivityAvatarNoPaddingSmall:hover +.avatarHoverText  {
  visibility: visible;
}

.avatarInitials {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	width:100%;
	flex-wrap:nowrap;
	color:white;
	font-size: 1.0rem;
	font-weight: 400;
	/*Center the initials */    
	margin: 0;    
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); /*Shift just the initials so the text itself is centered.  Above two lines centers the top left point on the text */
	/*background-color: red;    */
}

.avatarInitialsSmall {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	flex-wrap:nowrap;
	width:100%;
  
	color:white;
	font-size: 0.6rem;
	font-weight: 500;

	/*Center the initials */    
	margin: 0;    
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); /*Shift just the initials so the text itself is centered.  Above two lines centers the top left point on the text */
  
	/*background-color: yellow;    */
}

.avatarInitialsTiny {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:center;
	width:100%;
	flex-wrap:nowrap;
   
	color:white;
	font-size: 0.5rem;
	font-weight: 400;

	/*Center the initials */    
	margin: 0;    
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%); /*Shift just the initials so the text itself is centered.  Above two lines centers the top left point on the text */
  
	/*background-color: red;    */
}

/* Messages */
.messageButtonHover {
	visibility: hidden;
	white-space: nowrap;
	color: #fff;
	text-align: center;
	padding: 0.2vw;
	border-radius: 6px;
	font-size :medium;
	cursor: pointer;
 
	/* Position the hover text*/
	position: absolute;
	bottom: 0;
	right: 0;
	transform: translate(0%, 100%);
	z-index: 1;
	
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.conectivityCardChatMessageButton:hover + .messageButtonHover{
	visibility: visible;
}


/* Custom CSS for Bootstrap Modal used through App*/

/* Position the modal down from the top of the screen */
.modal-dialog {
	padding-top:5vh;
}
.modal-header {
	padding-top:300px;
	padding:9px 15px;
	border-bottom:1px solid #eee;
	background-color: var(--HEADER-blue);
	color: white;
 }

.userEditClass {
	padding-top:50px;
	min-width: 1300px;
}

.customerConfigClass {
	padding-top:50px;
	min-width: 1300px;
}

/* BUTTONS */

.buttonContainer {
	position:relative;
	cursor:pointer;
	text-align: center;    
	margin:2px;
}

.dataGridActionButtonRow {
	display:flex;
	flex-direction:row;
	align-items:center;
	/*justify-content:space-around;*/        /* distribute buttons evenly */
	justify-content:flex-end;                /* Stack buttons on far right of the row */
	/* margin:0px 10px; */
	width:100%;
}

.dataGridActionButton {
	/*flex:1;*/    /* grow the container equally */
	flex:0;        /* Do not grow the container */
	position:relative;
	cursor:pointer;
	/*background-color:pink;*/
	/*min-width: 50px;*/
	padding:0px 4px;
	text-align: center;    /* Center the Button Icon in the middle of its flex space */

}

.buttonContainer {
	position:relative;
	cursor:pointer;
	text-align: center;    
	margin:2px;
}

.buttonContainerAbsoluteOffset {
	position:absolute;
	cursor:pointer;
	text-align: center;    
	top:0;
	right:0;
	transform: translate(80%,-50%) !important;
}

.blankButton{
	/*flex:1;*/
	/*min-width: 50px;*/
}

.radarButton{
	color: #8884d8;
}
.previewButton, .launchButton, .transmitButton{
	color: var(--BALANCE-blue);
}

.summaryButton{
	color: var(--SOCIAL-green);
}

.addButton, .editButton {
	color: var(--BALANCE-blue);
}

.declineRsvpButton {
	color: var(--ALERT-red-opaque);
}

.acceptRsvpButton {
	color: var(--WARNING-yellow-opaque);
}

.shareButton{
	color: var(--TEAM-purple);
}

.unshareButton{
	color: var(--TEAM-purple);
}

.viewButton{
	color: var(--BALANCE-blue);
}

.viewButtonDisabled{
	color: var(--light-grey);
}

.paidButton{
	color: var(--SOCIAL-green);
}

.bonusButton{
	color: var(--bronze);
	color: pink;
}

.deleteButton{
	color: red;
}

.settingsButton {
	color: var(--BALANCE-blue);
}

.helpButton {
	color: var(--BALANCE-blue);
	transform: translate(-10%,-20%);    
}

.buttonZoomAbility {
	-webkit-transition: -webkit-transform 0.4s;
	transition: -webkit-transform 0.4s;
	transition: transform 0.4s;
	transition: transform 0.4s, -webkit-transform 0.4s; /* Animation */
}

.buttonZoomAbility:hover {
	-webkit-transform: scale(1.2);
			transform: scale(1.2);
}

.hoverTextStyle1 {
  /* min-width: clamp(200px,30vw,600px); */
  max-width: clamp(400px,50vw,600px);
  text-wrap: wrap;
  visibility: hidden;
  width: auto;
  color: var(--HEADER-blue);
  padding:0.5rem 0.5rem;
  background-color: white;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  bottom:clamp(-3px, -0.5vw, -7px);      /* Anchor bottom of pop-up 1% below the bvw below ottom of the parent container to create a margin*/
  left:50%;                             /* Start of object is at center bottom, until we translate */
  transform: translate(-50%, 100%);     /* Move the hover text down 100% of its own height; shift left 1/2 its width so as to center under button */
  z-index: 999999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.hoverLeft {
	transform: translate(-80%, 100%);
}

.hoverTextStyle1Visible  {
  visibility: visible !important;
  /*background:yellow;*/
}

.hoverTextStyle1Large  {
	min-width: clamp(40px,10vw,120px);
	max-width: 95vw;
 }

.hoverTextStyle1Wide  {
  min-width: clamp(250px,20vw,600px);
  max-width: 95vw;
}

.hoverTextStyle1ExtraWide  {
  min-width: 50vw !important;
  max-width: 95vw;
}

.infoPopUp  {
	min-width: clamp(250px, 40vw, 800px);
	transform: translate(-100%, 100%);     /* Move the info pop-up down 100% of its own height; shift left 100% its width to the left */
  }

.learnMoreLinkText {
	text-decoration: underline;
	cursor: pointer;
}

/* END BUTTONS */

/*Class to translate and center avatar image*/
/*Use this on the IMG elemement */
/*https://jonathannicol.com/blog/2014/06/16/centre-crop-thumbnails-with-css/*/

.avatarImageCenterPortrait {
  position: absolute;
  left: 50%;
  top: 50%;
  /*height: auto;*/
  height:100%;   /* Set both height and width to 100%, which would cause to the image to lost aspect ratio but for the object-fit of COVER */
  width: 100%;
  object-fit: cover;
  -webkit-transform: translate(-50%,-50%);
	  -ms-transform: translate(-50%,-50%);
		  transform: translate(-50%,-50%);
}


/* Avatar classes for DIV wrappers */
/* Used for a series of images; adjusts margins to overlap avatars */

.avatarImage {
	position: relative;
	flex:none;        /*If the avatar is a child of a flex box, do not shrink the image itself in the flex direction.  Distorts the image */
	vertical-align: middle;  
	overflow: hidden;
	border-radius: 50%;
	margin: 0px 5px;
	text-align:center;
	transition: box-shadow ease-in 1s;     
	background-color:white;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

/* Used to reduce the size of the image in the cirle.  Used primarily for logo images on modals */
.avatarImagePadding {
	padding: clamp(0.3vw,10px,1vw)
}

.avatarImageXXLarge {
	width: 128px;
	height: 128px;
}

.avatarImageXLarge {
	width: 96px;
	height: 96px;
}

.avatarImageLarge {
	width: 64px;
	height: 64px;
}



.avatarImageMediumLG {
  width: 50px;
  height: 50px;
}

.selected-chips {
	margin-top: 10px;
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
}

.chip {
	background-color: #e0e0e0;
	padding: 3px 6px;
	border-radius: 25px;
	display: flex;
	align-items: center;
}

.remove-chip {
	margin-left: 8px;
	cursor: pointer;
}

.avatarImageMedium {
  width: 42px;
  height: 42px;
}

.avatarImageMediumV2 {
	width: 30px;
	height: 30px;
  }

.avatarImageSmall {
  width: 28px;
  height: 28px;
}

.avatarImageTiny {
  width: 22px;
  height: 22px;
}

.avatarImageMediumV2{
  width: 40px;
  height: 40px;
}

.avatarImageMedium:not(:last-child) {
  margin-right: -10px;
}

.avatarImageSmall:not(:last-child) {
  margin-right: -8px;
}

.avatarImageLarge:not(:last-child) {
  margin-right: -12px;
}
.avatarImage:hover +.avatarHoverText  {
  visibility: visible;
}

/*
.avatarImageTiny:hover +.avatarHoverText  {
  visibility: visible;
}

.avatarImageSmall:hover +.avatarHoverText  {
  visibility: visible;
}
.avatarImageMedium:hover +.avatarHoverText  {
  visibility: visible;
}
.avatarImageLarge:hover +.avatarHoverText  {
  visibility: visible;
}
*/

.avatarHalo {
	-webkit-box-shadow: 0px 0px 20px 4px rgba(255,255,255,0.75); 
	box-shadow: 0px 0px 20px 4px rgba(255,255,255,0.75);
}

.connectivityInviteAvatarImg {
	height: 28px;
	width: 28px;
}

.connectivityInviteAvatarImg:not(:last-child) {
	margin-right: -15px;
}

.rsvpStatusAnchor {
	position:absolute;
	bottom:0;
	right:0;
	transform:translate(70%,40%);
}

.participantCountBadge {
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	/*position:absolute;		*/
	/*top: 0px;*/
	/*right: 0px;*/
	margin: 0px 10px;
	font-size: 1.2rem;
	height:1.5rem;
	/*padding: 2px 5px; */
	color: white;
	/*text-shadow: 2px 2px 10px red;*/
	background-color: var(--TEAM-purple);
	border-radius: 5px;
	/*display:inline-block;*/
	min-width:30px;
	/*height:25px;*/
	/*border:solid 2px #409cf7;*/
	text-align:center;
	vertical-align: middle;
	font-weight: 600;
	cursor: pointer;
}

/* invitation modal style start */

.monthlyViewWrapper {
	background-color: var(--soft-grey);
	padding: 16px 10px;
	border-radius: 8px;
	margin-top: 16px;
}

.darkMode .monthlyViewWrapper {
	background-color: var(--dark-mode-background);
}

/* invitation modal style end */

/*Tabs*/
.tabHeaderBox {
	/*margin: 0px 20px;*/
	/*padding: 0px 0px 10px 0px; */
	border-radius: clamp(4px,0.4vw,10px);
	cursor:pointer;
	/*width: 400px;*/

	/*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
	
	/*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
	/*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/

}

.tabPanel {
	min-height: 500px;
	height:100%;
}


/* DASHBOARD */

.dashboardMenuWrapper{
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 20px 20px;
	padding: 30px;
	border-radius: clamp(4px,0.4vw,10px);
	cursor:pointer;
	max-width: 1400px;
	width: 100%;

	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   
	/*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
	/*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

.dashboardContainer {
	display:flex;
	flex-direction:column;
	justify-content:flex-start;
	align-items:flex-start;
	margin-top:20px;
	padding-top: 0.5rem; 
	padding-bottom: 0.5rem; 
	padding-left: clamp(0.5rem, 5vw, 3.0rem);        /*Adjust the padding around the grid based on screen width */
	padding-right: clamp(0.5rem, 5vw, 3.0rem);
	border-radius: clamp(4px,0.4vw,10px);
	/* background-color: white; */
	max-width: clamp(600px, 80vw, 1800px);
	overflow: hidden;
	/* box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
	/*min-width: clamp(600px, 50vw, 1600px);*/    
	/*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
	/*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

	/*font-family: 'Abel', sans-serif;*/  

	/*font-family: 'Montserrat', sans-serif;*/

	/*font-family: 'Inter', sans-serif;*/
	
.dashboardTitle {
	/*height:55px;                             /*Making room for admin sidebar control on laptop screens*/
	font-size:1.4rem;
	font-weight: 700;
	text-align: left;
	color: var(--HEADER-blue);    
	padding-bottom:10px;
	background: linear-gradient(to right, var(--HEADER-blue) 0%, #FFFFFF 80%);
	background-position: 0 100%;
	background-size: 100% 6px;
	background-repeat: repeat-x;   
	width: 100%;
	display:flex;
	flex-direction:row;
	justify-content: space-between;
	align-items: center;
}


.dashboardCardRow {
	width: 100%;
	display: flex;
	flex-wrap:nowrap;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: clamp(20px,1.0vw,50px);
	margin-top: clamp(20px,1.0vw,50px);
	padding: clamp(8px,0.8vw,20px);
	overflow-x: scroll;
	overflow-y: hidden;
}

.dashboardCard {
	background-color: var(--light-mode-card-background);
	display: flex;
	flex-direction: column;
	 /* background-color:white; */
	justify-content: space-between;
	align-items:center;
	margin: clamp(4px,1vh,10px);
	padding: clamp(4px,1vh,10px) 0px clamp(4px,1vh,10px) 0px; 
	border-radius: clamp(4px,0.4vw,10px);
	/*cursor:pointer;*/
	width: clamp(300px,20vw,400px);    /* Dynamic width for larger viewports */
	height: clamp(340px,20vw,360px);
	flex-shrink:0;
	/*width: 360px;*/
	overflow-x:hidden;
	overflow-y:scroll;
	box-shadow: rgba(255, 255, 255, 0.6) 1px 2px 10px -1px, rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.darkMode .dashboardCard {
	background-color: var(--dark-mode-card-background);
}
.dashboardCardWideProfile {
	width: 500px;
}

.dashboardCardFlexStartProfile {
	justify-content: flex-start;
}

.dashboardCardTitle {
	width:100%;
	display: flex;
	align-items: flex-end;
	justify-content: center;
	width: 100%;
	font-size:clamp(1.1rem,8vw,1.3rem);
	color: var(--HEADER-blue);
	font-weight:600;
	padding: 5px 0px;    
	/*margin-bottom: 0px;*/
}

.dashboardScoreRectangle-Root {
	display:flex;
	flex-direction:column;
	align-items: center;
	justify-content: center;
	background-color: var(--light-mode-card-background);
	border-radius: clamp(4px,0.4vw,10px);
	padding:clamp(4px,1vw,14px);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.darkMode .dashboardScoreRectangle-Root {
	background-color: var(--dark-mode-card-background);
}

.dashboardScoreRectangle {
	display:flex;
	flex-direction:column;
	align-items: center;
	justify-content: center;
	color: white;
	font-size: 1.4rem;
	font-weight: 700;
	border-radius: clamp(4px,0.4vw,10px);
	/*text-align: right;*/
	/*padding-right: 4px;*/
	height: 56px;
	width:64px;
}
.darkMode .dashboardScoreRectangle {
	background-color: var(--dark-mode-card-background);
}

.dashboardScoreRectangleSmall {
	font-size: 1.2rem;
	height: 40px;
	width:50px;    
}

.dashboardScoreRectangleTiny {
	font-size: 0.8rem;
	height: 28px;
	width:40px;    
}

.dashboardScoreOval
{
	display:flex;
	justify-content: flex-start;
	align-items: center;
	margin: 5px 10px;
	padding: 5px;
	flex-wrap:nowrap;
	color: var(--TEAM-purple);
	border-radius: clamp(4px,0.4vw,10px);
	border: 2px solid var(--TEAM-purple);
	background-color: white;
	font-size:clamp(0.8rem,8vw,1.0rem);
	font-weight: 500;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}



.dashboardScoreCircle {
	display:flex;
	flex-direction:column;
	align-items: center;
	justify-content: center;
	color: var(--grey-blue);
	font-size:clamp(16px,8vw,20px);
	font-weight: 700;
	border-radius: 100%;
	border: 4px solid var(--soft-grey);
	height: 64px;
	width:64px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	background-color: white; 
}

.darkMode .dashboardScoreCircle {
	background-color: var(--dark-mode-card-background);
	border: 4px solid var(--SOCIAL-green);
}
.dashboardCardHeaderRow {
	width: 100%;
	display:flex;
	flex-direction:row;
	justify-content: space-around;
	align-items: center;
	padding: 0px 5px;
	/*margin: 5px 10px 5px 10px;*/
	flex-wrap:wrap;
	/* background-color: var(--soft-grey); */
}

.dashboardCardTextRow {
	width: 100%;
	display:flex;
	flex-direction:row;
	justify-content: space-around;
	align-items: center;
	padding: 5px 5px 5px 5px;
	flex-wrap:wrap;
	text-align: center;
}



.dashboardCardText {
	font-weight: 600;
	font-size:1.0rem;
	text-align: left;
	flex: 2 1 0;
}

.dashboardCardTextSmall {
	font-weight: 500;
	font-size:0.9rem;
	text-align: left;
	flex: 2 1 0;
}


.dashboardCardNumber {
	/* color: black; */
	font-weight: 600;
	font-size: 1.0rem;
	text-align: center;
	flex: 1 1 0;
}

.dashboardCardAward {
	/* color: black; */
	font-weight: 600;
	font-size: 1.0rem;
	text-align: center;
	flex: 1 1 0;
}

.dashboardCardTextFooter {
	padding: 10px 0px 10px 0px; 
	font-size: 0.7rem;
}


.dashboardCardFavoriteStars {
	width: 80px;
	/*background-color: yellow;*/
	padding-left: "2px";
	flex-shrink: 0;
}





.dashboardBannerHeader {
	margin-top: 30px;
	margin-bottom: 10px;
	color:var(--TEAM-purple);
	font-size: 2.0rem;
	font-weight: 500;
	height:auto;
	/*height:100px;*/
	width:60vw;
	max-width: 1400px;
	/*display:flex;*/
	/*flex-direction:column;*/
	/*align-items: center;*/
	/*justify-content: center;*/
	background-color: #111;
	/*background-color: pink;*/

	/*border-bottom: 0.2vw solid var(--TEAM-purple);*/
	/*border-radius: 20px;*/
	
	background: linear-gradient(to right, var(--TEAM-purple) 50%, #FFFFFF 100%);
	background-position: 0 100%;
	background-size: 100% 6px;
	background-repeat: repeat-x;       
}

.dashboardRectangle {
	padding: 1rem;
	border-radius: clamp(4px,0.4vw,10px);
	margin:0.2rem;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	border:none;
	background-color: white; 
}

.CardRank {
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	background-color: #f7f7f7;
	font-weight: 600;
	border-radius: 50%;
	padding:1px;
	height:clamp(10px,2vw,20px);
	width:auto;
	min-width: clamp(10px,2vw,20px);
	/*height: 40px;*/
	/*width:60px;*/
	color:#36454f;
	z-index:9999;
	position:absolute; 
	right:0; 
	top:0;   
	transform: translate(50%,-40%);
	
	 box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	
}

.CardScore {
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	text-align: center;
	vertical-align: middle;
	background-color: pink;
	font-size: 1.2rem;
	font-weight: 600;
	border-radius: 20px;
	height: 40px;
	width:60px;
	color:white;
	/*margin-top:5px;*/
	/*margin-bottom: 5px;*/
	z-index:0;
	position:relative;
	
}

/* END DASHBOARD */


.accountHistory .rotateCoins{
 animation: rotateCoins 3s linear infinite;
 
}

@keyframes rotateCoins {
	0% { 
		transform: rotateY(0); 
	}
	100% { 
		transform: rotateY(360deg);
	}
}

.accountHistory .inputContainer {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	width:100%;
	height:100%;
	background-color:white;
	border-radius:10px;
	margin-top:20px;
	margin-bottom:5px;
	padding:0px 10px;
}

/* SPECIAL EVENTS & ANNOUNCEMENTS */
.specialEventCard {
	position:relative;
	width:90%;
	max-width:clamp(600px,50vw,1200px);
	background-color:var(--TEAM-purple);
	padding: clamp(4px, 0.5vw, 10px);
	margin-top: clamp(2px, 0.3vw, 5px);
	margin-bottom: clamp(4px, 0.3vw, 5px);
	/* margin-left: clamp(4px, 0.3vw, 5px); */
	/* margin-right: clamp(4px, 0.3vw, 5px); */
	border-radius: 0.2vw;
}

.eventViewWrapper {
	position:relative;
	width: 100%;
	background-color:var(--TEAM-purple);
	padding: 7px clamp(4px, 0.5vw, 10px);
}

.eventCardViewInfoWrapper {
	gap: clamp(8px, 2vw, 15px);
	padding-left: clamp(8px, 2vw, 15px);
}

.eventCardExpireTimeWrapper {
	background-color: rgba(0,0,0,0.2);
	border-radius: clamp(20px, 3vw, 30px);
	padding: clamp(2px, 0.5vh, 6px) clamp(8px, 0.8vw, 15px);
}

.eventActionButton {
	padding: clamp(2px, 0.5vw, 4px) !important;
}

.eventCardCloseButton {
	position: absolute;
	top: 4px;
	right: clamp(4px, 0.5vw, 10px);
}

/* END SPECIAL EVENTS & ANNOUNCEMENTS */

.customerEditCard {
	background-color:var(--card-background);
	color:var(--HEADER-blue);
	margin: 10px 20px;
	/*padding: 0px 0px 10px 0px; */
	border-radius: clamp(4px,0.4vw,10px);
	/*cursor:pointer;*/
	/* width: clamp(600px,1200px,85vw); */
	min-height: 600px;
	/*background:yellow;    */
	-webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


}

.darkMode .customerEditCard {
	/* background-color: var(--dark-mode-card-background); */
}

.TimeSeriesButton {
	margin: 0px 20px;
	padding: 0px 0px 10px 0px; 
	border-radius: clamp(4px,0.4vw,10px);
	width: 160px;
	height: 100px;
	display: flex;
	/*background-color: var(--HEADER-blue);*/
	
	-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
	box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);
   
}

/* TEXT FORMATTING */
.strikeThrough {
	text-decoration:line-through;
}

.TextStyle1 {
	font-size: 0.6rem !important;
	line-height: 0.7rem !important;
	font-weight: 400;
	/* color: var(--BALANCE-blue); */
}

.TextStyle2 {
	font-size: 0.8rem !important;
	line-height: 1.0rem !important;
	font-weight: 400;
	/* color: var(--BALANCE-blue); */
}

.TextStyle3 {
	font-size: 1.0rem !important;
	line-height: 1.2rem !important;
	font-weight: 400 !important;
	/* color: var(--BALANCE-blue); */
}

.TextStyle4 {
	font-size: 1.1rem !important;
	line-height: 1.3rem !important;
	font-weight: 400;
	/* color: var(--BALANCE-blue); */
}

.TextStyle4_3 {
	font-size: 1.3rem !important;
	line-height: 1.5rem !important;
	font-weight: 400;
	/* color: var(--BALANCE-blue); */
}

.TextStyle5 {
	font-size: 1.4rem !important;
	line-height: 1.5rem !important;
	font-weight: 500;
	/* color: var(--BALANCE-blue); */
}

.TextStyle6 {
	font-size: 1.6rem !important;
	line-height: 1.8rem !important;
	font-weight: 500;
	/* color: var(--BALANCE-blue); */
}

.TextStyle6A {
	font-size: 2.0rem !important;
	line-height: 2.4rem !important;
	font-weight: 500;
	/* color: var(--BALANCE-blue); */
}

.TextStyle7 {
	font-size: 3.0rem !important;
	line-height: 3.2rem !important;
	font-weight: 500;
	/* color: var(--BALANCE-blue); */
}

/* V2 Text styles start */

.TextStyle1V2 {
	font-size: 0.625rem !important; /* 10px font-size */
	line-height: 0.7rem !important;
	font-weight: 400;
}

.TextStyle2V2 {
	font-size: 0.75rem !important; /* 12px font-size */
	line-height: 0.9rem !important;
	font-weight: 400;
}

.TextStyle3V2 {
	font-size: 0.875rem !important; /* 14px font-size */
	line-height: 1rem !important;
	font-weight: 400;
}

.TextStyle4V2 {
	font-size: 1rem !important; /* 16px font-size */
	line-height: 1.15rem !important;
	font-weight: 400;
}

.TextStyle5V2 {
	font-size: 1.125rem !important; /* 18px font-size */
	line-height: 1.25rem !important;
	font-weight: 500;
}

.TextStyle6V2 {
	font-size: 1.25rem !important; /* 20px font-size */
	line-height: 1.4rem !important;
	font-weight: 500;
}

.TextStyle7V2 {
	font-size: 1.375rem !important; /* 22px font-size */
	line-height: 1.5rem !important;
	font-weight: 500;
}

.TextStyle8V2 {
	font-size: 1.625rem !important; /* 26px font-size */
	line-height: 1.75rem !important;
	font-weight: 500;
}

/* V2 Text styles end */

/*Home Page Styling*/

.fadeInHomePageText {
  display: inline-block;
  /*font-family: Arial, Helvetica, sans-serif;*/
  font-size: 32px;
  color: var(--black);
  /*animation: fadeIn linear 3s ;*/
  opacity: 0; /* Default */
  font-family: 'Inter', sans-serif;
  
}

.fadeInTagToAddDynamically {
  animation: fadeIn linear 5s ;
  -webkit-animation: fadeIn linear 5s ;
  -moz-animation: fadeIn linear 5s ;
  -o-animation: fadeIn linear 5s ;
}

/*.fadeOutTagToAddDynamically {*/
/*  animation: fadeOut linear 1s ;*/
/*  -webkit-animation: fadeOut linear 1s ;*/
/*  -moz-animation: fadeOut linear 1s ;*/
/*  -o-animation: fadeOut linear 1s ;*/
/*}*/

/*Rules for other browsers */
@keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeIn {
  0% {opacity:0;}
  50% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@keyframes fadeOut {
  0% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-moz-keyframes fadeOut {
  0% {opacity:1;}
  90% {opacity:1;}
  100% {opacity:0;}
}

@-webkit-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}

@-o-keyframes fadeOut {
  0% {opacity:1;}
  100% {opacity:0;}
}


.companyPageOuterWrapper {
	display:flex;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 96vh;
}

/* Chat Messaging */

.chatWindowOuterContainer {
	height:100%;
	width:100%;
	position:relative;
	border-radius: clamp(4px,0.4vw,10px);
	overflow-x:hidden;
	overflow-y: scroll;
	background-color: var(--light-mode-card-background);
	/*min-width:clamp(400px,30vw,600px);*/
	/*max-width:clamp(600px,80vw,1200px);*/
	transition-timing-function: ease-in;
	transition: max-height 0.3s;
}

.darkMode .chatWindowOuterContainer {
	background-color: var(--dark-mode-card-background) !important;
}
.chatWindowInnerContainer {
	height:100%;
	width:100%;
	position:relative;
	overflow-Y: scroll;
	overflow-x:hidden;
	max-height :inherit;
	padding:1em 0px 2em 0px;    /* Leaving a bit of room at the bottom to show USER LIKES when hovered */
}

.chatWindowInnerContainerv2 {
	height:100%;
	width:100%;
	position:relative;
	max-height :inherit;
	/* padding:0em 0px 2em 0px;    Leaving a bit of room at the bottom to show USER LIKES when hovered */
}

.borderLeftGray{
	border-left: 3px solid var(--soft-grey);
}
.darkMode .borderLeftGray{
	border-left: 3px solid var(--davy-grey);
}
.borderLeftNone{
	border-left: none;
}
.messageRegionOuterWrapper {
	height: auto;
	overflow-Y: auto;
	/*overflow-X: hidden;*/
	max-height:50vh; 
	/*min-height:15vh;  */
	/*background-color: pink;*/
	background-color: white;
	border-radius: clamp(4px,0.4vw,10px);
	/*max-width:450px;*/
	width:100%;
	/*min-width:450px;*/
	/*padding: 10px 5px;*/
	/*margin-top:20px;*/
}

.messageRow {
	display: flex;
	flex-wrap: nowrap;
	align-items: flex-start;            /*Set items at top vertically in the message row */
	justify-content: space-between;     /* Seperate items */
	position:relative;
	width:100%;    /*Span the entire chat window with the row */
	text-align: left;    /*Align text for both sender and receiver to the left of the bubble */
	transition-timing-function: ease-in;
	transition: opacity 0.6s;
	opacity:1;
}

.messageRowNotParentOfReply{
	opacity:0.1;
}

.messageSenderAvatarPortion {
	flex-grow:0;
	padding:0.5rem;
}

.messageTimeAgoLeft {
	padding-left: 5px; 
	font-size:10pt; 
	color:darkgray; 
	font-weight:400;    
}

.messageTimeAgoRight {
	padding-right: 5px; 
	font-size:10pt; 
	color:darkgray; 
	font-weight:400;    
}

.messageRowText {
	/* font-size: 1.1rem;
	font-weight: 400; */
	color: var(--HEADER-blue);
	background-color: var(--soft-grey);
	/*background-color: pink;*/
	padding: 0px 20px;
	margin: 0px 5px;
	border-radius: 20px;
	max-width: 70%;
	text-align: left;
}

.messageRowTextV2 {
	/* font-size: 1.1rem;
	font-weight: 400; */
	color: var(--rich-black);
	background-color: var(--LIGHT-purple);
	border: 1px solid #EADCF0;
	/*background-color: pink;*/
	padding: 12px;
	margin: 0px 15px;
	max-width: 70%;
	border-radius: 8px;
	/* max-width: 70%; */
	text-align: left;
}

.messageRowTextSenderV2{
	/* font-size: 1.1rem;
	font-weight: 400; */
	color: var(--rich-black);
	background-color: var(--card-background);
	border: 1px solid var(--light-grey);
	/*background-color: pink;*/
	padding: 10px;
	margin: 0px 15px;
	border-radius: 8px;
	max-width: 70%;
	/* max-width: 70%; */
	text-align: left;
}

.darkMode .messageRowTextSenderV2{
	color: var(--white-smoke);
	border: 1px solid var(--light-grey);
}

.messageRowTimeStamp {
	/* font-size: 0.7rem;
	font-weight: 400; */
	color: var(--HEADER-blue);
	/*padding-left:20px;*/
}


.messageRowAvatar {
	/*flex: 1;*/
	width: clamp(20px,4vw,40px);
	/*flex-grow:0;*/

	/*background-color: yellow;*/
}
.messageRowBlank {
	/*flex: 1;*/
	width: clamp(20px,4vw,40px);
	/*flex-grow:0;*/
	/*background-color: pink;*/

}
.messageRow {
	.dataGridActionButton {
		padding:0px
	}
}
.messageLikesList {
	display:flex;
	align-items:center;
	justify-content:center;
	visibility: visible;
	min-width: clamp(40px,12vw,120px);
	height: 0;
	color: #fff;
	font-size:0.8rem;
	border-radius: 6px;
	padding: 0px 5px;
	
	/* Position the hover text*/
	position: absolute;
	top:100%;
	right:100%;
	overflow: hidden;
	background-color: #5DADE2;

	transition-timing-function: ease-in;
	transition: all 0.4s;
	transform: translate(50%,0);    

	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
	
}
.messageLikesListHover  {
	visibility: visible;
	height: 24px;
	/*padding: 5px;*/
}

.messageReplyButton{
	margin:0px clamp(2px,0.4vw,5px);
}


/* END CHAT MESSAGING */


/* Messaging Input CSS including Emojis and GIPHY */

.conectereMessagingInputAreaWrapper {
	text-align: left;
	/*max-width:450px;*/
	/*margin: 5px 10px 0px 10px;*/
	padding: 5px 5px;
	border-radius: clamp(4px,0.4vw,10px);
	
}
.conectereMessagingInputAreaWrapper .editMode {
	position:absolute;
	bottom:0px;
	left:50%;
	transform:translate(-50%,0%);
}
.conectereMessagingInputAreaInnerWrapper {
	width:100%;
	/*background-color: orange;*/
	border-radius: clamp(4px,0.4vw,10px);
}

.conectereMessagingInputArea {
	position: relative;
	display:flex;
	align-items:center;
	justify-content:center;
	width:100%;
	padding: 10px 0px;
	/*background: yellow;*/
}
.conectereMessagingInputIcon {
	color: #409cf7;
}


.conectereMessagingInputBar {
	min-width:clamp(150px,30vw,350px);
	/*width:100%;*/
	flex:1;
	background-color: #f7f7f7;
	border: 1px solid white;
	padding-left:10px;
	padding-right:100px;  /* Need to add room in the input bar to the typed text does not go over for the action icons */
	border-radius: clamp(4px,0.4vw,10px);
	color: #414a4c;
	transition: all 0.3s;
	transition-timing-function: ease-in;
	/* font-size:1.0rem;
	font-weight: 500; */
}

.conectereMessagingInputAreaButtons{
	position:absolute;
	top:50%;
	right:15px;
	transform:translate(0,-50%);
}

.conectereMessagingInputAreaButtonsV2 {
	position:absolute;
	right:25px;
	z-index: 1;
	gap: 5px;
}

.conectereMessagingGiphyStagingAreaWrapper{
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items:center;
	width:100%;
	position:relative;
}
.conectereMessagingGiphyStagingArea {
	display:flex;
	justify-content:flex-end;
	min-width:350px;
	width:100%;
	background-color: white;
	background-color: #f7f7f7;
	border: 1px solid white;
	padding:10px;
	border-radius: clamp(4px,0.4vw,10px);
	cursor:default;
}

.conectereMessagingGiphyClear{
	display:flex;
	align-items:center;
	justify-content:center;
	position: absolute;
	top:0;
	right:0;
	z-index: 9999;   
	cursor:pointer;
	height:12px;
	width:12px;
	background:white;
	color:var(--black);
	font-size:0.6rem;
	font-weight:600;
	transform:translate(-30%,30%)
}

.conectereMessagingGiphyGridContainer {
	background-color: white;
	max-height: 40vh;
	overflow-y:scroll;
	/*width:450px;*/
	width:100%;
}

.conectereMessagingGiphySearchContainer{
	background:white;
	width:100%;
}
.conectereMessagingGiphyBorder {
	width:80%;
	border-bottom: 1px solid lightgrey;
}



/* Message - Avatar text */


.displayMessageAvatar{
	position:relative;
	background: var(--black);
	border-radius: 50%;
	color:white;
	/*padding:5px;*/
	font-weight:400;
	font-size:medium;
	margin:3px;
	width: 38px;
	height: 38px;
	text-align:center;
	vertical-align: middle;
	
}

.messageAvatarHoverTextRight {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the hover text*/
  position: absolute;
  /*top:10px;*/
  right:45px;
  z-index: 1;
}

.messageAvatarHoverTextLeft {
  visibility: hidden;
  width: 120px;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
 
  /* Position the hover text*/
  position: absolute;
  /*top:50px;*/
  left:45px;
  z-index: 1;
}



.displayMessageAvatar:hover +.messageAvatarHoverTextRight  {
  visibility: visible;
}

.displayMessageAvatar:hover +.messageAvatarHoverTextLeft  {
  visibility: visible;
}

/* Products */

/* cards in store and in admin orders dashboard - note, the detailed view in the Store gets it's own special classes below */
.productCard {
	flex-shrink:0;
	height: clamp(200px,20vw,475px);
	width: clamp(160px,16vw,380px);
	padding:5px;
	/* max-height: 25rem; */
	box-shadow: 0px 8px 16px 0 #BDC9D7;
	/*border: 1px solid green;*/
	border-radius: clamp(4px,0.4vw,10px);
	margin-right: clamp(4px,2vw,16px);
	margin-bottom: clamp(4px,2vw,16px);
	background-color: white;
	overflow: hidden;
	cursor: pointer;
	transition: transform 450ms;    /* Control transition time when scaling in hover */
	
}

/*This class gets dynamically added by the isLargeCard PROP */
.productCardLarge {
 height: clamp(250px,25vw,600px);
 width: clamp(200px,20vw,475px);
 max-height: 30rem;
}

.productCardDetailedView {
	 flex-shrink:0;
	box-shadow: 0px 8px 16px 0 #BDC9D7;
	/*border: 1px solid green;*/
	border-radius: 16px;
	margin-right: 10px;
	background-color: white;
	overflow: hidden;
	cursor: pointer;
	height: auto;
	width: 45rem;
	cursor:default;
}

.productCardDetailedView:hover   {
	transform: scale(1.0);
	z-index: 1;

}

.productCard:hover   {
	transform: scale(1.05);
	z-index: 1;

}

.productBody {
	position:relative;
	display:flex;
	flex-direction:column;
	width: 100%;
	height: 100%;
	align-items:flex-start;
	justify-content:space-between;
	/*background-color: yellow;*/
}

.productHeader {
	flex-shrink:0;
	width: 100%; 
	/* height: 5rem; */
	padding: clamp(6px,0.6vh,14px);
	/* padding: 5px 5px 0px 5px; */
	/* font-size:1.4rem; */
	/* color: #6e6e6f; */
	/* font-weight: 500; */
	/*background-color: green;*/
	text-align:center;
	overflow:hidden;
}

.productImageWrapper {
  position: relative;
  width: 100%;
  height: clamp(100px,20vh, 200px);
  vertical-align: middle;
  overflow: hidden;
  /*border-radius: 50%;*/
  margin: 0px 0px;
  text-align:center;
}

.productImageWrapperLarge{
   /* height: 250px; */
}

.productCenterImage {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 100%;
  width: auto;
  -webkit-transform: translate(-50%,-50%);
	  -ms-transform: translate(-50%,-50%);
		  transform: translate(-50%,-50%);
}

.productCostImage {
	height:32px;
	padding-left:2px;
}
.productDescription {
	width: 100%;
	/* height: 5rem; */
	padding-left: clamp(4px,.03vw,8px);
	/* font-size:0.8rem; */
	/* color: #6e6e6f; */
	/* font-weight: 400; */
	/*font-family: 'Patua One', cursive;*/
	/*background-color: #99999999;*/
	text-align:left;
	overflow:hidden;
}

.productDescriptionLarge {
	/* height: auto;
	font-size:1rem;
	font-weight: 500; */
}

.productCostRow {
	padding:clamp(4px,0.5vw,16px);
}

.productCostRowLarge {
	height: 2rem;
	font-weight: 500;
	font-size:1.2rem;   
}

.productCustomerNameRow {
	position:absolute;
	bottom:0;
	right:0;
	transform:translate(-80%,-20%);
	flex-grow: 0;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	color: #6e6e6f;   
}

.productButton {
	cursor: pointer;
	color:#fff;
	outline: none;
	border: none;
	font-weight: 700;
	border-radius: 0.2vw;
	padding: 0.5rem 2rem;
	background-color: rgba(51, 51, 51, 0.5);
}

/* The following CSS is for the detailed view in the Employee store - should merge with above */
.productDetailedCard {
	position:relative;
	height: auto;
	width: 100%;
	padding:clamp(4px,0.8vw,14px);
	box-shadow: 0px 8px 16px 0 #BDC9D7;
	border-radius: 16px;
	background-color: white;
	overflow: hidden;
	/*cursor: pointer;*/
	transition: transform 450ms;    /* Control transition time when scaling in hover */
}

.productDetailedHeader {
	padding: clamp(4px, 0.7vw, 12px);
}

.productDetailedImageWrapper {
  position: relative;
  width: 100%;
  min-height: 400px;
  vertical-align: middle;
  overflow: hidden;
  /*border-radius: 50%;*/
  margin: 0px 0px;
  text-align:center;
  /*background-color: red;*/
}

.productDetailedCenterImage {
	position: absolute;
	left: 50%;
	top: 50%;
	height: 100%;
	width: auto;
	-webkit-transform: translate(-50%,-50%);
		-ms-transform: translate(-50%,-50%);
			transform: translate(-50%,-50%);
  }

.productDetailedCostRow {
	position:absolute;
	bottom:0;
	left:0;
	flex-grow: 0;
	transform:translate(25%,0);
	/* display:flex;
	flex-direction:row; */
	/*width: 100%;*/
	/* height: 2rem; */
	/* padding:20px 10px; */
	/* align-items:center;
	justify-content:flex-start; */
	/* font-size:1.5rem;
	font-weight:500; */
	/*background-color: pink;    */
}

.productDetailedButton {
	cursor: pointer;
	color:#fff;
	outline: none;
	border: none;
	font-weight: 700;
	border-radius: 0.2vw;
	padding: 0.5rem 2rem;
	background-color: rgba(51, 51, 51, 0.5);
}

.productDetailedButtonRow {
	width:100%;
	display:flex;
	flex-direction:row;
	align-items: center;
	justify-content: center;
	padding: 20px 0px 5px 0px;
}

.productDetailedButton:hover {
	color: #000;
	background-color: #e6e6e6;
	transition: all 0.3s;
}



/* End Products */

/* Report Pages CSS */

.adminReportPageOuterWrapper{
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:flex-start;
	background-color: white;
	width: 95vw;
	max-width: 1400px;
	height: 100%;
	min-height: 90vh;
	border-radius: clamp(4px,0.4vw,10px);
	/*border: 5px solid var(--TEAM-purple);*/
	margin-top: 20px;
	/*margin: 100px 200px;  */
	/*margin-left: 100px;*/
	/*padding: 20px 20px;*/
	/*padding: 10px 10px;*/
	color: #409cf7;
}


/* RECHARTS OVERLOAD */

.recharts-cartesian-axis-tick {    
	font-size: 12px;
	font-family: Roboto, sans-serif;
}


.creditDataStyle { color:green;  border: none; }
.debitDataStyle { color:red;  border: none;   }
.memoDataStyle { padding: clamp(4px,1vw,10px);  border: none; font-style: italic}

/* Banners - Launchpad, Employee Store, Community */

.bannerHeader {
	position:relative;
	/*background-size: 40% auto;         /*Size is set to 40% of our banner width with the height adjusting to fit */
	background-size: auto 95%;         /*Size is set to 95% of our banner height with the width adjusting to fit */
	color:var(--dark-grey);   

	/* background-size: contain; */
	background-repeat: no-repeat;
	/* background-position:75% 50%;    Center in RIGHT HALF of screen */
	background-position:right 8vw center;    /*position at 8% indent from RIGHT, centered vertically in our banner */


	height:clamp(250px,25vw,500px); /* Note - a function of screen WIDTH to shrink with width */
	display:flex;
}

.darkMode .bannerHeader {
	color:white;
}

.bannerInnerContainer {
	/* background-color:yellow; */
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;         /*Center vertically and expand up/down to fill banner */
	height: 100%; /* expands as needed to fill the banner, which pushes the fader to the bottom */
}

.bannerContents {
	z-index: 1;     /* Put the text and buttons on top of the fade */
	min-width: 50vw;
	/*flex-grow:0;*/
	/*flex-shrink:0;*/
	margin-left: clamp(10px,3vw,30px);
	/*padding-top: clamp(10px,7vw,140px);  /* Note - a function of screen WIDTH to shrink with widht */
	/*height: 190px;*/
	/*background-color:yellow;*/
}

/* Font size is a function of view port so it shrinks with the window */
.bannerTitle {
	/* font-size: clamp(16px,1.8vw,40px);  
	font-weight: 800; */
	max-width: 50vw;
	padding-bottom: .3vw;
	overflow:hidden;
}

.bannerDescription {
	/*height: 80px;*/
	width: 125rem;
	max-width: 40vw;    /*Limit max width to half the screen so we don't overlap the image */
	line-height: clamp(16px, 1.5vw, 32px);
	padding-top: 1vw;
	font-size: clamp(8px, 1.2vw, 24px);
	overflow:hidden;
}

.bannerButtons {
	display:flex;
	align-items:center;
	justify-content: flex-start;
}

.bannerButton {
	cursor: pointer;
	color:#fff;
	outline: none;
	border: none;
	font-weight: 700;
	border-radius: 0.2vw;
	padding-left: clamp(12px, 1.2vw, 24px);
	padding-right: clamp(12px, 1.2vw, 24px);
	/*margin-top: 1rem;*/
	padding-top: clamp(8px,0.8vw, 16px);
	background-color: rgba(51, 51, 51, 0.5);
	padding-bottom: clamp(8px,0.8vw, 16px);
	font-size: clamp(8px, 1.2vw, 24px);
	margin: clamp(2px,0.4vw,8px);
}

.bannerButton:hover {
	color: #000;
	background-color: #e6e6e6;
	transition: all 0.3s;
}

.bannerFadeLeftToRight{
	z-index: 0;
	position:absolute;
	top:0;
	left:0;
	flex-grow:0;
	flex-shrink:0;    
	width:100%;
	height: 100%;
	background: linear-gradient(to left, rgba(1,1,1,0.01) 0%, rgba(1,1,1,.9) 100%);
	background-position: 0 100%;
	background-size: 100% 100%;
	background-repeat: repeat-x;       
}


/* 
	93, 173, 226  - Balance (blue)
	187,143,206 - Team (purple)
	130, 224, 170  - Social (green)
	247, 220, 111 - Personal (yellow)
	64, 156, 247 - Blue Section Header (#409cf7) 
*/

.bannerFadeLeftToRightBalance{
	background: linear-gradient(to left, rgba(93,173,226,0.001) 0%, rgba(93,173,226,.2) 20%, rgba(93,173,226,.9) 100%);
}
.bannerFadeLeftToRightTeam {
	background: linear-gradient(to left, rgba(187,143,206,0.001) 0%, rgba(187,143,206,.2) 20%, rgba(187,143,206,.9) 100%);
}
.bannerFadeLeftToRightSocial {
	background: linear-gradient(to left, rgba(130, 224, 170,0.001) 0%, rgba(130, 224, 170,.2) 20%, rgba(130, 224, 170,.9) 100%);
}
.bannerFadeLeftToRightGrowth {
	background: linear-gradient(to left, rgba(247, 220, 111,0.01) 0%, rgba(247, 220, 111,.2) 20%, rgba(247, 220, 111,.9) 100%);
}


.bannerFadeBottom {
	position:absolute;
	bottom:0;
	left:0;
	flex-grow:0;
	flex-shrink:0;    
	width:100vw;
	height: 50%;
	background: linear-gradient(to bottom, rgba(1,1,1,0.001) 0%, rgba(1,1,1,.8) 100%);
	background-position: 0 100%;
	background-size: 100% 100%;
	background-repeat: repeat-x;       
}

.bannerStarWrapper {
	/*width:100px;*/
	font-size: 1.0vw;
}

   /* Conectivity Rings */

.conectivityRingBackgroundWrapper{
	/* height: 300px ;
	width: 250px; */
	margin-left: clamp(2px, 0.3vw,5px);
	margin-right: clamp(2px, 0.3vw,5px);
	border-radius: 0.3vw;
	overflow:hidden;
	background:var(--light-mode-card-background);
	box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.ringsPreview .conectivityRingBackgroundWrapper{
	padding:clamp(2px,0.5vw,4px);
}

.darkMode .conectivityRingBackgroundWrapper{
	background:var(--dark-mode-card-background);
}

.conectivityRingsWrapper{
	padding-top: clamp(4px, 1vw, 10px);
	padding-right: clamp(4px, 0.5vw,10px);
	padding-bottom: clamp(4px, 1vw,10px);
	padding-left: clamp(4px, 0.5vw,10px);
}

.conectivityRingsWrapperBalance {
	/*width: auto;*/
	background: rgba(93, 173, 226 ,0.1);
	color: var(--BALANCE-blue);
}

.conectivityRingsWrapperTeam {
	/*width: auto;*/
	background: rgba(187,143,206 ,0.1);
	color: var(--TEAM-purple);

}
.conectivityRingsWrapperSocial {
	/*width: auto;*/
	background: rgba(130, 224, 170,0.1);
	color: var(--SOCIAL-green);

}
.conectivityRingsWrapperPersonal {
	/*width: auto;*/
	background: rgba(247, 220, 111,0.1);
	color: var(--GROWTH-yellow);
}

.conectivityRingsInfoRow {
	/* height: 120px; */
	opacity: 1;
}

.conectivityRingsInfoRowLeft {
	flex: 3;
	/* padding-left: 0.3vw; */
}

.conectivityRingsInfoRowRight {
	flex: 1.5;
}

.ccRing {
	width: 180px;
	height: 180px;
	position: relative;  /*relative positioning so we can align the backgroud / foreground rings and the image */
}

.ccAnimatedRingOuterWWrapper {
	position: relative;
	height: 115px;
	width: 115px;
}

.ccAnimatedRing {
	position: relative;
	height: 105px;
	width: 105px;
}

.ccAnimatedRingBgRing {
	width: 105px;
	height: 105px;
	z-index:1;
	position: absolute;
	top:0;
	left:0;
}

.ccAnimatedRingForegroundRing {
	width: 105px;
	height: 105px;
	z-index:1;
	position: absolute;
	top:0;
	left:0;
}

.ccRingBackgroundRing {
	width: 180px;
	height: 180px;
	z-index:1;
	position: absolute;
	top:0;
	left:0;
	/*background-color: red;*/
}


.ccRingForegroundRing {
	width: 180px;
	height: 180px;
	
	z-index:2;
	position: absolute;
	top:0;
	left:0;
	
}

.ccRingImage {
	z-index:3;
	position: absolute;
	top:50px;
	left:50px;
}


.ccRingPercent {
	z-index:3;
	position: absolute;
	top: 50%;            
	left:50%;
	width: 100%;
	text-align: center;
	transform: translate(-50%,-50%);    /* Center the text */
}

.conectivityRingsStreakLabel{
	position:absolute;
	bottom:5px;
	right:2px;
	width: 70px;
	height: 30px;
	border-radius: 8px; 
}

.conectivityRingsStreakLabelLeftRings {
	position: absolute;
	left: 0 !important;
	bottom: 5px;
	height: 30px;
	width: fit-content;
}


.animatedProgressRing {
  transition: stroke-dashoffset 5.0s;
}

.connectivityProgressCardWrapper {
	/* flex: 1; */
	align-self: flex-start;
	gap: 30px;
}

.connectivityRingInfoInnerWrapper {
	flex: 1;
}

.animatedRingsInnerWrapper {
	gap: 20px;
}

.ringCardOrder1 {
	order: 1;
}

.ringCardOrder2 {
	order: 2;
}

.ringCardOrder3 {
	order: 3;
}

.ringInfoTitle {
	margin: 0 !important;
	text-align: left;
}

/* End Conectivity Rings */

/* Conectere Master Dashboard */
.section-conectere-dashboard .dashboardCardRow {
	/* background-color: var(--soft-grey); 
	border-radius: clamp(4px,0.5vw,10px); */
	justify-content: space-evenly;
}


/* End Conectere Master Dashboard */


/*                            */
/*     MEDIA ADJUSTMENTS      */
/*                            */

/* MED LAPTOP */
@media  only screen and (max-width: 1520px) {

	.headerLinkIcon{
		display:none;
	}    
}

/* SMALL LAPTOP */
@media  only screen and (max-width: 1440px) {

	.pageWrapper{
		line-height: unset !important;
		font-size: 16px;
	}
	.dashboardTitle {
		font-size:1.2rem;
		font-weight: 600;
	}

	.dashboardCard {
		width: 300px;
		/* margin: 10px 10px; */
		/* padding: 0px 0px 5px 0px;  */
		border-radius: 5px;
	}

	.dashboardCardWideProfile {
		width: 425px;
	}

	.dashboardScoreRectangle {
		height: 40px;
		width: 48px;
		font-size:1.0rem !important;
	}

	.dashboardScoreCircle {
		font-size: 16px;
		font-weight: 700;
		border: 3px solid var(--grey-blue);
		height: 48px;
		width:48px;
	}

	.dashboardScoreOval
	{
		margin: 5px;
		padding: 3px;
		border-radius: clamp(4px,0.4vw,10px);
		border: 2px solid var(--TEAM-purple);
		font-size:0.8rem;
		font-weight: 500;
	}

	.dashboardCardTitle {
		font-size:1.1rem;   
	}
	
	.dashboardCardText, .dashboardCardNumber, .dashboardCardAward {
		font-weight: 600;
		font-size: 0.9rem;
	}
	

	.dashboardCardTextSmall {
		font-size: 0.8rem;
	}
	
	
	.dashboardCardTextFooter {
		padding: 5px 0px 5px 0px; 
		font-size: 0.6rem;
	}   

	.TextStyle1 {
		font-size: 0.6rem !important;
		line-height: 0.8rem !important;
	}
	
	.TextStyle2 {
		font-size: 0.8rem !important;
		line-height: 1rem !important;
	}
	
	.TextStyle3, .avatarInitials {
		font-size: 0.9rem !important;
		line-height: 1.1rem !important;
	}
 
	.TextStyle4 {
		font-size: 1.0rem !important;
		line-height: 1.2rem !important;
	}
   
	.TextStyle4_3 {
		font-size: 1.1rem !important;
		line-height: 1.2rem !important;
	}

	.TextStyle5 {
		font-size: 1.2rem !important;
		line-height: 1.3rem !important;
	}
	
	.TextStyle6 {
		font-size: 1.5rem !important;
		line-height: 1.7rem !important;
	}

	.TextStyle6A {
	  font-size: 1.8rem !important;
	  line-height: 2.0rem !important;
  }

	.TextStyle7 {
		font-size: 2.7rem !important;
		line-height: 2.8rem !important;
	  }

	.buttonStyle1, .buttonStyle2 {
		border-radius: 0.3vw;
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;
		font-weight: 400;
		font-size: 0.9rem;
	}
	
	.conectivityRingBackgroundWrapper {
		/* height: 240px !important;
		width: 200px !important; */
	}

	.conectivityRingsInfoRow {
		/* height:96px !important; */
	}    
	.ccRing {
		width: 144px !important;
		height: 144px !important;
	}
	
	.ccRingBackgroundRing {
		width: 144px !important;
		height: 144px !important;
	}
	
	
	.ccRingForegroundRing {
		width: 144px !important;
		height: 144px !important;
	}
	.ccImgXXXXXXXL {
		height:256px !important;    
	}
	.ccImgXXXXXXL{
		height:192px !important;    
	}
	.ccImgXXXXXL{
	  height:156px !important;    
	}    
	.ccImgXXXXL{
	  height:72px !important;    
	}

	.ccImgXXXL{
	  height:51px !important;    
	}
	
	
	.ccImgXXL{
	  height:40px !important;    
	}
	
	.ccImgXL{
	  height:22px !important;    
	}
	
	
	.ccImgLarge{
	  height:19px !important;    
	}
	
	.ccImgMedium{
	  height:14px !important;    
	}
	
	.ccImgSmall{
	  height:10px !important;    
	}   

   .ccImgXXXXXLSquare{
	  height:156px !important;    
	  width:156px !important;    
	}    
	
	.ccImgXXXXLSquare{
	  height:72px !important;    
	  width:72px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:51px   !important;  
	  width: 51px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:40px !important;     
	  width:40px !important;     
	}
	
	.ccImgXLSquare{
	  height:22px !important;     
	  width:22px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:19px !important;     
	  width:19px !important;     
	}
	
	.ccImgMediumSquare{
	  height:14px !important;     
	  width:14px !important;     
	}
	
	.ccImgSmallSquare{
	  height:10px !important;     
	  width:10px !important;     
	}
   
	.avatarImageXXLarge {
		width: 96px;
		height: 96px;
	}

	.avatarImageXLarge {
		width: 72px;
		height: 72px;
	}
	
	.avatarImageLarge {
		width: 51px;
		height: 51px;
	}
	
	.avatarImageMediumLG {
	  width: 44px;
	  height: 44px;
	}
	
	
	.avatarImageMedium {
	  width: 38px;
	  height: 38px;
	}
	
	.avatarImageSmall {
	  width: 24px;
	  height: 24px;
	}
	
	.avatarImageTiny {
	  width: 20px;
	  height: 20px;
	}
	
	.awardImgXXL{
		height:82px;
	}
	  
	.awardImgXL{
		height:64px;
	}
	  
	.awardImgLarge{
		height:48px;
	}
	  
	.awardImgMedium{
		height:36px;
	}
	  
	.awardImgSmall{
		height:30px;
	}

	.recharts-cartesian-axis-tick {    
		font-size: 10px;
	}

	h1{
		font-size: 68px !important;
		line-height: 68px !important;
		font-weight: 700 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h2{
		font-size: 42px !important;
		line-height: 42px !important;
		font-weight: 650 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h3{
		font-size: 30px !important;
		line-height: 30px !important;
		font-weight: 650 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h4{
		font-size: 22px !important;
		line-height: 22px !important;
		font-weight: 600 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	
	h5{
		font-size: 16px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h6{
		font-size: 11px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	  
}

@media only screen and (max-width: 1325px){

    .pageWrapper{
        line-height: unset !important;
        font-size: 16px;
    }

	.dashboardSpotlightContainer {
		width: 100% !important;
	}

	.dashboardRingViewContainer {
		width: 100% !important;
	}

	.dashboardTitle {
		font-size:1.2rem;
		font-weight: 600;
	}

	.dashboardCard {
		width: 300px;
		/* margin: 10px 10px;
		padding: 0px 0px 5px 0px;  */
		border-radius: 5px;
	}

	.dashboardCardWideProfile {
		width: 425px;
	}

	.dashboardScoreRectangle {
		height: 40px;
		width: 48px;
		font-size:1.0rem !important;
	}

	.dashboardScoreCircle {
		font-size: 16px;
		font-weight: 700;
		border: 3px solid var(--grey-blue);
		height: 48px;
		width:48px;
	}

	.dashboardScoreOval
	{
		margin: 5px;
		padding: 3px;
		border-radius: clamp(4px,0.4vw,10px);
		border: 2px solid var(--TEAM-purple);
		font-size:0.8rem;
		font-weight: 500;
	}

	.dashboardCardTitle {
		font-size:1.1rem;   
	}
	
	.dashboardCardText, .dashboardCardNumber, .dashboardCardAward {
		font-weight: 600;
		font-size: 0.9rem;
	}
	

	.dashboardCardTextSmall {
		font-size: 0.8rem;
	}
	
	
	.dashboardCardTextFooter {
		padding: 5px 0px 5px 0px; 
		font-size: 0.6rem;
	}   

	.TextStyle1 {
		font-size: 0.6rem !important;
		line-height: 0.7rem !important;
	}
	
	.TextStyle2 {
		font-size: 0.8rem !important;
		line-height: 0.9rem !important;
	}
	
	.TextStyle3, .avatarInitials {
		font-size: 0.9rem !important;
		line-height: 1.1rem !important;
	}
 
	.TextStyle4 {
		font-size: 1.0rem !important;
		line-height: 1.2rem !important;
	}
   
	.TextStyle4_3 {
		font-size: 1.1rem !important;
		line-height: 1.2rem !important;
	}

	.TextStyle5 {
		font-size: 1.2rem !important;
		line-height: 1.3rem !important;
	}
	
	.TextStyle6 {
		font-size: 1.5rem !important;
		line-height: 1.7rem !important;
	}

	.TextStyle6A {
	  font-size: 1.8rem !important;
	  line-height: 2.2rem !important;
  }

	.TextStyle7 {
		font-size: 2.7rem !important;
		line-height: 2.8rem !important;
	  }

	.buttonStyle1, .buttonStyle2 {
		border-radius: 0.3vw;
		padding-top: 0.2rem;
		padding-bottom: 0.2rem;
		font-weight: 400;
		font-size: 0.9rem;
	}
	
	.conectivityRingBackgroundWrapper {
		/* height: 240px !important;
		width: 200px !important; */
	}

	.conectivityRingsInfoRow {
		/* height:96px !important; */
	}    
	.ccRing {
		width: 144px !important;
		height: 144px !important;
	}
	
	.ccRingBackgroundRing {
		width: 144px !important;
		height: 144px !important;
	}
	
	
	.ccRingForegroundRing {
		width: 144px !important;
		height: 144px !important;
	}
	.ccImgXXXXXXXL {
		height:192px !important;    
	}
	.ccImgXXXXXXL{
		height:172px !important;    
	}
	.ccImgXXXXXL{
	  height:132px !important;    
	}    
	.ccImgXXXXL{
	  height:68px !important;    
	}

	.ccImgXXXL{
	  height:50px !important;    
	}
	
	
	.ccImgXXL{
	  height:34px !important;    
	}
	
	.ccImgXL{
	  height:22px !important;    
	}
	
	
	.ccImgLarge{
	  height:19px !important;    
	}
	
	.ccImgMedium{
	  height:14px !important;    
	}
	
	.ccImgSmall{
	  height:10px !important;    
	}   

   .ccImgXXXXXLSquare{
	  height:156px !important;    
	  width:156px !important;    
	}    
	
	.ccImgXXXXLSquare{
	  height:72px !important;    
	  width:72px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:51px   !important;  
	  width: 51px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:40px !important;     
	  width:40px !important;     
	}
	
	.ccImgXLSquare{
	  height:22px !important;     
	  width:22px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:19px !important;     
	  width:19px !important;     
	}
	
	.ccImgMediumSquare{
	  height:14px !important;     
	  width:14px !important;     
	}
	
	.ccImgSmallSquare{
	  height:10px !important;     
	  width:10px !important;     
	}
   
	.avatarImageXXLarge {
		width: 96px;
		height: 96px;
	}

	.avatarImageXLarge {
		width: 72px;
		height: 72px;
	}
	
	.avatarImageLarge {
		width: 51px;
		height: 51px;
	}
	
	.avatarImageMediumLG {
	  width: 44px;
	  height: 44px;
	}
	
	
	.avatarImageMedium {
	  width: 38px;
	  height: 38px;
	}
	
	.avatarImageSmall {
	  width: 24px;
	  height: 24px;
	}
	
	.avatarImageTiny {
	  width: 20px;
	  height: 20px;
	}    

	.recharts-cartesian-axis-tick {    
		font-size: 10px;
	}

	h1{
		font-size: 68px !important;
		line-height: 68px !important;
		font-weight: 700 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h2{
		font-size: 42px !important;
		line-height: 42px !important;
		font-weight: 650 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h3{
		font-size: 30px !important;
		line-height: 30px !important;
		font-weight: 650 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h4{
		font-size: 22px !important;
		line-height: 22px !important;
		font-weight: 600 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	
	h5{
		font-size: 16px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h6{
		font-size: 11px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	  
}


@media only screen and (max-width: 1200px){
    .userDashboardFirstRow {
        flex-direction: column !important;
    }
    
    /*Switch navigation mode of header to only show icons.  hide navigation text link but now show as hover text */
    .headerLinkText{
        display:none;
    }    
    .headerLinkIcon{
        display:block;
    }    
    .headerLinkHoverText {
        visibility:visible;
    }
    .pageWrapper{
        font-size: 14px;
    }

 
	 .dashboardTitle {
		font-size:1.2rem;
		font-weight: 600;
	}

	.dashboardCard {
		width: 300px;
		/* margin: 10px 10px;
		padding: 0px 0px 5px 0px;  */
		border-radius: 5px;
	}

	.dashboardCardWideProfile {
		width: 425px;
	}

	.dashboardScoreRectangle {
		height: 32px;
		width: 40px;
		font-size:0.9rem !important;

	}

	.dashboardScoreCircle {
		font-size: 16px;
		font-weight: 700;
		border: 3px solid var(--grey-blue);
		height: 48px;
		width:48px;
	}

	.dashboardScoreOval
	{
		margin: 5px;
		padding: 3px;
		border-radius: clamp(4px,0.4vw,10px);
		border: 2px solid var(--TEAM-purple);
		font-size:0.8rem;
		font-weight: 500;
	}

	.dashboardCardTitle {
		font-size:1.1rem;   
	}
	
	.dashboardCardText, .dashboardCardNumber, .dashboardCardAward {
		font-weight: 600;
		font-size: 0.9rem;
	}
	

	.dashboardCardTextSmall {
		font-size: 0.8rem;
	}
	
	
	.dashboardCardTextFooter {
		padding: 5px 0px 5px 0px; 
		font-size: 0.6rem;
	}   
	
	.TextStyle1 {
		font-size: 0.65rem !important;
		font-weight: 400 !important;
		line-height: 0.75rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.7rem !important;
		font-weight: 400 !important;
		line-height: 0.8rem !important;
	}
	
	.TextStyle3, .avatarInitials {
		font-size: 0.8rem !important;
		line-height: 0.9rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.9rem !important;
		line-height: 1.1rem !important;
	  }
	
	  .TextStyle4_3 {
		font-size: 1.0rem !important;
		line-height: 1.1rem !important;
	}

	.TextStyle5 {
		font-size: 1.1rem !important;
		line-height: 1.2rem !important;
	  }
	
	.TextStyle6 {
		font-size: 1.3rem !important;
		line-height: 1.4rem !important;
	  }    

	.TextStyle6A {
		font-size: 1.5rem !important;
		line-height: 1.7rem !important;
	}

	.TextStyle7 {
		font-size: 2.5rem !important;
		line-height: 2.6rem !important;
	  }
	
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.8rem;
	} 

}


@media  only screen and (max-width: 1024px) {

	.pageWrapper{
		font-size: 12px;
	}
	 .dashboardTitle {
		font-size:1.1rem;
		font-weight: 600;
	}
	
	/* .dashboardCard {
		width: 280px;
		margin: 5px;
		padding: 0px 0px 5px 0px; 
		border-radius: 5px;
	} */

	.dashboardCardWideProfile {
		width: 350px;
	}
	
	.dashboardScoreRectangle {
		height: 32px;
		width: 40px;
		font-size:0.8rem !important;
	}

	.dashboardScoreCircle {
		font-size: 14px;
		font-weight: 700;
		border: 3px solid var(--grey-blue);
		height: 44px;
		width:44px;
	}
	
	.dashboardCardTitle {
		font-size:1.0rem;   
	}
	

	.dashboardCardText, .dashboardCardNumber, .dashboardCardAward {
		font-weight: 500;
		font-size: 0.7rem;
	}


	.dashboardCardTextSmall {
		font-weight: 500;
		font-size: 0.6rem;
	}
	
	
	.dashboardCardTextFooter {
		padding:  0px; 
		font-size: 0.5rem;
	}   

	
	.TextStyle1 {
		font-size: 0.6rem !important;
		font-weight: 400 !important;
		line-height: 0.7rem !important;
	}
	
	.TextStyle2 {
		font-size: 0.7rem !important;
		font-weight: 400 !important;
		line-height: 0.8rem !important;
	}
	
	.TextStyle3, .avatarInitials {
		font-size: 0.8rem !important;
		line-height: 0.9rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.85rem !important;
		line-height: 1.1rem !important;
	  }
	
	  .TextStyle4_3 {
		font-size: 0.9rem !important;
		line-height: 1.0rem !important;
	}

	.TextStyle5 {
		font-size: 1.0rem !important;
		line-height: 1.1rem !important;
	  }
	
	.TextStyle6 {
		font-size: 1.1rem !important;
		line-height: 1.2rem !important;
	}        

	.TextStyle6A {
		font-size: 1.3rem !important;
		line-height: 1.5rem !important;
	}     

	.TextStyle7 {
		font-size: 2.2rem !important;
		line-height: 2.3rem !important;
	  }    
	
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.7rem;
	}   
	
   .conectivityRingBackgroundWrapper {
		/* height: 225px !important;
		width: 188px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:90px !important; */
	}        
	.ccRing {
		width: 135px !important;
		height: 135px !important;
	}
	
	.ccRingBackgroundRing {
		width: 135px !important;
		height: 135px !important;
	}    
	
	.ccRingForegroundRing {
		width: 135px !important;
		height: 135px !important;
	}   
	.ccImgXXXXXXXL {
		height:172px !important;    
	}
	.ccImgXXXXXXL{
		height:156px !important;    
	}
   .ccImgXXXXXL{
	  height:128px !important;    
	}    
	
	.ccImgXXXXL{
	  height:64px !important;    
	}

	.ccImgXXXL{
	  height:48px !important;    
	}
	
	
	.ccImgXXL{
	  height:32px !important;    
	}
	
	.ccImgXL{
	  height:21px !important;    
	}
	
	
	.ccImgLarge{
	  height:16px !important;    
	}
	
	.ccImgMedium{
	  height:11px !important;    
	}
	
	.ccImgSmall{
	  height:9px !important;    
	} 

   .ccImgXXXXXLSquare{
	  height:128px !important;    
	  width:128px !important;    
	}  
	
   .ccImgXXXXLSquare{
	  height:64px !important;    
	  width:64px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:48px   !important;  
	  width: 48px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:32px !important;     
	  width:32px !important;     
	}
	
	.ccImgXLSquare{
	  height:21px !important;     
	  width:21px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:16px !important;     
	  width:16px !important;     
	}
	
	.ccImgMediumSquare{
	  height:11px !important;     
	  width:11px !important;     
	}
	
	.ccImgSmallSquare{
	  height:9px !important;     
	  width:9px !important;     
	}
  
	.avatarImageXXLarge {
		width: 72px;
		height: 72px;
	}

	.avatarImageXLarge {
		width: 64px;
		height: 64px;
	}
	
	.avatarImageLarge {
		width: 44px;
		height: 44px;
	}
	
	.avatarImageMediumLG {
	  width: 40px;
	  height: 40px;
	}
	
	
	.avatarImageMedium {
	  width: 32px;
	  height: 32px;
	}
	
	.avatarImageSmall {
	  width: 20px;
	  height: 20px;
	}
	
	.avatarImageTiny {
	  width: 16px;
	  height: 16px;
	}      
	
	.awardImgXXL{
		height:80px;
	}
	  
	.awardImgXL{
		height:62px;
	}
	  
	.awardImgLarge{
		height:46px;
	}
	  
	.awardImgMedium{
		height:34px;
	}
	  
	.awardImgSmall{
		height:28px;
	}
 
	 .recharts-cartesian-axis-tick {    
		font-size: 8px;
	}   
  
	h1{
		font-size: 56px !important;
		line-height: 56px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h2{
		font-size: 38px !important;
		line-height: 38px !important;
		font-weight: 650 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h3{
		font-size: 26px !important;
		line-height: 26px !important;
		font-weight: 650 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h4{
		font-size: 18px !important;
		line-height: 18px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	
	h5{
		font-size: 13px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h6{
		font-size: 10px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}      
}

@media only screen and (max-width: 993px){

	.pageWrapper{
		font-size: 12px;
	}
 
	 .dashboardTitle {
		font-size:1.1rem;
		font-weight: 500;
	}
	
	/* .dashboardCard {
		width: 250px;
		margin: 3px;
	} */

   .dashboardCardWideProfile {
		width: 300px;
	}
	
	.dashboardCardTitle {
		font-size:0.9rem;   
	}
	

	.dashboardCardText, .dashboardCardNumber, .dashboardCardAward {
		font-size: 0.6rem;
	}


	.dashboardCardTextSmall {
		font-size: 0.5rem;
	}
	
	
	.dashboardCardTextFooter {
		font-size: 0.4rem;
	}      

	
	.TextStyle1 {
		font-size: 0.5rem;
		font-weight: 400;
		line-height: 0.55rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.6rem;
		font-weight: 400;
		line-height: 0.65rem !important;
	  }
	
	.TextStyle3, .avatarInitials {
		font-size: 0.6rem;
		line-height: 0.7rem !important;
	  }
	
	.TextStyle4 {
		font-size: 0.7rem;
		line-height: 0.9rem !important;
	  }
	
	  .TextStyle4_3 {
		font-size: 0.85rem !important;
		line-height: 1.0rem !important;
	}

	.TextStyle5 {
		font-size: 0.8rem;
		line-height: 0.9rem !important;
	  }
	
	.TextStyle6 {
		font-size: 1.0rem;
		line-height: 1.15rem !important;
	  }   

	.TextStyle6A {
		font-size: 1.2rem !important;
		line-height: 1.3rem !important;
	}     

	.TextStyle7 {
		font-size: 1.8rem !important;
		line-height: 1.9rem !important;
	  }

	  .buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.6rem;
	}   

   .conectivityRingBackgroundWrapper {
		/* height: 210px !important;
		width: 175px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:85px !important; */
	}        
	.ccRing {
		width: 126px !important;
		height: 126px !important;
	}
	
	.ccRingBackgroundRing {
		width: 126px !important;
		height: 126px !important;
	}
	 
	.ccRingForegroundRing {
		width: 126px !important;
		height: 126px !important;
	}

    /* .ccAnimatedRingOuterWWrapper {
        height: 85px;
        width: 85px;
    }

    .ccAnimatedRing, .ccAnimatedRingBgRing, .ccAnimatedRingForegroundRing {
        height: 75px;
        width: 75px;
    } */

	.animatedRingsInnerWrapper {
		flex-direction: column !important;
	}  
	.ccImgXXXXXXXL {
		height:156px !important;    
	}
	.ccImgXXXXXXL{
		height:128px !important;    
	}
   .ccImgXXXXXL{
	  height:96px !important; 
   }
	.ccImgXXXXL{
	  height:58px !important;    
	}    
	.ccImgXXXL{
	  height:44px !important;    
	}
	
	
	.ccImgXXL{
	  height:32px !important;    
	}
	
	.ccImgXL{
	  height:20px !important;    
	}
	
	
	.ccImgLarge{
	  height:14px !important;    
	}
	
	.ccImgMedium{
	  height:12px !important;    
	}
	
	.ccImgSmall{
	  height:8px !important;    
	}
	
   .ccImgXXXXLSquare{
	  height:58px !important;    
	  width:58px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:44px   !important;  
	  width: 44px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:32px !important;     
	  width:32px !important;     
	}
	
	.ccImgXLSquare{
	  height:20px !important;     
	  width:20px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:14px !important;     
	  width:14px !important;     
	}
	
	.ccImgMediumSquare{
	  height:12px !important;     
	  width:12px !important;     
	}
	
	.ccImgSmallSquare{
	  height:8px !important;     
	  width:8px !important;     
	}  

}

/* TABLET */

@media  only screen and (max-width: 768px) {

	.conectivity-launcher-preview-portion{
		display:none;
	}

	.pageWrapper{
		font-size: 10px;
	}


   /* .btn-community {
		min-width: 0;
		background-color: white !important;
		box-shadow:none !important;       
	}*/
	 .dashboardTitle {
		font-size:1.0rem;
		font-weight: 600;
	}
	
	.dashboardCard {
		height:0;
		visibility:hidden;
	}

	.dashboardScoreRectangle {
		height: 24px;
		width: 32px;
		font-size:0.7rem !important;
	}

	.dashboardScoreCircle {
		font-size: 14px;
		font-weight: 700;
		border: 2px solid var(--grey-blue);
		height: 32px;
		width:32px;
	}

	.TextStyle1 {
		font-size: 0.5rem !important;
		line-height: 0.55rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.6rem !important;
		line-height: 0.7rem !important;
	  }
	
	.TextStyle3, .avatarInitials {
		font-size: 0.58rem !important;
		line-height: 0.62rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.7rem !important;
		line-height: 0.8rem !important;
	  }
	  .TextStyle4_3 {
		font-size: 0.8rem !important;
		line-height: 0.83rem !important;
	}

	.TextStyle5 {
		font-size: 0.8rem !important;
		line-height: 0.9rem !important;
	  }
	
	.TextStyle6 {
		font-size: 0.9rem !important;
		line-height: 1.1rem !important;
	  } 

	.TextStyle6A {
	font-size: 1.1rem !important;
	line-height: 1.2rem !important;
	}     

	.TextStyle7 {
		font-size: 1.6rem !important;
		line-height: 1.7rem !important;
	}
 
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.6rem;
	}   
	
	
   .conectivityRingBackgroundWrapper {
		/* height: 180px !important;
		width: 150px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:72px !important; */
	}        
	.ccRing {
		width: 108px !important;
		height: 108px !important;
	}
	
	.ccRingBackgroundRing {
		width: 108px !important;
		height: 108px !important;
	}
	 
	.ccRingForegroundRing {
		width: 108px !important;
		height: 108px !important;
	}  
	.ccImgXXXXXXXL {
		height:156px !important;    
	}
	.ccImgXXXXXXL{
		height:128px !important;    
	}
   .ccImgXXXXXL{
	  height:96px !important; 
   }    
	.ccImgXXXXL{
	  height:52px !important;    
	}
	
	.ccImgXXXL{
	  height:38px !important;    
	}
	
	
	.ccImgXXL{
	  height:24px !important;    
	}
	
	.ccImgXL{
	  height:16px !important;    
	}
	
	
	.ccImgLarge{
	  height:12px !important;    
	}
	
	.ccImgMedium{
	  height:10px !important;    
	}
	
	.ccImgSmall{
	  height:7px !important;    
	} 
 
   .ccImgXXXXLSquare{
	  height:52px !important;    
	  width:52px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:38px   !important;  
	  width: 38px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:24px !important;     
	  width:24px !important;     
	}
	
	.ccImgXLSquare{
	  height:16px !important;     
	  width:16px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:12px !important;     
	  width:12px !important;     
	}
	
	.ccImgMediumSquare{
	  height:10px !important;     
	  width:10px !important;     
	}
	
	.ccImgSmallSquare{
	  height:7px !important;     
	  width:7px !important;     
	}    
	.avatarImageXLarge {
		width: 48px;
		height: 48px;
	}
	
	.avatarImageLarge {
		width: 32px;
		height: 32px;
	}
	
	.avatarImageMediumLG {
	  width: 28px;
	  height: 28px;
	}
	
	
	.avatarImageMedium {
	  width: 24px;
	  height: 24px;
	}
	
	.avatarImageSmall {
	  width: 18px;
	  height: 18px;
	}
	
	.avatarImageTiny {
	  width: 12px;
	  height: 12px;
	} 

	.awardImgXXL{
		height:76px;
	}
	  
	.awardImgXL{
		height:58px;
	}
	  
	.awardImgLarge{
		height:42px;
	}
	  
	.awardImgMedium{
		height:30px;
	}
	  
	.awardImgSmall{
		height:24px;
	}
   
	h1{
		font-size: 32px !important;
		line-height: 32px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h2{
		font-size: 24px !important;
		line-height: 24px !important;
		font-weight: 650 !important;
		margin-top: 0.6rem !important;
		margin-bottom: 0.6rem !important;
	}
	
	h3{
		font-size: 18px !important;
		line-height: 18px !important;
		font-weight: 650 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h4{
		font-size: 12px !important;
		line-height: 12px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	
	h5{
		font-size: 10px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h6{
		font-size: 8px !important;
		font-weight: 500 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}         
}


/* PHONES */

@media  only screen and (max-width: 650px) {
	.headerLink, .headerLinkIcon, .btn-community {
		display:none;
	}
	.headerMenuCompressedContainer{
		display:block;
	}
	.headerChildRightInnerWrap{
		min-height:0px;
		/* background-color: var(--HEADER-blue); */
		min-width: 0;
		background-color: var(--light-mode-background);
		box-shadow:none;
	} 

	.darkMode .headerChildRightInnerWrap {
		background-color: var(--dark-mode-card-background) !important;
	}

	
	.conectivityRingsInfoRowLeftInner{
		display:none;
	}

	conectivityRingBackgroundWrapper {
		/* height: 144px !important;
		width: 120px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:57px !important; */
	}        

	.ccRing {
		width: 86px !important;
		height: 86px !important;
	}
	
	.ccRingBackgroundRing {
		width: 86px !important;
		height: 86px !important;
	}
	 
	.ccRingForegroundRing {
		width: 86px !important;
		height: 86px !important;
	}  
 

	.TextStyle1 {
		font-size: 0.45rem !important;
		font-weight: 400 !important;
		line-height: 0.48rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.5rem !important;
		font-weight: 400 !important;
		line-height: 0.55rem !important;
	  }
	
	.TextStyle3, .avatarInitials {
		font-size: 0.5rem !important;
		line-height: 0.6rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.6rem !important;
		line-height: 0.65rem !important;
	  }
	  .TextStyle4_3 {
		font-size: 0.7rem !important;
		line-height: 0.75rem !important;
	}

	.TextStyle5 {
		font-size: 0.75rem !important;
		line-height: 0.8rem !important;
	  }
	
	.TextStyle6 {
		font-size: 0.82rem !important;
		line-height: 0.88rem !important;
	  } 
	  .TextStyle6A {
		font-size: 1.0rem !important;
		line-height: 1.1rem !important;
		}     
	.TextStyle7 {
		font-size: 1.4rem !important;
		line-height: 1.5rem !important;
	}
 
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.5rem;
	}   
	.ccImgXXXXXXXL {
		height:128px !important;    
	}
	.ccImgXXXXXXL{
		height:96px !important;    
	}
   .ccImgXXXXXL{
	  height:72px !important; 
   }       
	.ccImgXXXXL{
	  height:44px !important;    
	}
	
	.ccImgXXXL{
	  height:30px !important;    
	}
	
	
	.ccImgXXL{
	  height:16px !important;    
	}
	
	.ccImgXL{
	  height:13px !important;    
	}
	
	
	.ccImgLarge{
	  height:11px !important;    
	}
	
	.ccImgMedium{
	  height:9px !important;    
	}
	
	.ccImgSmall{
	  height:5px !important;    
	} 
 
   .ccImgXXXXLSquare{
	  height:44px !important;    
	  width:44px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:30px   !important;  
	  width: 30px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:16px !important;     
	  width:16px !important;     
	}
	
	.ccImgXLSquare{
	  height:13px !important;     
	  width:13px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:11px !important;     
	  width:11px !important;     
	}
	
	.ccImgMediumSquare{
	  height:9px !important;     
	  width:9px !important;     
	}
	
	.ccImgSmallSquare{
	  height:5px !important;     
	  width:5px !important;     
	}    
	.avatarImageXLarge {
		width: 32px;
		height: 32px;
	}
	
	.avatarImageLarge {
		width: 28px;
		height: 28px;
	}
	
	.avatarImageMediumLG {
	  width: 22px;
	  height: 22px;
	}
	
	
	.avatarImageMedium {
	  width: 18px;
	  height: 18px;
	}
	
	.avatarImageSmall {
	  width: 14px;
	  height: 14px;
	}
	
	.avatarImageTiny {
	  width: 10px;
	  height: 10px;
	} 

	.awardImgXXL{
		height:70px;
	}
	  
	.awardImgXL{
		height:52px;
	}
	  
	.awardImgLarge{
		height:36px;
	}
	  
	.awardImgMedium{
		height:24px;
	}
	  
	.awardImgSmall{
		height:18px;
	}
 
   
	h1{
		font-size: 24px !important;
		line-height: 24px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h2{
		font-size: 18px !important;
		line-height: 18px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h3{
		font-size: 14px !important;
		line-height: 14px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h4{
		font-size: 10px !important;
		line-height: 10px !important;
		font-weight: 500 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	
	h5{
		font-size: 8px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	h6{
		font-size: 6px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}   

	.dashboardTitle {
		font-size:0.9rem;
		font-weight: 600;
	}
	
	.dashboardCard {
		height:0;
		visibility:hidden;
	}

	.dashboardScoreRectangle {
		height: 20px;
		width: 28px;
		font-size:0.6rem !important;
	}

	.dashboardScoreCircle {
		font-size: 14px;
		font-weight: 700;
		border: 2px solid var(--grey-blue);
		height: 28px;
		width:28px;
	}
}

@media only screen and (max-width: 500px){
	.headerLink, .headerLinkIcon, .btn-community {
		display:none;
	}
	.headerMenuCompressedContainer{
		display:block;
	}
	.headerChildRightInnerWrap{
		min-width: 0;
		/* background-color: white; */
		box-shadow:none;
	} 

	
	.conectivityRingsInfoRowLeftInner{
		display:none;
	}
	conectivityRingBackgroundWrapper {
		/* height: 144px !important;
		width: 120px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:57px !important; */
	}        

	.ccRing {
		width: 86px !important;
		height: 86px !important;
	}
	
	.ccRingBackgroundRing {
		width: 86px !important;
		height: 86px !important;
	}
	 
	.ccRingForegroundRing {
		width: 86px !important;
		height: 86px !important;
	}  
 

	.TextStyle1 {
		font-size: 0.45rem !important;
		font-weight: 400 !important;
		line-height: 0.48rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.5rem !important;
		font-weight: 400 !important;
		line-height: 0.55rem !important;
	  }
	
	.TextStyle3, .avatarInitials {
		font-size: 0.5rem !important;
		line-height: 0.6rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.6rem !important;
		line-height: 0.65rem !important;
	  }
	  .TextStyle4_3 {
		font-size: 0.7rem !important;
		line-height: 0.75rem !important;
	}

	.TextStyle5 {
		font-size: 0.75rem !important;
		line-height: 0.8rem !important;
	  }
	
	.TextStyle6 {
		font-size: 0.82rem !important;
		line-height: 0.88rem !important;
	  } 
	  .TextStyle6A {
		font-size: 1.0rem !important;
		line-height: 1.1rem !important;
		}     
	.TextStyle7 {
		font-size: 1.4rem !important;
		line-height: 1.5rem !important;
	}
 
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.5rem;
	}   
	.ccImgXXXXXXXL {
		height:156px !important;    
	}
	.ccImgXXXXXXL{
		height:96px !important;    
	}
   .ccImgXXXXXL{
	  height:72px !important; 
   }       
	.ccImgXXXXL{
	  height:44px !important;    
	}
	
	.ccImgXXXL{
	  height:30px !important;    
	}
	
	
	.ccImgXXL{
	  height:16px !important;    
	}
	
	.ccImgXL{
	  height:13px !important;    
	}
	
	
	.ccImgLarge{
	  height:11px !important;    
	}
	
	.ccImgMedium{
	  height:9px !important;    
	}
	
	.ccImgSmall{
	  height:5px !important;    
	} 
 
   .ccImgXXXXLSquare{
	  height:44px !important;    
	  width:44px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:30px   !important;  
	  width: 30px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:16px !important;     
	  width:16px !important;     
	}
	
	.ccImgXLSquare{
	  height:13px !important;     
	  width:13px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:11px !important;     
	  width:11px !important;     
	}
	
	.ccImgMediumSquare{
	  height:9px !important;     
	  width:9px !important;     
	}
	
	.ccImgSmallSquare{
	  height:5px !important;     
	  width:5px !important;     
	}    
	.avatarImageXLarge {
		width: 32px;
		height: 32px;
	}
	
	.avatarImageLarge {
		width: 28px;
		height: 28px;
	}
	
	.avatarImageMediumLG {
	  width: 22px;
	  height: 22px;
	}
	
	
	.avatarImageMedium {
	  width: 18px;
	  height: 18px;
	}
	
	.avatarImageSmall {
	  width: 14px;
	  height: 14px;
	}
	
	.avatarImageTiny {
	  width: 10px;
	  height: 10px;
	} 

	h1{
		font-size: 24px !important;
		line-height: 24px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h2{
		font-size: 18px !important;
		line-height: 18px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h3{
		font-size: 14px !important;
		line-height: 14px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h4{
		font-size: 10px !important;
		line-height: 10px !important;
		font-weight: 500 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	
	h5{
		font-size: 8px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	h6{
		font-size: 6px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}   


}

@media only screen and (max-width: 450px){
	.headerLink, .headerLinkIcon, .btn-community {
		display:none;
	}
	.headerMenuCompressedContainer{
		display:block;
	}
	.headerChildRightInnerWrap{
		min-width: 0;
		/* background-color: white; */
		box-shadow:none;
	} 

	
	.conectivityRingsInfoRowLeftInner{
		display:none;
	}
	conectivityRingBackgroundWrapper {
		/* height: 144px !important;
		width: 120px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:57px !important; */
	}        

	.ccRing {
		width: 86px !important;
		height: 86px !important;
	}
	
	.ccRingBackgroundRing {
		width: 86px !important;
		height: 86px !important;
	}
	 
	.ccRingForegroundRing {
		width: 86px !important;
		height: 86px !important;
	}  
 

	.TextStyle1 {
		font-size: 0.45rem !important;
		font-weight: 400 !important;
		line-height: 0.48rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.5rem !important;
		font-weight: 400 !important;
		line-height: 0.55rem !important;
	  }
	
	.TextStyle3, .avatarInitials {
		font-size: 0.5rem !important;
		line-height: 0.6rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.6rem !important;
		line-height: 0.65rem !important;
	  }
	  .TextStyle4_3 {
		font-size: 0.7rem !important;
		line-height: 0.75rem !important;
	}

	.TextStyle5 {
		font-size: 0.75rem !important;
		line-height: 0.8rem !important;
	  }
	
	.TextStyle6 {
		font-size: 0.82rem !important;
		line-height: 0.88rem !important;
	  } 
	  .TextStyle6A {
		font-size: 1.0rem !important;
		line-height: 1.1rem !important;
		}     
	.TextStyle7 {
		font-size: 1.4rem !important;
		line-height: 1.5rem !important;
	}
 
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.5rem;
	}   
	.ccImgXXXXXXXL {
		height:156px !important;    
	}
	.ccImgXXXXXXL{
		height:96px !important;    
	}
   .ccImgXXXXXL{
	  height:72px !important; 
   }       
	.ccImgXXXXL{
	  height:44px !important;    
	}
	
	.ccImgXXXL{
	  height:30px !important;    
	}
	
	
	.ccImgXXL{
	  height:16px !important;    
	}
	
	.ccImgXL{
	  height:13px !important;    
	}
	
	
	.ccImgLarge{
	  height:11px !important;    
	}
	
	.ccImgMedium{
	  height:9px !important;    
	}
	
	.ccImgSmall{
	  height:5px !important;    
	} 
 
   .ccImgXXXXLSquare{
	  height:44px !important;    
	  width:44px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:30px   !important;  
	  width: 30px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:16px !important;     
	  width:16px !important;     
	}
	
	.ccImgXLSquare{
	  height:13px !important;     
	  width:13px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:11px !important;     
	  width:11px !important;     
	}
	
	.ccImgMediumSquare{
	  height:9px !important;     
	  width:9px !important;     
	}
	
	.ccImgSmallSquare{
	  height:5px !important;     
	  width:5px !important;     
	}    
	.avatarImageXLarge {
		width: 32px;
		height: 32px;
	}
	
	.avatarImageLarge {
		width: 28px;
		height: 28px;
	}
	
	.avatarImageMediumLG {
	  width: 22px;
	  height: 22px;
	}
	
	
	.avatarImageMedium {
	  width: 18px;
	  height: 18px;
	}
	
	.avatarImageSmall {
	  width: 14px;
	  height: 14px;
	}
	
	.avatarImageTiny {
	  width: 10px;
	  height: 10px;
	} 

   
	h1{
		font-size: 24px !important;
		line-height: 24px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h2{
		font-size: 18px !important;
		line-height: 18px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h3{
		font-size: 14px !important;
		line-height: 14px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h4{
		font-size: 10px !important;
		line-height: 10px !important;
		font-weight: 500 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	
	h5{
		font-size: 8px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	h6{
		font-size: 6px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}   
 
}

@media only screen and (max-width: 390px){
	.headerLink, .headerLinkIcon, .btn-community {
		display:none;
	}
	.headerMenuCompressedContainer{
		display:block;
	}
	.headerChildRightInnerWrap{
		min-width: 0;
		/* background-color: white; */
		box-shadow:none;
	} 

	
	.conectivityRingsInfoRowLeftInner{
		display:none;
	}

	.conectivityRingBackgroundWrapper {
		/* height: 144px !important;
		width: 120px !important; */
	}
	.conectivityRingsInfoRow {
		/* height:57px !important; */
	}        

	.ccRing {
		width: 86px !important;
		height: 86px !important;
	}
	
	.ccRingBackgroundRing {
		width: 86px !important;
		height: 86px !important;
	}
	 
	.ccRingForegroundRing {
		width: 86px !important;
		height: 86px !important;
	}  
 

	.TextStyle1 {
		font-size: 0.45rem !important;
		font-weight: 400 !important;
		line-height: 0.48rem !important;
	  }
	
	.TextStyle2 {
		font-size: 0.5rem !important;
		font-weight: 400 !important;
		line-height: 0.55rem !important;
	  }
	
	.TextStyle3, .avatarInitials {
		font-size: 0.5rem !important;
		line-height: 0.6rem !important;
	}
	
	.TextStyle4 {
		font-size: 0.6rem !important;
		line-height: 0.65rem !important;
	  }
	  .TextStyle4_3 {
		font-size: 0.7rem !important;
		line-height: 0.75rem !important;
	}

	.TextStyle5 {
		font-size: 0.75rem !important;
		line-height: 0.8rem !important;
	  }
	
	.TextStyle6 {
		font-size: 0.82rem !important;
		line-height: 0.88rem !important;
	  } 
	  .TextStyle6A {
		font-size: 1.0rem !important;
		line-height: 1.1rem !important;
		}     
	.TextStyle7 {
		font-size: 1.4rem !important;
		line-height: 1.5rem !important;
	}
 
	.buttonStyle1, .buttonStyle2, .buttonStyle3, .buttonStyle4 {
		border-radius: 0.3vw;
		font-weight: 400;
		font-size: 0.5rem;
	}   
	.ccImgXXXXXXXL {
		height:156px !important;    
	}
	.ccImgXXXXXXL{
		height:96px !important;    
	}
   .ccImgXXXXXL{
	  height:72px !important; 
   }       
	.ccImgXXXXL{
	  height:44px !important;    
	}
	
	.ccImgXXXL{
	  height:30px !important;    
	}
	
	
	.ccImgXXL{
	  height:16px !important;    
	}
	
	.ccImgXL{
	  height:13px !important;    
	}
	
	
	.ccImgLarge{
	  height:11px !important;    
	}
	
	.ccImgMedium{
	  height:9px !important;    
	}
	
	.ccImgSmall{
	  height:5px !important;    
	} 
 
   .ccImgXXXXLSquare{
	  height:44px !important;    
	  width:44px !important;    
	}
	
	.ccImgXXXLSquare{
	  height:30px   !important;  
	  width: 30px !important;    
	}
	
	
	.ccImgXXLSquare{
	  height:16px !important;     
	  width:16px !important;     
	}
	
	.ccImgXLSquare{
	  height:13px !important;     
	  width:13px !important;     
	}
	
	
	.ccImgLargeSquare{
	  height:11px !important;     
	  width:11px !important;     
	}
	
	.ccImgMediumSquare{
	  height:9px !important;     
	  width:9px !important;     
	}
	
	.ccImgSmallSquare{
	  height:5px !important;     
	  width:5px !important;     
	}    
	.avatarImageXLarge {
		width: 32px;
		height: 32px;
	}
	
	.avatarImageLarge {
		width: 28px;
		height: 28px;
	}
	
	.avatarImageMediumLG {
	  width: 22px;
	  height: 22px;
	}
	
	
	.avatarImageMedium {
	  width: 18px;
	  height: 18px;
	}
	
	.avatarImageSmall {
	  width: 14px;
	  height: 14px;
	}
	
	.avatarImageTiny {
	  width: 10px;
	  height: 10px;
	} 
   
	h1{
		font-size: 24px !important;
		line-height: 24px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h2{
		font-size: 18px !important;
		line-height: 18px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h3{
		font-size: 14px !important;
		line-height: 14px !important;
		font-weight: 600 !important;
		margin-top: 0.5rem !important;
		margin-bottom: 0.5rem !important;
	}
	
	h4{
		font-size: 10px !important;
		line-height: 10px !important;
		font-weight: 500 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	
	h5{
		font-size: 8px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}
	
	h6{
		font-size: 6px !important;
		font-weight: 400 !important;
		margin-top: 0.4rem !important;
		margin-bottom: 0.4rem !important;
	}   

}





