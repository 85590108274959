/* Modal that scrolls with the screen */

.ModalNoBackgroundContainer {
	position: absolute;
	top:0px;
	left:0px;
	height:100%;
	width:100vw;
	min-height:100vh;
	display: flex;
	justify-content: center;
	align-items: flex-start;
	padding-top: 200px;
	z-index: 9999;
}

.ModalScroll {
	overflow-y: auto;
	background-color: rgba(0, 0, 0, 0.6);
}

.ModalNoBackgroundCard {
	z-index: 100;
	position:absolute;
	top:0;
	width:auto;
	height:auto;
	max-width:clamp(600px,95vw, 1400px);
	/* min-width: clamp(100px,60vw,300px); */
	background-color: var(--TEAM-purple);
	color:var(--HEADER-blue);   /*Necessary to see text in dark mode since modals are fixed white background */
	opacity: 1;
	display: flex;
	flex-direction: column;
	align-items:center;
	justify-content:center;
	margin: clamp(4px,0.5vw,10px);
	/*padding: 10px; */
	border-radius: clamp(4px,0.4vw,10px);
}

.ModalNoBackgroundCloseButton {
	position:absolute;
	top:0;
	right:0;
	/* transform: translate(-100%,-150%); */
	transform: translate(0%,-150%);
	z-index:9999;
	cursor: pointer;
	/* height:18px;
	width:18px; */
	display:flex;
	align-items:center;
	justify-content:center;
}

.modalInnerCloseButton {
    transform: translate(-75%, 75%) !important;
}

/* Classes to use in the parent component that makes use of this modal */




.modalNoBkgInnerCard {
	background-color: var(--card-background);
	position:relative;
	display:flex;
	flex-direction:column;
	align-items:center;
	justify-content:center;
	opacity: 1;
	/*padding-top: 100px;*/
	width: 100%;
	min-height: 100px;
	/*boxShadow: '0 8px 16px 0 #BDC9D7',*/
	border-radius: clamp(4px,0.4vw,10px);
	/*margin: 10px;  */
	/*padding: 10px;*/
	/*color: black;*/
	/*cursor:pointer;*/

	/*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
	/*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

.modalNoBkgCenterColumn {
	display:flex;
	flex-direction:column;
	justify-content:center;
	align-items: center;
	/*width:300px;*/
	border-radius: clamp(4px,0.4vw,10px);
	background-color: inherit;
	color:var(--HEADER-blue);   /*Necessary to see text in dark mode since modals are fixed white background */
	width:100%;
	flex-grow:1;    /*Let this column expand to fill the card */
}

.modalNoBkgFooter {
	display:flex;
	flex-direction:row;
	align-items:center;
	justify-content:space-around;
	width:100%;
	padding:10px;
	background-color: var(--light-mode-card-background);
	flex-grow:0;  /*Will not expand; Push to bottom */
	border-radius: clamp(4px,0.4vw,10px);
}

.darkMode .modalNoBkgFooter{
	background-color: var(--dark-mode-card-background) !important;
}
.ModalNoBackgroundCard .flexEnd {
	justify-content:flex-end;
}
.modalNoBkgText1 {
	color:#409cf7;
	font-size:48px; 
	font-weight:700; 
	padding-right:3px;
}

.modalNoBkgText2 {
	color:#409cf7;
	font-size:22px; 
	font-weight:500; 
	padding-top:10px;
}

.modalNoBkgText3 {
	color:#409cf7;
	font-size:1.2 rem; 
	font-weight:500; 
}

.modalNoBkgTextRow {
	display:flex;
	flex-direction:row;
	justify-content:center;
	align-items: center;

}

.modalNoBkgImage {
	z-index: 1;
	/* width: 100px;
	height: 100px; */
	border-radius: 50%;
	border-color:white;
	border: 3px solid white;
	overflow: hidden;
	background-color: var(--card-background);
	position: absolute;
	top:0;
	left:0;
	transform:translate(25%,-50%);
}


@media only screen and (max-width: 1520px){  
	.ModalNoBackgroundCard{
		max-width:95vw;
	}
}

@media only screen and (max-width: 1440px){  

}

@media only screen and (max-width: 1325px){  

}

@media only screen and (max-width: 1200px){  

}

@media only screen and (max-width: 1024px){  

}

@media only screen and (max-width: 993px){  

}

@media only screen and (max-width: 768px){  

}


/* PHONES */

@media  only screen and (max-width: 650px) {
}


@media only screen and (max-width: 500px){  

}

@media only screen and (max-width: 390px){  

}

