
.SpotlightModalCard {
  min-width: clamp(450px, 50vw, 650px);
  max-width: clamp(650px, 60vw, 1000px);
  border-radius: clamp(12px, 5vw, 20px);
  /* padding: clamp(15px, 5vw, 30px); */
  background-color: var(--white);
  /* border: clamp(4px, 1vw, 6px) solid var(--WARNING-yellow); */
  border: 5px solid var(--GROWTH-yellow);
  box-shadow: 0px 1px 25px -9px #4D106326;
  overflow-y: auto;
  min-height: clamp(450px, 25vh, 600px);
  scrollbar-width: thin;
}

.spotlightInfoContainer{
width: calc(100% - 15px);
}

.userAvatar {
  width: 44px;
  height: 44px;
  background-color: var(--WARNING-yellow);
  border-radius: 50%;
  border: clamp(1px, 0.2vw, 2px) solid #fff;
  background-size: cover;
}

.spotlight-like-btn {
  background-color: var(--BALANCE-blue);
  color: #fff;
  border: none;
  border-radius: 8px;
  padding: clamp(9px, 1vw, 10px) clamp(9px, 1vw, 10px);
  cursor: pointer;
  margin-left: clamp(5px, 1vw, 10px);
  display: flex;
  align-items: center;
  gap: 8px;
  text-align: center;
}

.gray-blue {
  color: var(--grey-blue);
}

.header-blue2 {
  color: var(--HEADER-blue2);
}

.rotate {
  rotate: 130deg;
}

.rotateOpposite {
  rotate: -130deg;
}

.coinCountSpan {
  border-radius: 6px;
  border: 1px solid var(--light-grey);
  gap: clamp(3px, 0.5vw, 5px);
  padding: 8px;
}


.gap10 {
  gap: clamp(8px, 1vw, 10px);
}

.shareCard {
  gap: clamp(3px, 1vw, 5px);
  font-size: clamp(12px, 2vw, 15px);
}

.spotlightCommongap{
  gap: clamp(15px, 5vh, 25px);
}

.spotlightContentContainer{
  padding: 15px;
}

.paddingTop{
padding-top: 15px;
}

.spotlightInnerContainer{
border-top: 1px solid var(--light-grey);
}

.margin-none {
  margin: 0;
}

.coin-border{
  border-radius: 6px;
  border: 1px solid var(--dust-grey);
}

@media only screen and (max-width: 993px) {
  .SpotlightModalCard {
    min-width: 100% !important;
  }
}

.lightIcon{
  height: 24px;
  width: 24px;
}

.spotlight-light-effect {
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
  background: linear-gradient(
    to bottom,
    var(--white) 0%,
    rgba(255, 222, 52, 0.2) 15%,
    rgba(255, 222, 52, 0.3) 50%,
    var(--white) 100%
  );
  clip-path: polygon(45% 0%, 65% 0%, 90% 95%, 20% 95%);
  pointer-events: none;
  z-index: 1;
  /* animation: spotlight-animation 3.5s infinite; */
  opacity: 1;
  animation: flash 0s ease-in-out infinite;
}

@keyframes flash {
  0%, 100% {
    opacity: 0; /* Start and end invisible */
  }
  10%, 90% {
    opacity: 1; /* Full visibility during the flash */
  }
}