/* SPOTLIGHTS */

.spotlight-button-section .buttonStyle1:disabled {
   border: 2px solid var(--TEAM-purple) !important;
   background-color: var(--TEAM-purple) !important;
   cursor:default;
   pointer-events: none;
}

.spotlightAddButton {
   padding-right:clamp(4px,0.5vw,20px);
   text-align:center;
   text-wrap:wrap;
   word-break: none;
   /* max-width: clamp(50px,10vw,100px); */
}

.spotlightAddButtonIconWrapper {
   transform: translate(0px,-10%) rotate(-145deg);
}

.spotlightOuterProgressBar {
   position:relative;
   width:100%;
   height:clamp(10px,2vh,20px);
   background-color: rgb(255,255,255,0.4);
   /* border-radius:clamp(4px,0.4vw,8px); */
   text-align: center;
}
.spotlightInnerProgressBar {
   width:0%;
   height:clamp(10px,2vh,20px);
   background-color: rgb(255,255,255,1);
   transition:width 1s ease-in;
}

.spotlightInnerProgressBar:hover {
   background-color: var(--TEAM-purple);
}

.spotlightRow{
   display: flex;
   flex-wrap: nowrap;
   align-items: center;
   justify-content:flex-start;
   /*background-color: black;*/
   /*background-color: yellow;*/
   /*min-width: 100vw;*/
   /*overflow-y: hidden;*/
   /*overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
   overflow-x: visible;             /* Adds a scrollbar if the cards overflow in the X direction */
   overflow-y:visible;
   padding: clamp(4px,2vh,20px) 0px;                 /* Put some padding between rows so that a card can scale without shifting other rows around */
   /*min-height: 350px;              /* Fix the height so the row height won't change and shift the other rows */
   transition-timing-function: linear;
   
}


.spotlightRow::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
   display:none;
}

.spotlightRowIsSmall {
   /* min-height:150px; */
   padding: clamp(2px,0.3vh,4px) 0px;
   /*background-color: yellow;*/
}


.spotlightRowNavigator {
   position: absolute;
   height: 100%;
   width: 75px;
   /*background-color: rgba(1,1,1,0.0);*/
   background-color: rgba(187,143,206,0.0);
   z-index: 99;
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
   /*opacity: 0;*/
   color:white;
   font-size:64px;
   /*font-weight: 600;*/
   transition: all 0.4s;
   opacity: 0;    /*Hide text, i.e., arrow */
}

.spotlightRowNavigatorLeft {
   left: 0vw;
   top: 0vw;
}

.spotlightRowNavigatorRight {
   right: 0vw;
   top: 0vw;
}

.spotlightRowNavigatorActive{
   /*opacity: 0.5;*/
   background-color: rgba(187,143,206,0.5);
   /*background-color: rgba(1,1,1,0.5);*/
   opacity: 1; /*Show text with full opacity*/
}

.spotlightRecognizedUsersAvatarsWrapper {
   display:flex;
   width: 100%;
   align-items: center;
   justify-content: center;
   /*opacity: 0.5;*/
   /*background-color: yellow;*/
   border-radius: clamp(4px,0.4vw,10px);
   padding-left:(4px,1vw,10px);
   padding-right:(4px,1vw,10px);
   margin-bottom: clamp(4px,0.5vw,6px);
   margin-top: clamp(4px,1vw,12px);
}

.spotlightCurvedTextWrapper {
     font-family: 'Luckiest Guy', cursive;
     font-size: 35px;   
     color:gold;    
}

.spotlightCurvedTextWrapper svg {
   height:55px;
   width:220px
}

.spotlightCurvedTextWrapperIsSmall {
   font-family: 'Luckiest Guy', cursive;
   font-size: 18px;   
   color:gold;    
   z-index: 9999;
}

.spotlightCurvedText {
 /*background-color: #333;*/
 font-family: 'Luckiest Guy', cursive;
 font-size: 35px;   
 color:gold;
}

/* Avatar text */
.avatarNameText {
 visibility: visible;
 /*width: 120px;*/
 color: #fff;
 text-align: center;
 padding: 5px 5px;
 border-radius: 6px;
 font-size: 0.6rem;

 /* Position the hover text*/
 position: absolute;
 top:105%;
 left:0px;
 
 z-index: 1;
}


.modalSendSpotlightImage {
   z-index: 1;
   width: 100px;
   height: 100px;
   border-radius: 50%;
   border-color:white;
   border: 3px solid white;
   overflow: hidden;
   background-color: white;
   position: absolute;
   top:-50px;
   left:20px;
}


.modalSendSpotlightCenterColumn {
   display:flex;
   flex-direction:column;
   justify-content:center;
   align-items: center;
   /*width:300px;*/
   border-radius: clamp(4px,0.4vw,10px);
   background-color:white;
   width:100%;
   padding-top: 10px;
   padding-bottom: 10px;
}

.modalSendSpotlightInnerCard {
   position:relative;
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
   background-color: white;
   opacity: 1;
   /*padding-top: 100px;*/
   width: 800px;
   height: 600px;
   /*boxShadow: '0 8px 16px 0 #BDC9D7',*/
   border-radius: 8px;
   margin: 10px;  
   /*padding: 5px;*/
   color: black;
   /*cursor:pointer;*/

   /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
   /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

.spotlightBannerHeader {
   margin-top: clamp(4px,1vh,10px);
   color:#BB8FCE;
   /* font-size: 2.0rem;
   font-weight: 500; */
   height:100px;
   width:60vw;
   max-width: 1400px;
   display:flex;
   flex-direction:column;
   align-items: center;
   justify-content: center;
   /*background-color: #111;*/
   /*background-color: yellow;*/

   /*border-bottom: 0.2vw solid #BB8FCE;*/
   /*border-radius: 20px;*/
   
   background: linear-gradient(to right, #BB8FCE 50%, #FFFFFF 100%);
   background-position: 0 100%;
   background-size: 100% 6px;
   background-repeat: repeat-x;   
}

.spotlightBannerInnerContainer {
   /*background-color:yellow;*/
   display:flex;
   flex-direction:row;
   align-items:flex-start;
   justify-content:center;
   width:100%;
   height: 100%;
}


.spotlightBannerFootnote {
   padding-bottom: clamp(2px,0.5vh,8px);
   color:#BB8FCE;
   transform:translate(0,-10%);
   /*background-color: yellow;*/
}


.sendSpotlightCenterColumn {
   display:flex;
   flex-direction:column;
   justify-content:flex-start;
   align-items: center;
   /*width:300px;*/
   /*background-color: pink;*/
}

.spotlightLightRing {
   position:absolute;
   background-color: #fffffa;
   border-radius: 50%;
   height: 6px;
   width: 20px;
   
}
   
.spotlightLightRingRight {
   bottom:0px;
   right: -13px;
   
   /*-webkit-box-shadow: 10px 10px 30px 6px rgba(255,255,250,0.69); */
   /*box-shadow: 10px 10px 30px 6px rgba(255,255,250,0.69);   */
}

.spotlightLightRingLeft {
   bottom:10px;
   left: -13px;
   
   /*-webkit-box-shadow: -10px 10px 30px 6px rgba(255,255,250,0.69); */
   /*box-shadow: -10px 10px 30px 6px rgba(255,255,250,0.69);   */
}


.editButton{
   color: #5DADE2;
   cursor:pointer;
}


.deleteButton{
   color: red;
   cursor: pointer;
}


.spotlightCommentCountContainer {
   position:relative;    
   flex:1;
   display:flex;
   align-items:center;
   justify-content:center;
   /*background-color: yellow;*/
   font-size:1.0rem;
}   

.spotlightsLikesList {
   display:flex;
   align-items:center;
   justify-content:center;
   visibility: visible;
   min-width: 120px;
   height: 0;
   color: #fff;
   font-size:0.8rem;
   border-radius: 6px;
   padding: 0px 5px;
   
   /* Position the hover text*/
   position: absolute;
   top:-5px;
   right:50%;
   overflow: hidden;
   background-color: #5DADE2;

   transition-timing-function: ease-in;
   transition: all 0.4s;
   transform: translate(50%,0);    

   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   
}

.spotlightsLikesListHover  {
   visibility: visible;
   height: 24px;
   /*padding: 5px;*/
}

.spotlightLikesContainer {
   position:relative;    
   flex:1;
   display:flex;
   align-items:center;
   justify-content:center;
   /*background-color: red;*/
}




/*
   SPOTLIGHT CARD
*/

.spotlightInnerMenuIconContainer {
   display:flex;
   align-items:center;
   justify-content:center;
   /*background-color: red;*/
   position:absolute;
   top:0px;
   right:0px;
}

.spotlightInnerMenuContainer {
   display:flex;
   align-items:center;
   justify-content:flex-start;
   background-color: white;
   position:absolute;
   top:40px;
   right:5px;
   z-index: 1;
   overflow:hidden; /*This will hide the elements that overflow when the height is ZERO */
   transition: all 0.3s;
   /*width:280px;*/
   transition-timing-function: ease-in;
   border: 1px solid #36454f;
   border-radius: clamp(4px,0.4vw,10px);
   color: #36454f;
}


.spotlightCommentRow {
   display: flex;
   flex-wrap: nowrap;
   align-items: flex-start;          /*Set items at top vertically in the message row */
   justify-content: flex-start;     /* Align comments to the LEFT */
   /*padding:10px 0px 0px 0px;*/
   
   /*margin-bottom: 10px;*/
   /*margin-right: 10px;*/
   /*margin-left: 10px;*/
   
   position:relative;
   width:100%;
   /*background-color: yellow;*/
}


.spotlightCommentRowText {
   font-size: 1.1rem;
   font-weight: 400;
   color: #409cf7;
   background-color: #f7f7f7;
   padding: 0px 20px;
   margin: 0px 5px;
   border-radius: 20px;
   text-align: left;
   max-width: 70%;

}

.spotlightCommentReplyButton{
   margin:0px 5px;
}


.spotlightCommentReplyButtonText{
   font-size: 0.7rem;
   font-weight: 600;
   color: #409cf7;
}


.spotlightsCommentReplyContainer {
   width:100%;
   /*max-width: 450px;*/
   height: 0;
   border-radius: 20px;
   position:relative;

   overflow: hidden;

   transition-timing-function: ease-in;
   transition: all 0.4s;

   /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
   
}

.spotlightsCommentReplyContainerVisible  {
   visibility: visible;
   height: 5.0rem;
   overflow: visible;        /*Need to see Picker and GIF Experience */

   /*padding: 5px;*/
}

.spotlightReplyIndent {
   width:72px;
   height:100%;
   /*background-color:pink;*/
   transform:translate(5px,0);
}

.spotlightReplyInputIndent {
   width:23px;
   height:100%;
   /*background-color:pink;*/
   /*transform:translate(10%,0);*/
}

/*                            */
/*     MEDIA ADJUSTMENTS      */
/*                            */

@media only screen and (max-width: 1325px){


}

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 1024px){
 
}


/* PHONES */

@media only screen and (max-width: 650px){

   .spotlightAddButtonIcon {
       display:none;
   }
}

@media only screen and (max-width: 500px){


}

@media only screen and (max-width: 450px){

}

@media only screen and (max-width: 390px){

}