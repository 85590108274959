.menuHeaderCompressedButtonContainer {
    z-index: 500;
    /* color:white; */
    border-radius: 0.2vw;
    background-color: var(--light-mode-card-background);
    overflow: hidden;
    margin: clamp(3px, 0.3vw, 6px);
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}

.darkMode .menuHeaderCompressedButtonContainer {
    background-color: var(--dark-mode-card-background) !important;
}

.menuHeaderCompressedButton {
    cursor: pointer;
    visibility: hidden;
}
.compressedMenuIcon {
    width:clamp(40px, 6vw, 60px);
    text-align: center;
}

.menuHeaderCompressedWrapper {
    position:absolute;
    top:0;
    left:0;
    background-color: rgb(0,0,0,0.4);
    height:100vh;
    width:100vw;
}

.menuHeaderCompressed {
    position:absolute;
    top:clamp(30px,4vh,60px);
    right:clamp(20px,4vh,40px);
    background-color: white;
    padding: clamp(4px,1vw,10px);
}

/* PHONES */

@media  only screen and (max-width: 650px) {
    .menuHeaderCompressedButton {
        visibility: visible;
    }
}