.commentBoxContainer {
  padding: clamp(10px, 2vw, 16px);
  border-radius: clamp(6px, 1vw, 8px);
  box-shadow: 0px 1px 25px -9px #4D106326;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  /* min-height: clamp(100px, 15vh, 150px); */
}

.userImg {
  border-radius: 50%;
  width: clamp(30px, 4vw, 40px);
  height: clamp(30px, 4vw, 40px);
  margin-right: clamp(5px, 2vw, 10px);
}

.commentTextarea {
  flex: 1;
  outline: none;
  padding: clamp(5px, 1.5vw, 7px);
  border: none;
  height: 100%;
  /* min-height: clamp(80px, 15vh, 100px); */
  position: relative;
  z-index: 1;
  resize: none;
  overflow: auto;
  font-size: clamp(12px, 1.1vw, 14px);
}

.darkMode .commentTextarea{
  background-color: transparent;
  color: var(--white);
}

.messageBtn {
  color: var(--white);
  border: none;
  border-radius: 8px;
  padding: clamp(5px, 2vw, 10px) clamp(8px, 3vw, 15px);
  margin-left: clamp(5px, 2vw, 10px);
  cursor: pointer;
  /* background: linear-gradient(90deg, #a926df 0%, #b754e3 100%); */
  background: var(--TEAM-purple);
  height: clamp(40px, 6vw, 45px);
  font-size: clamp(12px, 1.1vw, 14px);
}

.messageBtnAi {
border-radius: 50%;
height: 35px;
width: 35px;
}

.paddingInline {
  padding: 10px 0px;
}

.textNowrap {
  text-wrap: nowrap;
}

.commentInputAreaButtons {
  margin-left: clamp(5px, 2vw, 10px);
  gap: clamp(5px, 2vw, 10px);
}

.inputContainer{
  border: 1px solid var(--light-grey);
  border-radius: 6px;
  height: 45px;
  padding: 10px;
}