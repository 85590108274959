
.displayCard {
    /*flex: 1;*/
    display: flex;
    flex-direction: column;
    background-color:white;
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    cursor:pointer;
    width: 400px;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/

}

.displayCardTitle {
    font-size:xx-large;
    /*margin-bottom: 0px;*/
}

.displayCardTextContainer {
    margin-bottom: 10px;
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.displayCardText1 {
    font-size: x-large;
}

.displayCardText2 {
    font-size: x-large;
}
.displayCardText3 {
    font-size: x-large;
}

.displayCardText4 {
    font-size: x-large;
}
.displayCardText5 {
    font-size: x-large;
}


.displayCardTextFooter {
    padding: 10px 0px 10px 0px; 
    font-size: small;
}