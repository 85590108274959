
/*

  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  copyright - CONECTERE.

*/


/* MATERIAL UI-CLASS OVERRIDES */

/***
	MUI ICON BUTTON USED THROUGHOUT SITE
***/
.MuiIconButton-root:hover {
	background-color: rgba(0,0,0,0.1) !important;       /* Hover effect in light mode */
}
.darkMode {
	.MuiIconButton-root:hover {
		background-color: rgba(255,255,255,0.3) !important;       /* Hover effect in dark mode */
	}
}

.MuiPickersPopper-root {
	z-index: 999999 !important;    /* Top layer so visible above all modals */
}
/* MUI - OVERLOAD THE DEFAULT TEXT SETTINGS OF MATERIAL UI RADIO BUTTONS AND CHECKBOXES */
	
.MuiTypography-root, .MuiInputBase-input {
   font-family: 'Sora', sans-serif !important;
   font-weight: 400 !important;
   font-size: 1.0rem;
   /* color:var(--HEADER-blue); */
}

.darkMode .accountHistory .MuiInputBase-input {
	color:var(--white) !important;
	background-color: var(--dark-mode-card-background) !important;
}

/* 
	Calendar input fields 
*/

.MuiFormControl-root {
	background-color: var(--light-mode-card-background);
	color:var(--black);
}

.darkMode {

	.MuiFormControl-root, .MuiInputBase-root-MuiOutlinedInput-root, .MuiInputBase-input, .MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled  {
		background-color: var(--dark-mode-card-background) !important;
		color:var(--white) !important;
		-webkit-text-fill-color: rgba(256,256,256,.3) !important;
	}
	.MuiSvgIcon-root {
		color:var(--white);
	}
}



/* Date/Time Labels */
.darkMode .MuiFormLabel-root {
	color:var(--HEADER-blue);
}

/*CSS for the MUI Icons */
.MuiSvgIcon-root {
	font-size: 2.0rem;
}

.SideBarContainer .MuiSvgIcon-root {
	color: var(--white) !important;
}

/* 
	DATA GRID 
*/

.MuiTablePagination-toolbar .MuiInputBase-root {
	padding-right:clamp(8px,1vw,16px) !important;  /* Added this to give space for their dropdown svg */
}

/*CSS for the Data Grid Cells */
/*CSS to turn off border of cell selection*/
.MuiDataGrid-root  {
	color: var(--HEADER-blue) !important;
}

.MuiDataGrid-footerContainer p {
	margin: 0px !important; /*Overload the Paragraph TAG as Bootstrap was messing this up with margins by adding a bottom margin on the DataGrid*/
 }

.MuiDataGrid-root .MuiDataGrid-cell:focus-within {
	outline: none !important;
}

/* CSS for bottom toolbar - pages, etc. */
.MuiDataGrid-root .MuiButtonBase-root{
	
	/*font-family: 'Sora', sans-serif;*/
	/*white-space: normal !important;*/
	/*word-break: break-word !important;    */
	font-size: 1.0rem;
	font-weight: 400;
	overflow:visible !important;    /* Allowing for HOVER elements to appear outside of cell / row */
}

.MuiDataGrid-columnHeaderWrapper{
	background-color: var(--HEADER-blue);
}

.darkMode .MuiDataGrid-columnHeaderWrapper{
	background-color: var(--dark-mode-card-background);
}

/* Datagrid cells, side menu options */
.MuiDataGrid-cell {
	font-size: 1.0rem;
	font-weight: 400 ;
	overflow: visible !important;
 }

/* THIS OVERRIDES BACKGROUND PROP IN COLUMNS */
.MuiDataGrid-columnHeader {
   font-size: 1.0rem;
   font-weight: 500;
   background-color: var(--HEADER-blue);
  color:white;
}

.darkMode .MuiDataGrid-columnHeader{
	background-color: var(--dark-mode-card-background);
}

.MuiDataGrid-columnHeaderTitleContainer {
	white-space: normal !important;
	line-height:normal;
	text-align:center;
	word-break: break-word !important;
}

.darkMode .MuiDataGrid-columnHeaderTitleContainer{
	background-color: var(--dark-mode-card-background);
}

	/*  https://mui.com/material-ui/api/tab/ */ 

	/* MUI DASHBOARD TABS */
	.MuiTab-root {                               /* Will set the UNSELECTED TABS characteristics */
		color: var(--HEADER-blue) !important;
		background-color: var(--header-background) !important;
		border-top-right-radius:8px !important;
		border-top-left-radius:8px !important;
		margin-right:4px !important;
		margin-top:4px !important;
		font-size:0.9rem;
		font-weight:400;
		min-width:300px;
	}    


	.MuiTab-root.Mui-selected {                     /* Will override & set the SELECTED TAB characteristics */
		background-color: var(--HEADER-blue) !important;
		color: var(--soft-grey) !important;
		font-size:1.1rem;
		font-weight:500;
	}

	.MuiTabs-indicator {
		/* background-color: var(--HEADER-blue) !important;
		color: var(--white) !important;
		height: 4; */
		display:none !important;
	 }

	 .MuiTabs-flexContainer {
		flex-wrap:wrap;
	 }


	/* MUI DROP DOWN MENUS */
	.MuiMenuItem-root {
		/*color: var(--HEADER-blue) !important;*/
		padding:(2px,1vw,8px);
	}         

	.MuiButtonBase-root {
		/*color: var(--HEADER-blue) !important;*/
		padding:(2px,1vw,8px);
	}  


	.MuiBox-root {
		border:none;
	   background-color: var(--HEADER-blue) ;
	   border-color: var(--HEADER-blue) ;
	}


	.MuiTab-root .MuiBadge-badge {
	 background-color: yellow !important;
	 color:black !important;
	 right:-5px !important;
	 top:-2px !important;
	}

	/* DATE PICKER */
	.MuiInputBase-input {
		padding: clamp(2px,1vw,8px) !important;
	}

	/* Data Grid */
	.darkMode .MuiTablePagination-root {
		color:white;
	}

	.darkMode .MuiDataGrid-root {
		background-color: var(--dark-mode-card-background);
	}


/*                            */
/*     MEDIA ADJUSTMENTS      */
/*                            */

/* MED LAPTOP */
@media  only screen and (max-width: 1520px) {

 
}

/* SMALL LAPTOP */
@media  only screen and (max-width: 1440px) {

   .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root {
	   font-size: 0.9rem !important;
	   line-height: 1.1rem !important;
   }

   .MuiSvgIcon-root {
	 font-size: 1.5rem;
  }

   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   font-size:0.9rem;
	   font-weight:400;
	 }
 
	 .MuiTab-root.Mui-selected {
		 font-size:1.0rem;
		 font-weight:500;
	 }    
	 .MuiDataGrid-columnHeader{
		font-size: 0.9rem !important;
		font-weight: 500 !important;
	  }
	 
}

@media only screen and (max-width: 1325px){

   .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.9rem !important;
	   line-height: 1.1rem !important;
   }

   .MuiSvgIcon-root {
	 font-size: 1.5rem;
  }

   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   font-size:0.9rem;
	   font-weight:400;
	 }
 
	 .MuiTab-root.Mui-selected {
		 font-size:1.0rem;
		 font-weight:500;
	 }    
	 .MuiDataGrid-columnHeader{
		font-size: 0.9rem !important;
		font-weight: 500 !important;
	  }
	 
}


@media only screen and (max-width: 1200px){
 .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root {
	   font-size: 0.8rem !important;
	   line-height: 0.9rem !important;
   }
   
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	 font-size:0.8rem;
	 font-weight:400;
   }

   .MuiTab-root.Mui-selected {
	 color: var(--soft-grey) !important;
	 font-size:0.9rem;
	 font-weight:500;
   } 
   .MuiDataGrid-columnHeader{
	   font-size: 0.8rem !important;
	   font-weight: 500 !important;
   }  
   .MuiSvgIcon-root {
	 font-size: 1.3rem;
 }
}


@media  only screen and (max-width: 1024px) {
 .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.8rem !important;
	   line-height: 0.9rem !important;
   }

   .MuiSvgIcon-root {
	 font-size: 1.1rem;
  }

   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   font-size:0.8rem;
	   font-weight:400;
	 }
 
	 .MuiTab-root.Mui-selected {
		 color: var(--soft-grey) !important;
		 font-size:0.9rem;
		 font-weight:500;
	 } 
	 .MuiDataGrid-columnHeader{
		font-size: 0.8rem !important;
		font-weight: 500 !important;
	  }
	
}

@media only screen and (max-width: 993px){
 .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.6rem;
	   line-height: 0.7rem !important;
	 }
  
   .MuiSvgIcon-root {
	 font-size: 1.0rem;
  }    
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   color: var(--HEADER-blue) !important;
	   font-size:0.6rem;
	   font-weight:400;
	   margin-bottom:5px !important;
	 }
 
	 .MuiTab-root.Mui-selected {
		 background-color: var(--HEADER-blue) !important;
		 color: var(--soft-grey) !important;
		 font-size:0.7rem;
		 font-weight:500;
	 } 
	 .MuiDataGrid-columnHeader{
		font-size: 0.6rem !important;
		font-weight: 500 !important;
	  } 
}

/* TABLET */

@media  only screen and (max-width: 768px) {
 .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.6rem !important;
	   line-height: 0.62rem !important;
   }
   
   .MuiSvgIcon-root {
	 font-size: 0.9rem;
  }    
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   color: var(--HEADER-blue) !important;
	   font-size:0.6rem;
	   font-weight:400;
	   margin-bottom:5px !important;
	 }
 
	 .MuiTab-root.Mui-selected {
		 background-color: var(--HEADER-blue) !important;
		 color: var(--soft-grey) !important;
		 font-size:0.7rem;
		 font-weight:500;
	 }  
	 .MuiDataGrid-columnHeader{
		font-size: 0.6rem !important;
		font-weight: 500 !important;
	  }
	
}


/* PHONES */

@media  only screen and (max-width: 650px) {
  .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.6rem !important;
	   line-height: 0.62rem !important;
   }
 
   .MuiSvgIcon-root {
	 font-size: 0.8rem ; 
  }    
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   color: var(--HEADER-blue) !important;
	   font-size:0.6rem !important;
	   font-weight:400 !important;
	   margin-bottom:5px !important;
	 }
 
	 .MuiTab-root.Mui-selected {
		 background-color: var(--HEADER-blue) !important;
		 color: var(--soft-grey) !important;
		 font-size:0.6rem;
		 font-weight:500;
	 }  
	 .MuiDataGrid-columnHeader{
		font-size: 0.5rem !important;
		font-weight: 400 !important;
	  }

}

@media only screen and (max-width: 500px){
 .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.6rem !important;
	   line-height: 0.62rem !important;
   }

   .MuiSvgIcon-root {
	 font-size: 0.8rem;
  }    
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   color: var(--HEADER-blue) !important;
	   font-size:0.6rem !important;
	   font-weight:400 !important;
	   margin-bottom:5px !important;
	 }
 
	 .MuiTab-root.Mui-selected {
		 background-color: var(--HEADER-blue) !important;
		 color: var(--soft-grey) !important;
		 font-size:0.6rem;
		 font-weight:500;
	 }  
	 .MuiDataGrid-columnHeader{
		font-size: 0.5rem !important;
		font-weight: 400 !important;
	  }
 
}

@media only screen and (max-width: 450px){
  .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.6rem !important;
	   line-height: 0.62rem !important;
   }
 
   .MuiSvgIcon-root {
	 font-size: 0.8rem;
  }    
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   color: var(--HEADER-blue) !important;
	   font-size:0.6rem !important;
	   font-weight:400 !important;
	   margin-bottom:5px !important;
	 }
 
	 .MuiTab-root.Mui-selected {
		 background-color: var(--HEADER-blue) !important;
		 color: var(--soft-grey) !important;
		 font-size:0.6rem;
		 font-weight:500;
	 }  
	 .MuiDataGrid-columnHeader{
		font-size: 0.5rem !important;
		font-weight: 400 !important;
	  }


}

@media only screen and (max-width: 390px){
  .MuiDataGrid-cell, .MuiButtonBase-root, .MuiInputBase-input, .MuiFormLabel-root  {
	   font-size: 0.6rem !important;
	   line-height: 0.64rem !important;
   }
  
   .MuiSvgIcon-root {
	 font-size: 0.8rem; 
  }  
   
   .MuiTab-root {                 /* Will set the UNSELECTED characteristics */
	   color: var(--HEADER-blue) !important;
	   font-size:0.6rem !important;
	   font-weight:400 !important;
	   margin-bottom:5px !important;
	 }
 
	 .MuiTab-root.Mui-selected {
		 background-color: var(--HEADER-blue) !important;
		 color: var(--soft-grey) !important;
		 font-size:0.6rem;
		 font-weight:500;
	 }  
	 .MuiDataGrid-columnHeader{
		font-size: 0.5rem !important;
		font-weight: 400 !important;
	  } 

}






