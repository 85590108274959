/*
    SPOTLIGHT FEED
*/

.spotlightFeed{
    display: flex;
    flex-direction:column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    /*background-color: black;*/
    /*background-color: yellow;*/
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    /*overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    /*overflow-y:visible;*/
    /*padding: 30px 0px;                 /* Put some padding between rows so that a card can scale without shifting other rows around */
    /*min-height: 350px;              /* Fix the height so the row height won't change and shift the other rows */
}


.spotlightFeedInnerWrapper{
    margin-bottom:20px;
}