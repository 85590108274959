.BonusModalContainer {
    position: absolute;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.BonusModalCard {
    z-index: 100;
    position:absolute;
    /* top:0; */
    width:auto;
    height:auto;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    margin: clamp(4px,0.5vw,10px);
    border-radius: clamp(8px,0.8vw,15px);
    max-width:400px;
    padding: clamp(20px,5vw,35px);
    background-color: var(--white);
}

.celebration-animation {
  top: -50px;
}

.celebration-animation svg {
  height: auto !important;
  width: auto !important;
}

.blue-gradient{
    font-weight: bold;
    background: linear-gradient(90deg, #4285F4, #7F56DA); 
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

.perple-yellow-gradient{
    font-weight: bold;
    background: linear-gradient(90deg, #A84BCF, #F78DA7, #F4AC71, #FACF54);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
  }

/* coin animation */
.floating-element {
  position: fixed;
}

.instruction-target {
  position: relative;
}