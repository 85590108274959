.MenuButtonContainer {
    z-index: 500;
    color:white;
    border-radius: 0.2vw;
    background-color: var(--TEAM-purple);
    overflow: hidden;
    margin: clamp(3px, 0.3vw, 6px);
    display:flex;
    align-items: center;
    justify-content: center;
    cursor:pointer;
}

.SideBarContainer {
    z-index: 500;
    position:fixed;
    left:10px;
    top:100px; 
    max-height: 80vh;
    min-height:50vh;
    min-width:clamp(200px, 20vw, 400px);
    /* width:0px; */
    /* height:80vh; */
    transition-timing-function: ease-in;
    transition-duration: 225ms;
    padding:clamp(5px,0.5vw,10px) clamp(5px,0.5vw,10px);

    /* position:relative; */
    /*top:0px;*/
    z-index:500;
    overflow-y: visible;
    transform:translateX(-110%);
    background-color: var(--TEAM-purple);
    border-radius: 0.2vw;
    overflow-y: scroll;
    /* padding: 0px 20px; */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;


}
.SideBarIconField {
    width:clamp(10px,2vw,30px);
}
.SideBarContainerActive {
    transform: translateX(0);
}

.SidebarBlankAreaOfScreen {
    z-index: 499;
    position:fixed;
    background-color: black;
    top:0px;
    left:0px;
    width:100vw;
    min-height:100vh;
    height:100%;
    opacity: 0.2;
    /*left:410px;*/
    /*top:0px; */
    /*width:400px;*/
    /*height:100%;*/
    /*background-color: white;*/
}


.ContainerSidebarMenu {
    font-size: 14px;
    font-weight: 400;
    color: #FFFFFF;

    /*z-index: 99999;*/
}

.ContainerSidebarSubMenu {
    font-size: 14px;
    font-weight: 400;
    background-color: var(--TEAM-purple);
    padding: 0px 20px;
    border-radius: 20px;
    margin-bottom: 20px;
    margin-left: 20px;
}

.SidebarText {
    padding: 10px 10px;
    /* color:  var(--white) !important; */

}

.SidebarLock {
    position:absolute;
    color: white;
    top:2px;
    right:0px;
    /*background-color: red;*/
}

.aiTagWrapper {
    /*background-color: yellow;*/
    display:flex;
    align-items:center;
    justify-content:center;
}

.aiTag {
    position:absolute;
    top:0;
    right: -14px;
    width:24px;
    height: 22px;
    text-align:center;
    vertical-align: middle;
    overflow: hidden;
    border-radius: 20%;
    background-color: #82E0AA;  
    color:white;
    font-size: 1.0rem;
    font-weight: 400;
}


/*                            */
/*     MEDIA ADJUSTMENTS      */
/*                            */

@media only screen and (max-width: 1325px){


}

@media only screen and (max-width: 1200px){

}

@media only screen and (max-width: 1024px){
  
}


/* PHONES */

@media only screen and (max-width: 650px){

    .section-sidebar-menu .MenuButtonContainer {
        display:none;
    }
}

@media only screen and (max-width: 500px){


}

@media only screen and (max-width: 450px){
 
}

@media only screen and (max-width: 390px){

}