
.displayCardTeamStatsModal {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    cursor:pointer;
    width: 400px;
    background-color:white;
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.displayCardTeamStatsTitleModal {
    color:white;
    background-color: #82E0AA;
    font-size:x-large;
    /*margin-bottom: 0px;*/
}





.displayCardTeamStatsModalValue {
    border: 3px solid #e6e7eb;
    color: #6495ED;
    font-weight: 800;
    font-size: large;
    width: 60px;
    height: 40px;
    /*padding: 0px 10px;*/
    /*margin: 5px 0px 0px 0px;*/

}


.displayCardTeamStatsModalSubtitle{
    color: #919191;
    font-weight: 400;
    font-size: small;
}

.displayCardTeamStatsModalScoreCardVertical
{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    /*margin: 10px 20px;*/
    /*padding: 5px 10px 5px 10px;*/
    flex-wrap:nowrap;
    /*border: 3px solid #e6e7eb;*/
 
/* -webkit-box-shadow: 10px 10px 3px -1px rgba(0,0,0,0.11); */
/*box-shadow: 10px 10px 3px -1px rgba(0,0,0,0.11);*/
}

.displayCardTeamStatsModalScoreCardRow
{
    display:flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0px;
    /*padding: 5px 10px 5px 10px;*/
}


.displayCardTeamStatsModalText {
    font-weight: 600;
    font-size: medium;
    flex: 2;
    /*align-self:flex-start;*/
    /*background:#f7f8fa;*/
}



.displayCardTeamStatsModalTextFooter {
    padding: 10px 0px 10px 0px; 
    font-size: small;
}



.displayTeamCardModalTextRowPadding {
    display:flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px 10px 5px 10px;
    flex-wrap:nowrap;
    /*background-color: red;*/
    background-color: #f7f8fa;

}