.displayCardUserStatsModalValue {
    border: 3px solid #e6e7eb;
    color: #6495ED;
    font-weight: 800;
    font-size: large;
    width: 60px;
    height: 40px;
    /*padding: 0px 10px;*/
    /*margin: 5px 0px 0px 0px;*/

}


.displayCardUserStatsModalSubtitle{
    color: #919191;
    font-weight: 400;
    font-size: small;
}

.displayCardUserStatsModalScoreCardVertical
{
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
    /*margin: 10px 20px;*/
    /*padding: 5px 10px 5px 10px;*/
    flex-wrap:nowrap;
    /*border: 3px solid #e6e7eb;*/
 
/* -webkit-box-shadow: 10px 10px 3px -1px rgba(0,0,0,0.11); */
/*box-shadow: 10px 10px 3px -1px rgba(0,0,0,0.11);*/
}

.displayCardUserStatsModalScoreCardRow
{
    display:flex;
    justify-content: space-around;
    align-items: center;
    margin: 15px 0px;
    width:100%;
    /*padding: 5px 10px 5px 10px;*/
}
