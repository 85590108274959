.employeeSpotlightPageWrapper {
    width:100%;
    /*background-color:black;         slightly off black to provide face to black */
    /*background: linear-gradient(180deg, rgba(200,183,35,1) 0%, rgba(106,97,9,1) 43%, rgba(43,42,7,1) 100%);*/
    position:relative;
    min-height: 100vh;
    overflow-x: hidden;             /* Hide X scroll bar on page */
}        


.employeeSpotlightFeedInnerPageWrapper {
    width:100%;
    /*background-color:yellow;        */
    position:relative;
    min-height: 100vh;
    display: flex;
    flex-wrap: nowrap;    /*No wrap for the three elements (Left, Center, Right) although they can wrap internally*/
    align-items: flex-start; /*Top of page for elements in each column */
    justify-content:center;
}    

.spotlightFeedPageLeft {
    /*display: flex;    */
    /*align-items: center;*/
    /*justify-content: flex-end; */
    height:100%;
    /*background-color: yellow;*/
    /* min-width: 14vw; */
    position: relative;
   /* padding: 10px 10px;    /* internal padding within the column */
}

.spotlightFeedPageCenter {
    /*display:flex;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    height:100%;
    /*background-color: green;*/
    padding: clamp(4px,1vw,10px);    /* internal padding within the column */
}


.spotlightFeedPageRight {
    /*display: flex;    */
    /*align-items: center;*/
    /*justify-content: flex-start;     /*Start toward the middle */
    flex-wrap:wrap;
    height:100%;
    /*background-color: violet;*/
    /* min-width: 14vw; */
    /*padding: 10px 10px;    /* internal padding within the column */

}

