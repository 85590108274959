.ModelContainer {
    position: absolute;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}


.ModalBgContainer {
    z-index: 99;
    position:fixed;
    background-color: rgba(0, 0, 0, 0.3);
    top:0px;
    left:0px;
    width:100vw;
    min-height:100%;
    cursor:pointer;
}

.ModalOuterCard {
    z-index: 100;
    position:absolute;
    width:auto;
    height:auto;
    /* max-width:clamp(1000px,85vw, 1400px); */
    /* min-width: clamp(180px,70vw,300px); */
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    border-radius: clamp(8px,0.8vw,15px);
}

@keyframes shrinkIn {
    0% {
        opacity: 0;
        transform: scale(1.5); /* Adjust based on size difference between the big and small modal */
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.SmallModal {
    z-index: 101;
    position: absolute;
    width: auto;
    height: auto;
    border-radius: clamp(8px,0.8vw,15px);
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    animation: shrinkIn 0.4s ease-out forwards;
}

/* connectivity info related changes start */

.connectivityScheduledWrapperGap {
    gap: clamp(8px, 1.5vw, 12px);
}

.connectivityRightBorderWrapper {
    border-right: 1px solid white;
    padding-right: clamp(8px, 1.5vw, 12px);
}

/* connectivity info related changes end */

