/* General modal  */

.modalGeneralImage {
    z-index: 1;
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border-color:white;
    border: 3px solid white;
    overflow: hidden;
    background-color: white;
    position: absolute;
    top:-50px;
    left:20px;
}


.modalGeneralCenterColumn {
    /*width:300px;*/
    border-radius: clamp(4px,0.4vw,10px);
    width:100%;
    padding:0px 10px;
}

.modalGeneralInnerCard {
    position:relative;
    opacity: 1;
    /*padding-top: 100px;*/
    width: 100%;
    min-height: 100px;
    /*boxShadow: '0 8px 16px 0 #BDC9D7',*/
    border-radius: clamp(4px,0.4vw,10px);
    /*margin: 10px;  */
    /*padding: 5px;*/
    /*cursor:pointer;*/

    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

