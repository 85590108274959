.pieChart {
    display: flex;
    flex-direction: column;
    /*background:yellow;*/
    align-items: center;
    justify-content: center;
    
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
    position:relative;

}

.pieChartTitle {
    font-size:1.2rem;
    color: #6495ED
    /*margin-bottom: 20px;*/
}

.pieChartTitle2 {
    font-size:0.8rem;
    color: #6495ED;
    font-style: italic;
    /*margin-bottom: 20px;*/
}

.pieChartTitleTarget {
    font-size: 1.0rem
}

.pieChartCenterValue {
    position: absolute;
    font-size:1.4rem;
    color: #6495ED
}