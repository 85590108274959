.launchPadPageWrapper {
    width:100%;
    /*background-color:#111;        slightly off black to provide face to blakk
    position:relative;
    min-height: 100vh;
    overflow-x: hidden;             /* Hide X scroll bar on page */
}

.launchPadBlankRow{
    width:100%;
    height:300px;
    /* background-color: black; */
}