/*

  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  copyright - CONECTERE.

*/

.challengeHistory {
   max-height: inherit;
   overflow-y: scroll;
   padding:clamp(4px,2vw,16px);
   /* margin-bottom:clamp(4px,2vw,16px); */
}

.challengeDataRecordCard {
   position:relative;
   width:clamp(200px,80vw,600px);
   margin-bottom:clamp(10px,2vw,24px);
   padding:clamp(4px,0.5vw,8px);
   border-radius:clamp(4px,1vw,10px);
   background-color: var(--light-mode-card-background);
   border-top-width:clamp(1px,0.1vw,3px);
   /* border-top-color:red; */
   border-top-style:solid;
   min-height: 4em;
   min-width: clamp(400px,60vw,800px);
   max-width: 95vw;
}

.darkMode .challengeDataRecordCard {
   background-color: var(--dark-mode-card-background) !important;
}

.challengeDataEntry { 
   /* margin-top:clamp(4px,2vw,16px); */
   padding-top:0em;
}

.challengeDataEntry .buttonStyle1 {
   margin-right:0.2em;
   margin-left:0.2em;   
   padding-left: 0.2em;
   padding-right: 0.2em;
   padding-top: 0.2em;
   padding-bottom: 0.2em;
}


/* challengeDataEditor */

.challengeDataEditor {
	max-width: 95vw;
   max-height: 90vh;
   background-color: var(--light-mode-card-background);
   /* padding:1.0em; */
   /* border-width:0.2em;
   border-style:solid;
   border-color:var(--TEAM-purple);	 */
}

/* End challengeDataEditor */

/* Challenges Admin Page */
.adminChallengesEnableRegion {
   margin:clamp(4px,0.5vw,10px);
}

.adminChallengesSettingsRegion{
   /* border-style: solid;
   border-width: 2px;
   border-color: var(--TEAM-purple); */
   background-color:var(--soft-grey);
}

.adminChallengesCapsRegion {
   padding:clamp(4px,1vw,10px);
   margin:clamp(4px,1vw,10px);
}

.adminChallengesPage .disabled {
   color:var(--davy-grey) !important;
   opacity:0.3;
}
