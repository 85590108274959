
.xxx {
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    cursor:pointer;
    width: 400px;
     background-color:white;
   
    -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
    box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);

}
