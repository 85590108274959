/* Modal fixed on the screen.  Does NOT scroll */

.ModalNoBackgroundFixedContainer {
    position: fixed;
    top:0px;
    left:0px;
	height:100%;
	min-height:100vh;
	width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.ModalNoBackgroundFixedCard {
    z-index: 100;
    position:relative;
    /* top:50vh;
    left:50vw; */
    /*transform: translate(-50%, -50%);    /*Shift to the LEFT and UP to center the CARD on the viewable screen */
    width: auto;
    height: auto;
    max-width:clamp(600px,95vw, 1400px);
    min-width: clamp(180px,60vw,300px); 
    max-height:90vh;
    background-color:#BB8FCE;
    color:var(--HEADER-blue);   /*Necessary to see text in dark mode since modals are fixed white background */
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;

    margin: clamp(4px,0.5vw,10px);
    padding: clamp(2px,0.3vw,4px);         /* Adds a border around our card */
    border-radius: clamp(4px,0.4vw,10px);
}


@media only screen and (max-width: 1520px){  
    .ModalNoBackgroundFixedCard{
        max-width:95vw;
    }
}

@media only screen and (max-width: 1440px){  

}

@media only screen and (max-width: 1325px){  

}

@media only screen and (max-width: 1200px){  

}

@media only screen and (max-width: 1024px){  

}

@media only screen and (max-width: 993px){  

}

@media only screen and (max-width: 768px){  

}


/* PHONES */

@media  only screen and (max-width: 650px) {
}


@media only screen and (max-width: 500px){  

}

@media only screen and (max-width: 390px){  

}

