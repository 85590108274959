/*

  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  copyright - 2020-2023.

*/

/* LOGIN / AUTHENTICATION */
.loginContainer {
   position:absolute;
   top:0;
   right:0;
   min-height: 100vh;
   min-width: 100vw;
   /* background: rgba(93, 173, 226 ,0.4); */
   background-position: center;
   background-repeat: no-repeat;
   background-size: cover;
   color: var(--BALANCE-blue);
   /* background: rgba(187,143,206 ,0.2); */

}

.loginWrap {
   /* background: rgba(93, 173, 226 ,0.0); */
   background-color: rgba(0, 0, 0 ,0.4);

   /* background-color: var(--BALANCE-blue); */
   /*background-color: var(--GROWTH-yellow);*/
   /*margin-top:122px;*/
   /* margin-bottom:clamp(20px,10vh,100px); */
   padding-top: 20px;
   padding-bottom: 20px;
   position: relative;
   /* border-top-right-radius: clamp(50px,15vw,200px); */
   /* border-bottom-right-radius: clamp(50px,15vw,200px); */
   overflow-x: hidden;
   /* min-height: 80vh; */
   /* -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
   box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);     */
}

.loginCard {
   width:auto;
   max-width:clamp(600px,70vw, 1400px); 
   min-width: clamp(300px,60vw,600px);
   padding:clamp(4px,2vw,20px);
   background-color: none;

   /* background-color:#BB8FCE;  */
   /* border-radius: clamp(4px,0.4vw,10px);
   -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
   box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
}

.loginInnerCard {
   position:relative;
   /* background-color: rgba(0, 0, 0 ,0.4); */
   /* background-color: white; */
   /* opacity: 1; */
   /*padding-top: 100px;*/
   /* width: 100%; */
   /* min-height: 100px; */
   /*boxShadow: '0 8px 16px 0 #BDC9D7',*/
   border-radius: clamp(4px,0.4vw,10px);
   /*margin: 10px;  */
   padding: clamp(4px,1vw,10px);
   /*color: black;*/
   /*cursor:pointer;*/
   /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
   /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}
/* Authenticator Styling & CSS Variables
   https://ui.docs.amplify.aws/react/connected-components/authenticator/customization
   https://ui.docs.amplify.aws/react/theming/css-variables
*/

.user-login {

   a {
       font-weight: 600;
   }
   .amplify-input {
       background-color: white !important;
   }
   .amplify-button {
       /* background-color: white !important; */
       border: none !important;
   }
   .amplify-button--primary {
       background-color: var(--TEAM-purple) !important;
       color:white !important;
   }

   .amplify-button--link {
       color:white !important;
       font-weight: 600 !important;
   }

   .amplify-field-group__outer-end {
      background-color: white;
   }


   [data-amplify-authenticator] {

       --amplify-components-authenticator-router-background-color: rgba(93, 173, 226 ,0);
       /* --amplify-components-authenticator-router-box-shadow: 0 0 16px var(--amplify-colors-overlay-10); */
       --amplify-components-authenticator-router-box-shadow: none;
       --amplify-components-authenticator-router-border-width: 0;
       --amplify-components-authenticator-form-padding: var(--amplify-space-medium) var(--amplify-space-xl) var(--amplify-space-xl);
       --amplify-components-button-primary-background-color: var(--amplify-colors-neutral-100);
       --amplify-components-fieldcontrol-focus-box-shadow: 0 0 0 2px var(--amplify-colors-purple-60);
       --amplify-components-tabs-item-active-border-color: var(--amplify-colors-neutral-100);
       --amplify-components-tabs-item-color: var(--amplify-colors-neutral-80);
       --amplify-components-tabs-item-active-color: var(--amplify-colors-purple-100);
       --amplify-components-button-link-color: var(--amplify-colors-purple-80);

       --amplify-components-field-label-color:white;
       --amplify-components-input-border-color:white;
       /* --amplify-components-input-color:yellow; */
       --amplify-components-input-focus-border-color:var(--TEAM-purple);
   }
}
/* END LOGIN */
