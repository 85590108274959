
/*         CONECTIVITY CARD  

    Standard size = 400w x 360h ( 0.9 aspect ratio)
        Inner wrapper = 360h
            Header wrapper =         80px
            Invitation ROW =         80px
            Description wrapper =    140px
            Actions wrapper =        60px
            ----------------------------------
                                     360PX   
            
    Small = 300w x 270h
        Inner wrapper = 270h
            Header wrapper =         77px
            Description wrapper =    135px
            Actions wrapper =        58px
            ----------------------------------
                                     270PX  
    
*/

.conectivityCardOuterWrapper {
   position: relative;
   z-index: 0;
   cursor:pointer;
}

.connectivityCardMainWrapper {
    width: calc(33% - 10px);
}

.conectivityCardHeaderSpecialEventAnchor {
   position:absolute;
   top:0;
   left:0;
   z-index:1;
   transform:translate(-15%,-40%);
 }

 .conectivityCardHeaderSpecialEventText {
    position:absolute;
    top:50%;
    left:50%;
    z-index:1;
    transform:translate(-50%,-50%);
 }

.fixSizeBadgeImg{
    height:48px;   
}

.connectivitySpecialEventBadgeText {
    position: absolute;
    top: 20%;
    left: 50%;
    z-index: 1;
    transform: translate(-50%, 0%);
}

.conectivityCardHeaderOpenAnchor {
 /* position:absolute;
 top:0;
 right:0;
 z-index:1;
 border-radius: clamp(2px,0.5vw,4px);
 border:1px solid;
 transform:translate(-20%,20%); */
 z-index:1;
}

.scheduledInfoActionsWrapper {
 border-left: 1px solid var(--light-grey);
 padding-left: clamp(10px, 0.5vw, 16px);
 gap: 5px;
}

.scheduledActionRowWrapper {
 width: 100%;
 padding: clamp(10px, 2vh, 16px) clamp(14px, 1vw, 20px);
}

.conectivityCardScale {
   transition: transform 800ms;    /* Control transition time when scaling to 1.0 */
   transform: scale(1.0);
}

.conectivityCardScale:hover   {
   transform: scale(1.1);
   z-index: 1;
   transition: transform 800ms;    /* Control transition time when scaling in hover */
}


.conectivityCardRoot {
   z-index: 0;
   position: relative;
   width: 320px;
   min-height: 360px;
   border:none;
   border-radius: clamp(2px,0.5vw,4px);
   margin: clamp(4px,0.3vw,10px);
   background-color: var(--light-mode-card-background);
   overflow: hidden;
   color: var(--davy-grey) !important;
   transition: transform 800ms;    /* Control transition time when scaling to 1.0 */
   transform: scale(1.0);
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.conectivityCardRootWrapper {
    width: 100%;
    min-height: 250px;
    border-radius: clamp(12px,0.5vw,14px);
    margin: 0;
    background-color: var(--light-mode-card-background);
    /* overflow: hidden; */
    border-left: 8px solid;
    box-shadow: 0px 1px 25px -9px rgba(77, 16, 99, 0.25);
 }

.darkMode .conectivityCardRootWrapper {
    background-color: var(--dark-mode-card-background) !important;
    /* border-left: none;
    border-top: 3px solid; */
}

.conectivityCardSmall {
   width:300px !important; 
   margin-right: clamp(4px,0.3vw,10px) !important;
   min-height: 338px;    
}

.darkMode .conectivityCardRoot {
   background-color: var(--dark-mode-card-background) !important;
   border-style:solid;
   border-width: clamp(2px,0.4vw,4px);   
}

/*Standard size on home page and for Launch cards & Community Cards for rows marked not marked LARGE 
.conectivityCardSmall {
   width:300px !important; 
   margin-right: clamp(4px,0.3vw,10px) !important;
   height: 270px !important;
   min-height: unset !important;    
}
*/
.conectivityCardInnerWrapper {
   height:360px;         /*Inner Wrapper (300) + Header Wrapper (100)+ Actions Wrapper (60) equals == overall Card Height  (460 set as minHeight) */
   /*background-color:white;*/
   /*background-color:pink;*/
}

.conectivityCardInnerWrapperWithInstructions {
   height:100%;
   min-height: 360px;
}

.conectivityCardInnerWrapperSmall {
   height:270px;
}

.conectivityCardHeaderWrapper {
   /*padding:0.3vw 0px;*/
   height: 85px;
   overflow:hidden;
}

.darkMode .conectivityCardHeaderWrapper {
    background-color: var(--dark-mode-header-background) !important;
}

.conectivityCardHeaderWrapperSmall {
   height: 77px !important;
}

.conectivityCardHeaderImageWrapper {
   /* flex: 1; */
   /*margin: 5px;*/
   padding:clamp(2px,0.5vw,5px);
}

.conectivityCardHeaderImageLarge {
   width:64px;
}

.conectivityCardHeaderImage {
   width:56px;
}

.conectivityCardHeaderImageV2 {
   width:24px;
}

.connectivityRingCardImage {
    width: 36px;
}

.connectivityCardHeaderWrapper {
    padding: clamp(10px, 2vh, 16px) clamp(14px, 1vw, 20px);
    align-items: center;
}

/* .darkMode .connectivityCardHeaderWrapper {
    border-bottom: 3px solid;
} */

.conectivityCardHeaderTextWrapper {
   flex: 5;
   padding-right: clamp(8px,2vw,20px);
   padding-top: clamp(2px,0.5vw,5px);
   /*height: 100%;*/
   text-align: center;
}

.conectivityCardInvitationsRow{
 height: 70px;
 /*overflow-x: scroll !important;
 overflow-y: visible !important; /*Does not work due to the Scroll  -  SET PROGROMATICALLY BASED ON NUMBER OF INVITATIONS */
}

.conectivityCardInvitationWrapper{
 min-width: 200px;        /* min width for each invitation in our row of invitations; 2 per card width */
 width:100%;
 position:relative;
 margin-top:clamp(4px,0.5vw,8px);        /*Room for chat message */
 padding: 0px clamp(4px,1vw,10px);      /* Padding between multiple invitations */
 /* margin-bottom:16px; */
 overflow-y: visible !important;
}

.connectivityCardInvitationWrapperV2 {
    min-width: auto !important;
}

.conectivityCardSendInvitationBadge {
  position:absolute;		/*position on the message icon */
  top: 0px;
  left: 0px;
  transform:translate(-50%,-50%);
  border-radius: 50%;
   height: 30px;
   width: 30px;
   z-index: 1;
   border: 1px solid red;
   line-height: 1.0
}

.conectivityCardInvitationSubMenuConnector{
   position: absolute;
   top: 0px;
   left: 50%;        /*Center on the invitation row */
   transform: translate(-50%, -90%);    /*Shift to right to center the subMenuContainer itself rather than having it's corner centered in the row */
   z-index: 1; 
   height:0;
   /*width:100%;*/
   /*height:36px;*/
   /*width: 36px;*/
   /* background-color: yellow; */
   overflow:hidden; /*This will hide the elements that overflow when the height is ZERO */
   /*transition: all 0.3s;*/
   display:flex;
   flex-direction:row;
   align-items: flex-start;
   justify-content:center;
   padding:0;
   margin:0;
   font-size:32px;
   /*color:yellow;*/
}

.conectivityCardInvitationSubMenuContainer {
   position: absolute;            /* Relative to View Port so we can see the full menu outside the scroll row */
   bottom: -10px;        /* Move down the screen 10px to expose the diamond */
   left: 50%;        /*Center on the invitation row */
   transform: translate(-50%, +100%);    /*Shift to right to center the subMenuContainer itself rather than having it's corner centered in the row */
   z-index: 9999;
   overflow:hidden; /*This will hide the elements that overflow when the height is ZERO */
   /*transition: all 0.3s;*/
   width:280px;
}

.conectivityCardAvatarWrapper {
   transition-timing-function: ease-in;
   transition: all 0.6s;
   cursor: default; /* changed 9.26 from pointer */

}

.conectivityCardChatMessageButton {
   position: absolute;
   top: 0px;
   right: 0px;
   /* font-size: 1.0rem; */
   z-index: 1;
   transform: translate(100%,-25%);    /*Shift to right by the width of this object to line it up at the edve of the invitation group */
}

.conectivityCardDescriptionWrapper{
 /* height: 140px; */
 text-align: center;
 padding-top:0px;
 padding-bottom:0px;
 padding-left:clamp(4px,0.5vw,10px);
 padding-right:clamp(4px,0.5vw,10px);
}

.connectivityCardInfoWrapper {
    padding: 0 clamp(14px, 1vw, 20px);
    flex-grow: 1;
}

.conectivityCardDescriptionWrapperSmallFlex{
 text-align: center;
 padding:0px 10px;
}

.ConectivityDescriptionTextDetailed {
 font-weight: 600;
 border-radius:10px;
 font-size:1.0rem;
 padding: 10px 10px;
 border:1px solid red; /* color is set dynamically based on conectivity */
 margin: 10px 10px;
 text-align:center;
 /*background-color: red;*/
}

.conectivityCardInstructionsWrapperHidden {
   height:0;
   width:100%;
   max-height: 0;
   overflow:hidden; 
   padding:0px 10px;
   /*transition: all 1.2s ease-out; */
}

.conectivityCardActionsWrapper {
   background:#fffede;
   height: 60px;
   color: var(--HEADER-blue);
}

.darkMode .conectivityCardActionsWrapper {
    background-color: var(--dark-mode-header-background);
}

.conectivityCardActionsWrapperSmall {
   height: 58px !important;
}

.conectivityCardPopOutButton {
   background-color: #6495ED;
   margin-top:5px;
   margin-bottom:5px;
   cursor: pointer;
   color:white;
   outline: none;
   border-radius: 0.3vw;
   padding-left: 0.3rem;
   padding-right: 0.3rem;
   padding-top: 0.1rem;
   padding-bottom: 0.1rem;
   font-weight: 500;
   font-size: 1.0rem;
   transition: all 0.2s;
   border:none;
}

.conectivityCardPopOutButton:hover {
   transform: scale(1.15);
   font-weight: 700;
}

/* Animated CSS for transition */
.ConectivityCardShowInstructions {
   height: 100%;
   max-height: 2000px;
}

.ConectivityDescriptionWrapperPreview{
   padding: 20px 20px;
   color:var(--page-text);
   background-color: var(--card-background);
   text-align: center;
}

.ConectivityInstructionPreview {
   padding: 10px 10px 0px 10px;
   color:var(--page-text);
   background-color: var(--card-background);
}

.darkMode {
    .ConectivityInstructionPreview span[style], li[style]{
        color:var(--page-text) !important;      /*In dark mode, turn ALL text and symbols white for readability */
    }
}



/* END CONECTIVITY CARD  */

/* SCHEDULED CONECTIVITY DETAILED VIEW */

/* CONECTIVITY CARD DETAILED VIEW */
.ScheduledConectivityDetailedView {
   z-index: 100;
   position:relative;
}

.ConectivityPreviewModalContainer {
   position: absolute;
   top:0px;
   left:0px;
   /*height:100%;*/     /*Removed so if parent is bigger we can grow */
   min-height: 100%;    /*At a minimum, expand to the parent */
   width:100%;
   display: flex;
   justify-content: center;
   align-items: flex-start;
   padding: 150px 0px;    /*Same padding on top and bottom so our height of our opaque container is correct */
   z-index: 100;
   /*background-color: rgba(0,0,0,.7);*/
   
   /*background:red*/
}



.ConectivityDetailedViewContainer {
   /* min-width: clamp(400px, 80vw, 1200px); */
   max-width: 95vw;
   max-height: 90vh;
   background-color: var(--card-background);
   /* padding:1.0em; */
   border-width:clamp(1px,0.3vw,3px);
   border-style:solid;
   border-color:var(--TEAM-purple);
   width:100%;
}

.darkMode .ConectivityDetailedViewContainer {
   background-color: var(--dark-mode-card-background);
}

.ConectivityDetailedViewContainer .ConectivityDetailedViewRow {
   max-height: calc(90vh - 3em);
   padding:clamp(4px,0.5vw,8px);
}
 
 .ConectivityDetailedViewContainer {
    /* min-width: clamp(400px, 80vw, 1200px); */
    max-width: 95vw;
    max-height: 90vh;
    background-color: var(--light-mode-card-background);
    /* padding:1.0em; */
    border-width:clamp(1px,0.3vw,3px);
    border-style:solid;
    border-color:var(--TEAM-purple);
    width:100%;
    display:flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
 }

 .ConnectivityModelInnerContainer {
    border-radius: clamp(8px,0.8vw,15px);
 }
 
 .darkMode .ConectivityDetailedViewContainer {
    background-color: var(--dark-mode-card-background);
 }
 
 .ConectivityDetailedViewContainer .ConectivityDetailedViewRow {
    max-height: calc(90vh - 3em);
    padding:1em;
 }
 
 
/* connectivity info viewer style start */

 .ConectivityDetailedViewContainer .ConnectivityInfoViewPaper {
    max-height: calc(90vh - 3em);
 }

 .connectivityInfoPortion {
    max-height:inherit;
    /* overflow-y:scroll; */
    overflow: hidden;
    /* width:100%; */
    border-radius: clamp(8px,0.8vw,15px);
    margin-right:clamp(4px,1vw,10px);
}

 .ConnectivityImageWrapper {
    padding: clamp(20px, 3vw, 30px);
    border-radius: 100%;
    background-color: var(--card-background);
 }

 .connectivityInfoHeaderInnerWrapper {
    position: relative;
    top: clamp(40px, 10vh, 55px);
    z-index: 1;
    gap: 20px;
 }

 .connectivityInfoHeaderContainer {
    height: clamp(125px, 17vh, 175px);
    padding: clamp(15px, 1.5vw, 25px);
    border-top-left-radius: clamp(8px,0.8vw,15px);
    border-top-right-radius: clamp(8px,0.8vw,15px);
 }

 .connectivityModalCloseIcon {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 1;
 }

 .connectivityInfoContentContainer {
    padding: clamp(15px, 1.5vw, 25px);
    padding-top: clamp(30px, 5vh, 45px);
    flex-grow: 1;
    height: 100%;
    overflow-y: auto;
 }

 .connectivity-actionRow {
    padding-right: clamp(15px, 1.5vw, 25px);
    padding-left: clamp(15px, 1.5vw, 25px);
}

.actionRow-innerContainer {
    padding-top: clamp(15px, 1.5vw, 25px);
    padding-bottom: clamp(15px, 1.5vw, 25px);
    border-top: 1px solid var(--dust-grey);
 }

 /* connectivity info viewer style end */

 /* Conectivity Detailed View */
 .ConectivityDetailedViewContainer .conectivityPortion {
    flex-shrink:1;
    /* width:100%; */
    max-height:inherit;
   /* overflow-y:hidden; */
   display:flex;
   flex-direction:column;
   align-items: center;  
   /* flex:1; */
   /* background-color: pink; */
   margin-right:clamp(4px,1vw,10px);
   /* padding:clamp(4px,1vw,10px); */
}

.ConectivityDetailedViewContainer .chatPortion {
    flex-shrink:1;
    /* width: 100%; */
    max-height: inherit;
    display:flex;
    flex-direction:column;
    align-items: center;
    transition-timing-function: ease-in;
    transition: all 2.8s;  
    max-width: 50%;
    /* overflow-y:hidden; */
    /* flex:0.5; */
    /* margin:clamp(10px,5vw,50px); */
    /* background-color: yellow; */
}
.ConectivityDetailedViewContainer .expandChatPortionAnchor {
    background-color: var(--card-background);
    margin:clamp(4px,1vw,10px);
    align-self:flex-end;
    position:relative;
    /* width: 100%; */
    display:flex;
    flex-direction:row;
    justify-content: flex-end;
    align-items: center;
    /* top:clamp(2px,0.5vw,4px);
    right:clamp(2px,0.5vw,4px); */
    /* transition: transform 400ms ease-out; */
    /* padding:clamp(0.5px,0.3vw,8px) clamp(0.5px,0.7vw,8px) clamp(0.5px,0.3vw,8px) clamp(0.5px,0.3vw,8px); */
    /* background-color: var(--card-background); */
    /* border: 0.5px solid var(--light-grey); */
    /* box-shadow: rgba(255, 255, 255, 0.4) 1px 2px 10px -1px, rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px; */
}

.ConectivityDetailedViewContainer .expandChatPortionButton {
    /* margin-right:clamp(4px,0.5vw,8px); */
}
.chatClosed .expandChatPortionButton{
    transform:rotate(0deg);
}
.chatExpanded .expandChatPortionButton{
    transform:rotate(180deg);
}

.lbClosed .expandlbPortionButton{
    transform:rotate(0deg);
}
.lbExpanded .expandlbPortionButton{
    transform:rotate(180deg);
}

.ConectivityDetailedViewContainer .chatWindowOuterContainer, .ConectivityDetailedViewContainer .lboard_wrap {
    flex-shrink:1;
    flex-grow:1;
	transition:all 600ms;
}
.chatClosed .chatWindowOuterContainer{
    max-height:0;
    max-width:0;
}
.chatExpanded .chatWindowOuterContainer{
    max-height:90vh;
    max-width:90vw;
}

.lbClosed .lboard_wrap{
    /* display:none; */
    max-height:0;
    max-width:0;
}
.lbExpanded .lboard_wrap{
    /* display:block; */
    max-height:90vh;
    max-width:90vw;
}

.ConectivitiesDetailedViewCard {
    width: 100%;
    height:100%;
    overflow-y:scroll;
    border-radius: clamp(4px,0.4vw,10px);
    background-color: var(--card-background) !important;
    /*margin: 10px;*/
    /* overflow: hidden; */
    position:relative;
    max-width:clamp(600px,40vw,1000px);
    /* border: 1px solid grey; */
    /* min-width:clamp(200px,40vw,600px); */
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
 }

.connectivityInfoViewCard {
    border-radius: clamp(8px,0.8vw,15px);
    max-width:clamp(600px,65vw,1000px);
    display: flex;
    flex-direction: column;
    height: 100%;
}

.conectivityCardApprovedPaidTime {
   padding:0px clamp(2px,0.5vw,5px);
}

.conectivityCardAwards {
   padding:0px clamp(2px,0.5vw,5px);
}

/* END CONECTIVITY CARD DETAILED VIEW */

/* CONECTIVITY INSTRUCTIONS DEFAULT STYLING */

.conectivityCardRoot, .ConectivityInstructionPreview ul {
   text-align:left;
}

.conectivityCardRoot .ConectivityInstructionPreview ul {
   text-align:left;
}

/* CONECTIVITY DEFAULT STYLING */




/*                            */
/*     MEDIA ADJUSTMENTS      */
/*                            */

/* MED LAPTOP */
@media  only screen and (max-width: 1520px) {

   .headerLinkIcon{
       /* display:none; */
   }    

   .connectivityCardMainWrapper {
        width: calc(50% - 10px) !important;
    }
}

/* SMALL LAPTOP */
@media  only screen and (max-width: 1440px) {

   .conectivityCardRoot {
       width: 320px;
       min-height: 332px;
   }

   .connectivityCardMainWrapper {
        width: calc(50% - 10px) !important;
    }
   
   .conectivityCardSmall {
       width:276px !important; 
       min-height: 312px !important;
   }
   
   .conectivityCardInnerWrapper {
       height:288px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:216px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 64px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 62px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:51px;
   }
   
   .conectivityCardHeaderImage {
       width:45px;
   }
   .conectivityCardInvitationsRow{
       height: 64px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 160px;        
     margin-top:12px;        
     padding: 0px 8px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 24px;
       width: 24px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:224px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 112px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 108px; */
   }
   
   .conectivityCardActionsWrapper {
       height: 48px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 46px !important;
   }
     
}

@media only screen and (max-width: 1325px){


   .conectivityCardRoot {
       width: 320px;
       min-height: 332px;
   }

   .connectivityCardMainWrapper {
        width: calc(50% - 10px) !important;
    }
   
   .conectivityCardSmall {
       width:276px !important; 
       min-height: 312px !important;
   }
   
   .conectivityCardInnerWrapper {
       height:288px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:216px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 64px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 62px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:51px;
   }
   
   .conectivityCardHeaderImage {
       width:45px;
   }
   .conectivityCardInvitationsRow{
       height: 64px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 160px;        
     margin-top:12px;        
     padding: 0px 8px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 24px;
       width: 24px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:224px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 112px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 108px; */
   }
   
   .conectivityCardActionsWrapper {
       height: 48px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 46px !important;
   }
     
}


@media only screen and (max-width: 1200px){
    .connectivityCardMainWrapper {
        width: 100% !important;
    }

}


@media  only screen and (max-width: 1024px) {


   .conectivityCardRoot {
       width: 250px;
       min-height: 256px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:214px !important; 
       min-height: 242px !important;
   }
   
   .conectivityCardInnerWrapper {
       height:270px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:202px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 60px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 57px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:48px;
   }
   
   .conectivityCardHeaderImage {
       width:42px;
   }
   .conectivityCardInvitationsRow{
       height: 60px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 150px;        
     margin-top:11px;        
     padding: 0px 7px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 22px;
       width: 22px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:210px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 105px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 101px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 45px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 43px !important;
   }
  
}

@media only screen and (max-width: 993px){

   .conectivityCardRoot {
       width: 245px;
       min-height: 248px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:208px !important; 
       min-height: 234px !important;
   }
   
   .conectivityCardInnerWrapper {
       height:252px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:189px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 60px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 57px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:48px;
   }
   
   .conectivityCardHeaderImage {
       width:42px;
   }
   .conectivityCardInvitationsRow{
       height: 56px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 140px;        
     margin-top:11px;        
     padding: 0px 7px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 21px;
       width: 21px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:196px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 98px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 94px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 42px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 40px !important;
   }
}

/* TABLET */

@media  only screen and (max-width: 768px) {


   .conectivityCardRoot {
       width: 200px;
       min-height: 192px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:160px !important; 
       min-height: 180px !important;
   }
   
   .conectivityCardInnerWrapper {
       height: 216px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:162px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 48px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 46px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:38px;
   }
   
   .conectivityCardHeaderImage {
       width:34px;
   }
   .conectivityCardInvitationsRow{
       /* display:none; */
       height: 48px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 120px;        
     margin-top:9px;        
     padding: 0px 6px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 18px;
       width: 18px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:168px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 84px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 81px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 36px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 35px !important;
   }     
}


/* PHONES */

@media  only screen and (max-width: 650px) {
    .ConectivityDetailedViewContainer .expandChatPortionAnchor {
        /* display:block; */
        /* z-index: 999999;
        position:absolute;
        top:0;
        right:clamp(2px,0.5vw,4px); */
    }

    .ConectivityDetailedViewContainer .chatPortion{
        position:absolute;
        top:0;
        right:0;
        max-width: 80vw;
        z-index: 99999;
        padding-top:clamp(4px,5vh,20px);    /*Prevent the LB and messages from coming all the way up to the top */
    }

    .conectivityCardApprovedPaidTime {
       display:none;
   }
   
   .conectivityCardRoot {
       width: 164px;
       min-height: 172px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:136px !important; 
       min-height: 154px !important;
   }
   
   .conectivityCardInnerWrapper {
       height: 162px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:122px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 36px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 34px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:28px;
   }
   
   .conectivityCardHeaderImage {
       width:26px;
   }

   .conectivityCardHeaderOpenAnchor {
       display:none;
   }

   .conectivityCardInvitationsRow{
       height: 36px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 96px;        
     margin-top:7px;        
     padding: 0px 4px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 14px;
       width: 14px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:134px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 67px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 61px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 29px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 26px !important;
   }
}

@media only screen and (max-width: 500px){
    .conectivityCardApprovedPaidTime {
       display:none;
   }
   
   .conectivityCardRoot {
       width: 164px;
       min-height: 172px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:136px !important; 
       min-height: 154px !important;
   }
   
   .conectivityCardInnerWrapper {
       height: 162px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:122px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 36px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 34px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:28px;
   }
   
   .conectivityCardHeaderImage {
       width:26px;
   }

   .conectivityCardHeaderOpenAnchor {
       display:none;
   }

   .conectivityCardInvitationsRow{
       height: 36px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 96px;        
     margin-top:7px;        
     padding: 0px 4px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 14px;
       width: 14px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:134px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 67px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 61px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 29px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 26px !important;
   }

}

@media only screen and (max-width: 450px){
    .conectivityCardApprovedPaidTime {
       display:none;
   }
   
   .conectivityCardRoot {
       width: 164px;
       min-height: 172px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:136px !important; 
       min-height: 154px !important;
   }
   
   .conectivityCardInnerWrapper {
       height: 162px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:122px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 36px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 34px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:28px;
   }
   
   .conectivityCardHeaderImage {
       width:26px;
   }

   .conectivityCardHeaderOpenAnchor {
       display:none;
   }

   .conectivityCardInvitationsRow{
       height: 36px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 96px;        
     margin-top:7px;        
     padding: 0px 4px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 14px;
       width: 14px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:134px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 67px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 61px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 29px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 26px !important;
   }

}

@media only screen and (max-width: 390px){
    .conectivityCardApprovedPaidTime {
       display:none;
   }
   
   .conectivityCardRoot {
       width: 164px;
       min-height: 172px;
   }

   .connectivityCardMainWrapper {
        width: 100% !important;
   }
   
   .conectivityCardSmall {
       width:136px !important; 
       min-height: 154px !important;
   }
   
   .conectivityCardInnerWrapper {
       height: 162px; 
   }
   
   .conectivityCardInnerWrapperSmall {
       height:122px;
   }
   
   .conectivityCardHeaderWrapper {
       height: 36px;
   }
   .conectivityCardHeaderWrapperSmall {
       height: 34px !important;
   }   
   .conectivityCardHeaderImageLarge {
       width:28px;
   }
   
   .conectivityCardHeaderImage {
       width:26px;
   }

   .conectivityCardHeaderOpenAnchor {
       display:none;
   }

   .conectivityCardInvitationsRow{
       height: 36px; 
   }
   .conectivityCardInvitationWrapper{
     min-width: 96px;        
     margin-top:7px;        
     padding: 0px 4px;     
   }
   .connectivityCardInvitationWrapperV2 {
        min-width: auto !important;
    }
   .conectivityCardSendInvitationBadge {
       height: 14px;
       width: 14px;
   }
   .conectivityCardInvitationSubMenuContainer {
       width:134px;
   }

   .conectivityCardDescriptionWrapper{
       /* height: 67px; */
   }
   
   .conectivityCardDescriptionWrapperSmall{
     /* height: 61px ; */
   }
   
   .conectivityCardActionsWrapper {
       height: 29px;
   }
   
   .conectivityCardActionsWrapperSmall {
       height: 26px !important;
   }

}





