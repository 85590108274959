.stackedBarChart {
    display: flex;
    flex-direction: column;
    background:white;
    align-items: center;
    justify-content: center;
    height:100%;
    width:100%;
    margin: 5px;
    padding: 10px; 
    /*border-radius: clamp(4px,0.4vw,10px);*/
    
}

.stackedBarChartTitle {
    font-size:1.3rem;
    font-weight: 600;
    color: #6495ED
}