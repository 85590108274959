/*

  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  copyright - CONECTERE.

*/

.launchRuleCard {
   cursor:pointer;
   width:clamp(200px,80vw,600px);
   margin-bottom:clamp(10px,2vw,24px);
   padding:clamp(4px,0.5vw,8px);
   border-radius:clamp(4px,1vw,10px);
   background-color: var(--light-mode-card-background);
   border-top-width:clamp(4px,1vw,10px);
   /* border-top-color:red; */
   border-top-style:solid;
}

.darkMode .launchRuleCard {
   background-color: var(--dark-mode-card-background) !important;
}
