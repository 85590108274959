
.calendarCardContainer {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content:center;
    position:relative;
    height:auto;
    min-height: 100px;
    width: 500px;
    border-radius: 16px;
    margin-top: 20px;
    /*border: 1px solid grey;*/
    
    cursor: pointer;
    transition: all 0.6s;

    -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
    box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);    
}

.addToCalendardButton {
    /*height: 60px;*/
    /*width: 180px;*/
    border:none;
    cursor: pointer;
    color:gold;
    border-radius: 0.3vw;
    background-color: #6495ED;
    /*background-color: #82E0AA;*/
    /*background-color: rgba(187, 143, 206, 1);*/
    font-family: 'Abel', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    transition: all 0.2s;
    padding: 10px 10px;
    margin: 0px 10px;
}

/* https://developers.google.com/identity/branding-guidelines#top_of_page */

.calendarAccessControlCard {
    width:clamp(200px,30vw,300px);
    position:relative;
    height:auto;
    text-align: center;
    border:1px solid #4285F4;
    cursor: pointer;
    background-color: #4285F4;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    /*border-radius: 0.3vw;*/

    font-family: "Roboto";
    font-weight: 500;
    font-size: clamp(8px, 1.6vh, 18px);
    color:white;
   
    transition: all 0.2s;
    /*padding: 10px 10px;*/
    margin: 10px 10px;
}

.calendarAccessControlCard:hover {
    border:1px solid #3367d6;
    background-color: #3367d6;
        
}

.calendarAccessControlCardDisabled {
 pointer-events:none;    /* disables user ability to click */
 background-color: #a1a6b5;
 border:1px solid #a1a6b5;

}
.calendarLogo{
    height: clamp(40px, 3vh, 80px);
    width: clamp(80px, 6vh, 160px);
    
}