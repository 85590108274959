.employeeStorePageWrapper {
    width:100%;
    /* background-color:black;         */
    position:relative;
    min-height: 100vh;
    /*background-color: yellow;*/
    overflow-x: hidden;             /* Hide X scroll bar on page */
}


.employeeStoreRow{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    /* background-color: black; */
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    padding: 50px;                  /* Put some padding between rows so that a card can scale without shifting other rows around */
    min-height: 350px;              /* Fix the height so the row height won't change and shift the other rows */

}


.employeeStoreRow::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
    display:none;
}


.employeeStoreRowLarge{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    /* background-color: black; */
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    padding: 50px;                  /* Put some padding between rows so that a card can scale without shifting other rows around */
    min-height: 450px;              /* Fix the height so the row height won't change and shift the other rows */
}


.employeeStoreRowLarge::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
    display:none;
}

.employeStoreRowNavigator {
    position: absolute;
    height: 100%;
    width: clamp(25px,7vw,75px);
    background-color: rgba(1,1,1,0.0);
    z-index: 99;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    /*opacity: 0;*/
    color:white;
    font-size: clamp(12px,2vh,64px);
    /*font-size:64px;*/
    /*font-weight: 600;*/
    transition: all 0.4s;
    opacity: 0;    /*Hide text, i.e., arrow */
}

.employeStoreRowNavigatorLeft {
    left: 0vw;
    top: 0vw;
}

.employeStoreRowNavigatorRight {
    right: 0vw;
    top: 0vw;
}

.employeStoreRowNavigatorActive{
    /*opacity: 0.5;*/
    background-color: rgba(1,1,1,0.5);
    opacity: 1; /*Show text with full opacity*/
}

/*::-webkit-scrollbar {*/
/*    -webkit-appearance: none;*/
/*    width: 4px;*/
/*}*/
/*::-webkit-scrollbar-thumb {*/
/*    border-radius: 20px;*/
/*    background-color: rgba(0,0,0,.5);*/
/*    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);*/
/*}*/


.employeeStoreConectivityCard {
    width:300px;
    height:100%;
    flex-shrink:0;
    /*min-height: 300px;*/
    /*max-height: 300px;*/
    box-shadow: 0px 8px 16px 0 #BDC9D7;
    border-radius: 16px;
    margin-right: 10px;
    background-color: white;
    overflow: hidden;
    cursor: pointer;
    transition: transform 450ms;    /* Control transition time when scaling in hover */
}

.employeeStoreConectivityCard:hover   {
    transform: scale(1.2);
}

/*Setting card size based on viewing screen so as to be responsive*/
/*Fitting about SIX across the screen */
.employeeStoreConectivityCardLarge {
    /*min-width: 400px;*/
    /*max-width: 400px;*/
    width:400px; /*Just less than 1/4th of the screen */
    height:100%;
    flex-shrink:0;
    /*min-height: 350px;*/
    /*max-height: 350px;*/
    box-shadow: 0px 8px 16px 0 #BDC9D7;
    border-radius: 16px;
    margin-right: 8px;
    background-color: white;
    overflow: hidden;
    cursor: pointer;
    
    transition: transform 450ms;    /* Control transition time when scaling in hover */

}


.employeeStoreConectivityCardLarge:hover   {
    transform: scale(1.2);
}

/* These wrappers adjust the height of the rows as they are the vertical flex boxes */

.employeeStoreConectivityCardInnerVerticalWrapper {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    width:100%;
    min-height:300px;
}

.employeeStoreConectivityCardInnerVerticalWrapperLarge {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:space-between;
    width:100%;
    min-height:380px;
}

.employeeStoreRowTitle {
    position:absolute;
    top:0;
    left:0;
    /*font-size:36px;*/
    font-size: clamp(18px,2vw,36px);
    font-weight:500;
    color:white;
    padding-left:100px;
}

.employeeStoreConectivityCardHeaderWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    min-height: 125px;
}

.employeeStoreConectivityHeaderImageWhite {
    flex: 1;
    /*background: white;*/
    margin: 5px;
    /*border-radius: 25px;*/
    padding:5px;
}

.employeeStoreConectivityHeaderTextWrapper {
    flex: 3;
    /*display: flex;*/
    /*flex-direction:column;*/
    align-items: center;
    justify-content: center;
    padding-right: 5px;

}


.employeeStoreConectivityHeaderTextLarge {
    /*font-size:22px;*/
    font-size: clamp(18px,1.2vw,22px);
    font-weight:500;
    color:white;
}

.employeeStoreConectivityDescriptionWrapper{
    padding: 5px 20px;
    color:#6e6e6f;
    width: 100%;
  background-color: white;
}

.employeeStoreConectivityDescriptionTextLarge {
  /*font-size:18px;*/
  font-size: clamp(12px,1vw,18px);
  color: #6e6e6f;
  font-weight: 500;
  /*font-family: 'Patua One', cursive;*/
  padding: 5px 10px 0px 10px;
  /*background-color: yellow;*/
}

.employeeStoreConectivityHeaderText {
    font-size:18px;
    font-weight:500;
    color:white;
}


.employeeStoreConectivityDescriptionText {
  font-size: clamp(8px,0.8vw,16px);
  /*font-size:12px;*/
  color: #6e6e6f;
  font-weight: 400;
  /*font-family: 'Patua One', cursive;*/
  padding: 5px 10px 0px 10px;
  /*background-color: yellow;*/
}



.employeeStoreConectivityActionsWrapper {
    display:flex;
    align-items: center;
    justify-content: space-around; 
    margin: 0px 0px;    
    flex-wrap:wrap;  
    background:#fffede;
}

.employeeStoreButtonsWrapper {
    display:flex;
    height:0;            /* NOTE HOW THIS STARTS AT ZERO */
    position:absolute;
    bottom:0;
    flex-direction:row;
    align-items:center;
    justify-content: space-around;
    width:100%;
    background-color: rgba(51, 51, 51, 0.2);
    transition-timing-function: ease-in;
    transition: all 0.4s;
    overflow: hidden;
    /*display: hidden;*/
}

.employeeStoreButtonsShow {
    /*display:block;*/
    /*background-color: yellow;*/
    height:50px;
}

.employeeStoreConectivityImageLarge {
    height:64px;
}

.employeeStoreConectivityImage {
    height:48px;
}

/*
.searchBarEmployeeStore {
    position:absolute;
    top:20px;
    right:20px;    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    height: 50px;
    cursor:pointer;
}

.searchButtonEmployeeStore{
    color: #5DADE2;
    font-size: 36px;
    font-weight: 500;

}

.searchIconEmployeeStore {
    padding-left:5px;
    background-color: transparent;
    border: 0;
}

.searchInputEmployeeStore {
    background-color: #111;
    border: 4px solid white;
    padding-left:10px;
    border-radius: clamp(4px,0.4vw,10px);
    color: white;
    transition: all 0.5s;
    opacity: 0;
    width: 0;
    transition-timing-function: ease-in;
    font-weight: 600;
}

.searchInputActiveEmployeeStore {
    width:300px;
    opacity: 1;
    
}
*/
.employeeStoreBlankRow{
    width:100%;
    height:300px;
    background-color: black;
}

/*Customization, if desired https://thewebdev.info/2020/08/01/react-bootstrap%E2%80%8A-%E2%80%8Adropdown-customization/ */

.customerFilterWrapperEmployeeStore {
    position:absolute;
    top:80px;
    right:20px;    

}

.employeeStoreCustomerLogoWrapper {
    position:absolute;
    top:10px;
    left:30px;        /* Leaving room for the admin menu button */
    width:clamp(200px, 12vw,400px);
    height:clamp(25px, 3vw,100px);  /*Note the 4/1 aspect ratio */
    z-index: 1;
  
    /*min-height: 200px;*/
     /*width:100px;*/
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: flex-end;*/
    /*height: 30px;*/
    /*background-color: yellow;*/
    /*cursor:pointer;*/
    /*color:green;   */
}    


.employeeStoreCustomerLogoImageWrapper {
  position: relative;
  height:clamp(25px, 3vw,100px);  

  /*width: 300px; /*Leaving space here in case logo is portrait. Will only scale to height setting regardless since width is AUTO in the image div */
  /*height:100px;*/
   vertical-align: middle;
  /* overflow: hidden; */
  /*border-radius: 50%;*/
  margin: 0px 0px;
  text-align:center;
  /*background-color: pink;*/
}

.employeeStoreCenterLogo{
   position: absolute;
   left: 0;      /* Left justify the LOGO */
   top: 50%;     /* Center vertically in the space */
   max-width: 100%; 
   max-height: 100%;
  object-fit: scale-down;   /* Scale down any / both dimension that exceeds the allocated space to fit the image */
  -webkit-transform: translate(0,-50%);
      -ms-transform: translate(0,-50%);
          transform: translate(0,-50%);
}


.employeeStoreCustomerName {
    font-size:36px;
    font-weight:500;
    color:white;
    padding-left:100px;
}

