/*
  PROPRIETARY AND CONFIDENTIAL

  PROPERTY OF CONECTERE - ALL RIGHT, TITLE & INTEREST
  Copyright 2020-2024.  All Rights Reserved
*/

/*
.userUpcomingScheduleOuterWrapper{
    display:flex;
    flex-direction: column;
    align-items:center;
    justify-content:flex-start;
    position:relative;
    width: 100vw;
    height: 100%;
    min-height: 90vh;
    border-radius: clamp(4px,0.4vw,10px);
    padding: 0px 60px;   
    color: black;
    z-index: 0;
}


.userUpcomingScheduleInnerWrapper{
    position:relative;
    max-width: 1400px;
    background-color: white;
    width: 100%;
    height: 100%;
    border-radius: clamp(4px,0.4vw,10px);
    padding-bottom: 100px;
}

*/

.addToCalendardButton {
    /*height: 60px;*/
    /*width: 180px;*/
    border:none;
    cursor: pointer;
    color:gold;
    border-radius: 0.3vw;
    background-color: #6495ED;
    /*background-color: #82E0AA;*/
    /*background-color: rgba(187, 143, 206, 1);*/
    font-family: 'Abel', sans-serif;
    font-weight: 400;
    font-size: 1.4rem;
    transition: all 0.2s;
    padding: 10px 10px;
    margin: 0px 10px;
}


.addToCalendarURLContainer {
    padding: 10px;
    border-radius: clamp(4px,0.4vw,10px);
    background-color: #f7f7f7;
}

.addToCalendardButton:hover {
    transform: scale(1.15);   
    font-weight: 700;
}

.copyCalendarURLButtonContainer {
  margin-top: 20px;
    display: inline-block;
}

.copyCalendarURLButtonContainer .copyURLToolTipText{
  visibility: hidden;
  width: 200px;
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  top: 0px;
  left:50%;
  /*margin-left: -75px;*/
  opacity: 0;
  transition: opacity 0.3s;  
  transform: translate(-50%, 0); /*Shift by 50% the width of the ToolTip so that it is centered */  
}

.copyCalendarURLButtonContainer:hover .copyURLToolTipText{
  visibility: visible;
  opacity: 1;
}

/* This puts a cool arrow head on the box */
.copyCalendarURLButtonContainer .copyURLToolTipText::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #222 transparent transparent transparent;
}

.copyCalendarURLButtonContainer .addToCalendarButtonText {
    font-size:16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    padding:10px 10px;
    display: inline-block; /*Display inline and maintain block characteristics.*/
    vertical-align: central; /*Makes sure all the divs are correctly aligned.*/
    white-space: normal; /*Prevents child elements from inheriting nowrap.*/
    color:white;
    
}

.copyCalendarURLButtonContainer:hover .addToCalendarButtonText {
    transform: scale(1.15);   
    font-weight: 700;    
}

.addToCalendarText {
    font-size:16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    color:#409cf7;
    padding:10px 10px;
}

.addToCalendarIcon {
  font-size:1rem;
  height: 6rem;
  padding: 1rem;
  /*margin-bottom: .3em;*/
  /*margin-right: .8em;*/
}

.closeSettingsButton {
    cursor: pointer;
    background:#BB8FCE;
    color:#FFF;
    outline: none;
    border: none;
    /*font-size: 0.8rem;*/
    /*font-weight: 400;*/
    border-radius: 0.3vw;
    padding: 0.2rem 0.5rem;
    /*margin: 0.5rem;*/
    /*transition: all 0.3s;*/
    /*background-color: rgba(51, 51, 51, 0.5);*/
}

.calendarEventHoverOuter{
    position:fixed;    /* Position relative to the ViewPort since we are sensing mouse positions */
    top:0;
    left:0;
    transform:translate(-50%, +30px);
    width: 400px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: 4px;
    /*margin: 25px 5px 5px 5px;*/
    background-color: white;
    /*overflow: hidden;*/
    min-height: 2.0rem;
    cursor: default;
    /*transition: transform 350ms;    /* Control transition time when scaling in hover */
    z-index: 9999;

    /* Default Fonts for any undefined region including instructions */
    font-family: 'Sora', sans-serif;
    font-weight: 400;    
    color: #6e6e6f;
    font-size: 0.8rem;    
}


.calendarEventHoverTitleText {
    font-size: 0.9rem;
    font-weight:500;
    color:#6495ED;
}

.calendarEventHoverAnchor{
    position:absolute;
    top:0;
    left:50%;
    transform:translate(0,-50%);
    
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
    
}

.calendarRepeatEventAnchor{
    position:absolute;
    top:0;
    right:0;
    /*transform:translate(-100%,+100%);*/
    
    /*box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;*/
    
}



