.menuDataGridWrapper {
    /*height: 1200px;*/
    height: clamp(600px, 90vw, 1200px);
    /* background-color: white; */
    width:100%;
    overflow:hidden;
    max-width: 100%;
    /*width: 1600px;*/
    /*border-radius: 20px;*/
    

}

.menuDataGridTextField{
    display: flex;
    flex-direction:row;
    align-items: center;
    justify-content:center;
    width:100%;
}


.menuDataGridListImageWrapper{
    display: flex;
    align-items: center;
    justify-content:center;
    width:200px;
}

.menuDataGridListImage{
    flex:1;
    /*margin-right: 10px;*/
}

.menuDataGridListButton{
    /*margin-right: 10px;*/
    flex:1;
}


.dataGridWrapperTop{
   /* width: 1600px;        /*setting width so ADD button position can be set on top of grid */
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content:center;*/
    margin: clamp(2px,2vw,10px) auto;
    position:relative;
    /*padding-bottom: 20px;*/

}
.dataGridAddButton {
    position: absolute;
    top: 8px;
    right: 10px;
}

.dataGridAddButtonStyle {
    border:none;
    cursor: pointer;
    color:#fff;
    outline: none;
    border-radius: 0.2vw;
    padding: clamp(2px,0.5vw,5px);
    /*margin-top: 1rem;*/
    background-color: var(--TEAM-purple);
    /*background-color: rgba(187, 143, 206, 1);*/
    /*font-family: 'Abel', sans-serif;*/
    /* font-weight: 600;
    font-size: 1.4rem; */
    transition: all 0.3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

 
    
}

.dataGridAddButtonStyle:hover {
    transform: scale(1.1);
    font-weight: 700;
}

.dataGridPdfButton {
    position: relative;
    width: 40px;
    height: 40px;
    vertical-align: middle;
    overflow: hidden;
    border-radius: 50%;
    /*border: 1px solid grey;*/
    /*margin: 0px 1px;*/
    text-align:center;
    background-color:white;
    cursor:pointer;
    
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
     
}

.dataGridPdfButtonImageCenterPortrait {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 65%;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}


@media only screen and (max-width: 1520px){  

}

@media only screen and (max-width: 1440px){  

}

@media only screen and (max-width: 1325px){  

}

@media only screen and (max-width: 1200px){  

}

@media only screen and (max-width: 1024px){  

}

@media only screen and (max-width: 993px){  

}

@media only screen and (max-width: 768px){  

}


/* PHONES */

@media  only screen and (max-width: 650px) {
}


@media only screen and (max-width: 500px){  

}

@media only screen and (max-width: 390px){  

}

