.chatCardContainer {
  padding: clamp(12px, 2vw, 24px);
  border-radius: clamp(6px, 1vw, 12px);
  box-shadow: 0px 1px 25px -9px #4D106326;
  /* box-shadow: 0px 0px clamp(6px, 1vw, 12px) rgba(0, 0, 0, 0.1); */
  /* margin: clamp(10px, 4vw, 20px) auto; */
  /* max-width: clamp(300px, 80vw, 600px); */
}

.darkMode .chatCardContainer {
  box-shadow: 0px 1px 25px -9px rgba(255, 255, 255, 0.1);
}
  .incDecCard {
    height: 100%;
    margin: 0 clamp(8px, 1.5vw, 16px) clamp(8px, 1.5vw, 16px);
    padding: clamp(4px, 1vw, 8px);
    border-radius: clamp(6px, 1vw, 12px);
  }
  
  .incDecBtn {
    font-size: clamp(14px, 2vw, 18px);
    padding: clamp(6px, 1vw, 10px);
    border-radius: clamp(3px, 0.5vw, 6px);
  }
  
  .incDecCount {
    color: var(--SOCIAL-green);
    margin: clamp(6px, 1vw, 10px) 0;
  }
  
  .usernameSpan {
    color: var(--davy-grey);
    margin-right: clamp(6px, 1vw, 12px);
  }
  
  .timeSpan {
    color: var(--dust-grey);
    font-size: clamp(12px, 1.5vw, 16px);
  }
  
  .replyBtn {
    background-color: transparent;
  }
  
  .messageSpan {
    line-height: clamp(14px, 1.5vw, 18px);
    font-size: clamp(14px, 1.5vw, 16px);
  }
  