.adminEmployeeStore{
    height: 200px;
    width:200px;
    display:flex;
    flex-direction:column;
     align-items: center;
     justify-content:center;
     /*background-color: yellow;*/
}

.adminEmployeeImportLoadInstructions {
    height:300px;
    width:600px;
    display:flex;
    flex-direction:column;
     align-items: flex-start;
     justify-content:center;
     /*background-color: pink;*/
     font-size:1.0rem;
     font-weight: 500;
    color:#409cf7;
    padding: 10px 20px;
}

.adminEmployeeImportLoadedIndicator { 
     width:50px;
     height:50px;
     border-radius:50%;
     font-size:1.5rem;
     font-weight: 700;
     color:white;
     background-color: #82E0AA; 
     display:flex;
     align-items: center;
     justify-content:center;
}

.adminEmployeeImportNumLoaded { 
     width:100px;
     height:100px;
     border-radius:50%;
     font-size:1.5rem;
     font-weight: 700;
     color:white;
     background-color: #BB8FCE; 
     display:flex;
     align-items: center;
     justify-content:center;
     /*margin: 20px;*/
}
    
.adminEmployeeImportText {
    width:100%;
    font-size:0.9rem;
    font-weight: 700;
    color:#409cf7;
    /*background-color: green;*/
    text-align: left;
    padding:5px 5px;
}

.adminEmployeeImportTableHeader {
    border: 1px solid #409cf7;
    padding: 10px 10px;
}

.adminEmployeeImportTableData{
    border: 1px solid #409cf7;
    padding: 10px 10px;
    
}

