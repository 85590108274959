.adminEmployeeStoreFeaturedMarker{
    height: 20px;
    width:50px;
    color:white;
    border-radius:10px;
    font-size: 0.6rem;
    font-weight: 400;
     background-color: #BB8FCE;
     display:flex;
     align-items:center;
     justify-content:center;
     margin-left: 10px;
}
    
.productImageCenterPortrait {
  position: absolute;
  left: 50%;
  top: 50%;
  height: auto;
  width: 100%;
  -webkit-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
          transform: translate(-50%,-50%);
}


.productImageXLarge {
    position: relative;
    width: 250px;
    height: 250px;
    vertical-align: middle;  
    overflow: hidden;
    /*border-radius: 50%;*/
    margin: 5px 5px;
    /*padding: 20px;*/
    text-align:center;
}

.employeeStoreDefaultBadge {
    margin-left: 10px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color:var(--TEAM-purple);
    font-size:0.7rem;
    /*width:55px;*/
    height:21px;
    border-radius: clamp(4px,0.4vw,10px);
    border: 2px solid var(--TEAM-purple);
    text-align: center;
    vertical-align: middle;
    padding-Left:10px;
    padding-Right:10px;
    /*transform:translate(0,-10px);*/
}

.employeeStoreCharityBadge {
    margin-left: 10px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--CS-green);
    font-size:0.7rem;
    /*width:55px;*/
    height:21px;
    border-radius: clamp(4px,0.4vw,10px);
    border: 2px solid var(--CS-green);
    text-align: center;
    vertical-align: middle;
    padding-Left:10px;
    padding-Right:10px;
    /*transform:translate(0,-10px);*/
}

.employeeStoreSpotlightsRequiredBadge {
    margin-left: 10px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color: var(--CS-green);
    font-size:0.7rem;
    /*width:55px;*/
    height:21px;
    border-radius: clamp(4px,0.4vw,10px);
    border: 2px solid var(--CS-green);
    text-align: center;
    vertical-align: middle;
    /*transform:translate(0,-10px);*/
    
    padding-Left:10px;
    padding-Right:10px;

}

.employeeStoreSponsoredBadge {
    margin-left: 10px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    color:#5DADE2;
    font-size:0.6rem;
    width:120px;
    height:21px;
    border-radius: clamp(4px,0.4vw,10px);
    border: 2px solid #5DADE2;
    text-align: center;
    vertical-align: middle;
    padding-Left:10px;
    padding-Right:10px;
    /*transform:translate(0,-10px);*/
}

.employeeStoreCustomerSpecificBadge {
    margin-left: 10px;
    margin-right: 10px;
    display:flex;
    align-items: center;
    justify-content: center;
    background-color: #82E0AA;
    color:white;
    font-size:0.6rem;
    width:60px;
    height:20px;
    border-radius: clamp(4px,0.4vw,10px);
    padding-Left:10px;
    padding-Right:10px;
    /*transform:translate(0,-10px);*/
}