.SpinnerNoBackgroundContainer {
    position: fixed;
    top:0px;
    left:0px;
    height:100vw;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 40vh;             /* Bring inner content down from top */
    z-index: 999999;

    /*background:red;*/
}


.SpinnerInnerContainer {
    position:relative;
    /*background:white;*/
    border-radius: clamp(4px,0.4vw,10px);
    /*width: 200px;*/
    /*height: 75px;*/
    width: 50px;
    height:50px;
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    z-index: 9999;


    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/

    

}

.SpinnerNoBackgroundOpaqueContainer {
    z-index: 9998;
    position:absolute;
    background-color: black;
    top:0px;
    left:0px;
    width:100%;
    height:100%;
    opacity: 0.1;
}



