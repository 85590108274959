.communityPadPageWrapper {
    width:100%;
    /*background-color:#111;        /* REPLACED FOR DARK MODE - slightly off black to provide face to blakk */
    position:relative;
    min-height: 100vh;
    overflow-x: hidden;             /* Hide X scroll bar on page */
}


.communityRow{
    position:relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    background-color: black;
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    padding: 50px;                  /* Put some padding between rows so that a card can scale without shifting other rows around */
    min-height: 350px;              /* Fix the height so the row height won't change and shift the other rows */
}


.communityRow::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
    display:none;
}


.communityRowLarge{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    background-color: black;
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    padding: 50px;                  /* Put some padding between rows so that a card can scale without shifting other rows around */
    min-height: 450px;              /* Fix the height so the row height won't change and shift the other rows */
}


.communityRowLarge::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
    display:none;
}

.communityRowNavigator {
    position: absolute;
    height: 100%;
    width: 75px;
    background-color: rgba(1,1,1,0.0);
    z-index: 99;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    /*opacity: 0;*/
    color:white;
    font-size:64px;
    /*font-weight: 600;*/
    transition: all 0.4s;
    opacity: 0;    /*Hide text, i.e., arrow */
}

.communityRowNavigatorLeft {
    left: 0vw;
    top: 0vw;
}

.communityRowNavigatorRight {
    right: 0vw;
    top: 0vw;
}

.communityRowNavigatorActive{
    /*opacity: 0.5;*/
    background-color: rgba(1,1,1,0.5);
    opacity: 1; /*Show text with full opacity*/
}

/*::-webkit-scrollbar {*/
/*    -webkit-appearance: none;*/
/*    width: 4px;*/
/*}*/
/*::-webkit-scrollbar-thumb {*/
/*    border-radius: 20px;*/
/*    background-color: rgba(0,0,0,.5);*/
/*    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);*/
/*}*/


.communityBannerButton {
    cursor: pointer;
    color:#fff;
    outline: none;
    border: none;
    font-weight: 700;
    border-radius: 0.2vw;
    padding-left: 2rem;
    padding-right: 2rem;
    /*margin-top: 1rem;*/
    padding-top: 0.5rem;
    background-color: rgba(51, 51, 51, 0.5);
    padding-bottom: 0.5rem;
}

.communityBannerButton:hover {
    color: #000;
    background-color: #e6e6e6;
    transition: all 0.3s;
}


.communityButtonHoverText {
  visibility: hidden;
  width: auto;
  color: white;
  font-size:clamp(6px,1vw,10px);
  line-height:clamp(6px,1vw,10px);
  /*background-color: white;*/
  text-align: center;
  /*padding: 5px;*/
  /*border-radius: 6px;*/
  position: absolute;
  bottom:0;                             /* Anchor at the bottom of the container */
  left:50%;                             /* Start of object is at center bottom, until we translate */
  transform: translate(-50%, 100%);     /* Move the hover text down 100% of its own height; shift left 1/2 its width so as to center under button */
  z-index: 99999;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}


/*Community Heading Banner */

.conectereCommunityBannerHeader {
    margin-top: 15px;
    color:#BB8FCE;
    /* font-size: clamp(10px,2vw,20px); */
    /* font-weight: 500; */
    /* height:100px; */
    width:auto;
    /*display:flex;*/
    /*flex-direction:column;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    /* background-color: #111; */
    /*background-color: pink;*/

    /*border-bottom: 0.2vw solid #BB8FCE;*/
    /*border-radius: 20px;*/
    
    /*background: linear-gradient(to right, #BB8FCE 50%, #FFFFFF 100%);*/
    /*background-position: 0 100%;*/
    /*background-size: 100% 6px;*/
    /*background-repeat: repeat-x;       */
}

.conectereCommunityBannerInnerContainer {
    /*background-color:yellow;*/
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:center;
    width:100%;
    height: 100%;
}


.communityCommunityBannerFootnote {
    /* padding-bottom: 0.5rem; */
    color:#BB8FCE;
    /*background-color: yellow;*/
}


/* Conectivity Banner */

.communitySearchBar {
    position:absolute;
    top:0px;
    right:20px;    
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor:pointer;
}

.communitySearchButton{
    color: #5DADE2;
    font-size: 36px;
    font-weight: 500;
}

.communitySearchIcon {
    padding-left:5px;
    background-color: transparent;
    border: 0;
}

.communitySearchInput {
    background-color: #111;
    border: 1px solid white;
    padding-left:10px;
    border-radius: clamp(4px,0.4vw,10px);
    color: white;
    transition: all 0.5s;
    opacity: 0;
    width: 0;
    transition-timing-function: ease-in;
}

.communitySearchInputActive {
    width:clamp(100px, 15vw, 180px);
    opacity: 1;
    
}

.communityPadBlankRow{
    width:100%;
    height:300px;
    background-color: black;
}

.alreadyDownloadedConectivityGraphic {
    position:absolute;
    top: 0px;
    right: 0px;
    transform: translate(0,-30%);
    z-index: 1;
}

.alreadyDownloadedConectivityGraphic2 {
    position:absolute;
    top: 0px;
    right: 0px;
    transform: translate(-100%,0);
    font-size: 20px;
    line-height: 20px;
    z-index: 1;
}


.communityConectivityMenuButtonAnchor {
    position: absolute;
    top: 50%;                        /* 1/2 way down the row */
    right: 0;
    transform: translate(0, -50%);    /*Shift up to center the icon in the row */
   
    /*background-color: red;*/
}

.conectivityInnerMenuIconContainer {
    /*background-color: red;*/
    position:absolute;
    top:0px;
    right:0px;
}

.conectivityInnerMenuContainer {

    background-color: white;
    position:absolute;
    top:40px;
    right:10px;
    z-index: 1;
    overflow:hidden; /*This will hide the elements that overflow when the height is ZERO */
    transition: all 0.3s;
    /*width:280px;*/
    transition-timing-function: ease-in;
    border: 1px solid #36454f;
    border-radius: clamp(4px,0.4vw,10px);
    color: #36454f;
    /*transform:translate(0,-100%);*/
}

