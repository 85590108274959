/* Network Graph CSS */

.conectivityChart {
    /*display: flex;*/
    /*flex-direction: column;*/
    /*background:yellow;*/
    /*align-items: center;*/
    /*justify-content: center;*/
    
    /*margin: 0px 20px;*/
    /*padding: 0px 0px 10px 0px; */
    /*border-radius: clamp(4px,0.4vw,10px);*/
    /*cursor:pointer;*/
    /*width: 600px;*/
    /*height: 650px;*/
    
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}


.NetworkClusterControlWrapper {
    /*margin: 0px 20px;*/
    padding: 0px 0px 0px 10px; 
    border-radius: clamp(4px,0.4vw,10px);
    /*cursor:pointer;*/
    width: clamp(400px, 70%, 1200px);
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
   
}

.NetworkGraphLegendScaleQ1 {
    background-color:  #f0676e;
    width: 100%;
    height: 15px;
}
.NetworkGraphLegendScaleQ2 {
    background-color: #f5ae20;
    width: 100%;
    height: 15px;
}
.NetworkGraphLegendScaleQ3 {
    background-color: #f5df3d; 
    width: 100%;
    height: 15px;
}
.NetworkGraphLegendScaleQ4 {
    background-color: #92ed61; 
    width: 100%;
    height: 15px;
}
.NetworkGraphLegendScaleQ5 {
    /*background-color: #92ed61; */
    /*width: 75px;*/
    height: 15px;
}

.NetworkGraphLegendLabel {
    /*padding: 0px 0px 10px 0px; */
    
}

.NetworkGraphSlider {
    width: 80%;
    height: 40px;
    padding: 10px 0px 0px 0px; 
}

.NetworkClusterControlTitle {
  font-family: 'Abel', sans-serif;
  font-size: large;
  font-weight: 600; 

}

.NetworkCanvasSizeSliderX {
    width: 120px;
    height: 20px;
    padding: 0px 0px 0px 10px; 
}

.NetworkCanvasSizeSliderY {
    width: 20px;
    height: 80px;
    padding: 0px 0px 10px 0px; 

}

.NetworkCanvasSizeWrapper {
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    /*cursor:pointer;*/
    width: 160px;
    height: 135px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    /*background-color: #6495ED;*/

     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
   
}

.NetworkCanvasSizeTitle {
  font-family: 'Abel', sans-serif;
  font-size: large;
  font-weight: 600; 

}


.TimeAnimationControlWrapper {
    /*margin: 0px 20px;*/
    padding: 0px 0px 0px 10px; 
    border-radius: clamp(4px,0.4vw,10px);
    /*cursor:pointer;*/
    width: 700px;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
   
}

.NetworkGraphSelectWrapper {
    width:600px;
    height:     auto;
    padding:    5px;
    /*margin:     20px 0px;*/
    /*border-radius: 20px;*/
    /*border: 1px solid blue;*/
}