.menuHeaderWrapper {
    position:fixed;
    top:0;
    left:0;
    background-color: rgb(0,0,0,0.4);
    height:100vh;
    width:100vw;
}

.menuHeaderProfile {
    position:absolute;
    top:clamp(40px,8vh,80px);
    right:clamp(10px,2vh,20px);
    background-color: white;
    padding: clamp(4px,1vw,10px);
}

