.radarChart {
    display: flex;
    flex-direction: column;
    background-color:white;
    align-items: center;
    justify-content: center;
    
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    /*cursor:pointer;*/
    /*width: 600px;*/
    /*height: 650px;*/
    
     box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
   
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

.radarChartContainer {
    position:relative;
    height:310px;
    width:400px; 
}

.radarChartTitle {
    font-size:1.2rem;
    font-weight: 600;
    color: #6495ED
    /*margin-bottom: 20px;*/
}

.radarChartTitleTarget {
    font-size:1.2rem;
    font-weight: 600;
}


.radarChartImage {
    width: 40px;
    height: 40px;
    background-color:white;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

