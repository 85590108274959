.LoadingNoBackgroundContainer {
  position: fixed;
  top: 0px;
  left: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding-top: 30vh; */
  z-index: 999999;
}

.LoadingInnerContainer {
  position: relative;
  border-radius: clamp(4px, 0.4vw, 10px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.LoadingNoBackgroundOpaqueContainer {
  z-index: 9998;
  position: absolute;
  background-color: white;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.LoadingAnimationSize {
  height: 150px;
  width: 150px;
}

.LoadingContectereImg {
  height: 200px;
  width: 200px;
}

.gradient-line {
  display: flex;
  width: 100%;
  height: 4px;
  background: linear-gradient(90deg, #6495ed, #bb8fce, #82e0aa, #f7dc6f);
  opacity: 0.7;
}

.loading-gradient-line {
  top: 0px;
  z-index: 9999;
  position: absolute;
  height: 10px;
}

.ripple-effect-outer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999      ;
  width: 20px;
  height: 20px;
  background-color: #bb8fce; 
  border-radius: 50%;
  animation: expandRippleOuter 0.75s ease-out forwards;
}

@keyframes expandRippleOuter {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(100);
    opacity: 0;
  }
}

.ripple-effect-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999      ;
  width: 20px;
  height: 20px;
  background-color: #bb8fce; 
  border-radius: 50%;
  animation: expandRippleInner 0.75s ease-out forwards;
  /* display: none; */
}

@keyframes expandRippleInner {
  0% {
    transform: scale(100);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
    height: 0;
    width: 0;
  }
}
