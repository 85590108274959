
/*Community Heading Banner */

.teamConectivityBannerHeader {
    margin-top: 30px;
    color:#BB8FCE;
    font-size: 2.0rem;
    font-weight: 500;
    height:100px;
    width:60vw;
    max-width: 1400px;
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    background-color: #111;
    /*background-color: pink;*/

    /*border-bottom: 0.2vw solid #BB8FCE;*/
    /*border-radius: 20px;*/
    
    background: linear-gradient(to right, #BB8FCE 50%, #FFFFFF 100%);
    background-position: 0 100%;
    background-size: 100% 6px;
    background-repeat: repeat-x;       
}

.teamConectivityBannerInnerContainer {
    /*background-color:yellow;*/
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:center;
    width:100%;
    height: 100%;
}

.teamConectivityBannerTitle {
    font-size:3rem;
    font-weight: 800;
    /*padding-bottom: 0.3rem;*/
}

.teamConectivityBannerFootnote {
    font-size:0.9rem;
    font-weight: 800;
    padding-bottom: 0.5rem;
    color:#BB8FCE;
    /*background-color: yellow;*/
}

.teamConectivityChartWrapper {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: 50px 50px;
}

.teamConectivityTeamSelectWrapper {
    width:600px;
    height:     auto;
    /*padding:    20px;*/
    /*margin:     20px 0px;*/
    /*border-radius: 20px;*/
    /*border: 1px solid blue;*/
}

.teamConectivityChartTitle {
    font-size:1.7rem;
    font-weight: 500;
    color: #6495ED;
}

.teamConectivityLegendWrapper {
    /*border: 1px solid red;*/
    /*background-color: yellow;*/
    max-width: 1200px;
    padding-bottom: 50px
}

.teamConectivityLegendItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left:20px;
    padding-right: 20px;
    /*background-color: yellow;*/
}

.teamConectivityLegendImage {
    height: 32px;
    /*width: 20px;*/
}

.teamConectivityLegendText {
    font-size: 1.0rem;
    font-weight: 400;
    text-align:left;
    padding-left: 5px;
}