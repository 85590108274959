.logoutModalContainer {
    position: absolute;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.ModalBgContainer {
    z-index: 99;
    position:fixed;
    background-color: rgba(0, 0, 0, 0.6);
    top:0px;
    left:0px;
    width:100vw;
    min-height:100%;
    cursor:pointer;
}

.logoutModalOuterCard {
    z-index: 100;
    position:absolute;
    width:auto;
    height:auto;
    opacity: 1;
    display: flex;
    flex-direction: column;
    align-items:center;
    justify-content:center;
    border-radius: clamp(8px,0.8vw,15px);
    background-color: var(--white);
    padding: clamp(15px, 5vw, 30px);
    padding-top: clamp(40px, 10vh, 55px);
    max-width:clamp(600px,40vw,1000px);
    min-width:clamp(150px,25vw,450px);
}

