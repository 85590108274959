
.spotlightCardOuterWrapper {
   /*background-color: yellow;*/
   /*padding-top:20px;*/
   width:500px;
   border-radius: clamp(4px,0.4vw,10px);
   padding:clamp(4px,0.4vw,6px) clamp(4px,0.4vw,6px);
   cursor: default;
   position: relative;
   
}

.spotlightOuterWrapperIsSmall{
   width:auto;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.spotlightOuterWrapperIsFull{
   /* width:35vw; */
   max-width:clamp(200px,90vw,600px);

   /* max-width:600px; */
   /* min-width: 500px; */
   min-width:clamp(200px,50vw,500px);
   /* box-shadow: 0px 8px 16px 0 #BDC9D7; */
}

.spotlightCardOuterWrapper:hover   {
   /*transform: scale(1.15);*/
   /*z-index: 1;*/
   /*transition: transform 450ms;    /* Control transition time when scaling in hover */
}

.spotlightCard {
   width:clamp(300px,50vw,400px);
   height:100%;
   flex-shrink:0;
   min-height: clamp(200px,20vh,260px);
   /*max-height: 300px;*/
   border-radius: clamp(4px,0.5vw,8px);
   /*margin-right: 40px;*/
   overflow: hidden;
   cursor: pointer;
   position: relative;
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:flex-start;

}

/* Dynamically add FLEX attribute when in isFull mode */
.spotlightCardIsFull {
   width:100%;
   cursor: default;

   /*background-color: red;*/
   /*display:flex;*/
   /*background-color: yellow;*/
   /*cursor:auto;*/
   /*pointer-events: none;        /*Disable the HOVER */
}

.spotlightCardIsSmall {
   width:300px;
   /*border-radius: 8px;*/
   /*height:120px;*/
   min-height: 100px;
   box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.spotlightCardHeader{
   padding-top:clamp(2px,1vw,10px);
}
.spotlightCardHeaderText{
   font-family: 'Inter', sans-serif;
   font-size: 2.8rem;
   line-height: 2.9rem ;
   padding-left: clamp(4px,1vw,8px);
   padding-right: clamp(4px,1vw,8px);
   color: gold;
   font-weight: 500;   
}

.spotlightCardHeaderTextSmall {
   font-size: 1.6rem ;
   line-height: 1.8rem ;
   font-weight: 500;
}

.spotlightCardHeaderLightLeft {
   transform: translate(0px,0%) rotate(+130deg) ;
   color: gold;
    /* border-radius:50%;
    position:absolute; 
    left:-35px;
    bottom:0;
    height:40px;
    width:40px;
    transition: all 1s ease-in; */
}

.spotlightCardHeaderLightRight {
   transform:translate(0px,0%) rotate(-128deg);
   color: gold;
   /* border-radius:50%;
   position:absolute; 
   right:-35px;
   bottom:0;
   height:40px;
   width:40px;
   background-color:black;
   transition: all 1s ease-in; */
}


.spotlightCardInnerVerticalWrapper {
   width:100%;
   height: 100%;
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:flex-start;
   /*min-height:300px;*/
   /*background-color: violet;*/
}


.spotlightSenderName {
   text-align: center;
   padding-left: clamp(4px,0.5vw,10px); 
   /* font-size:10pt;  */
   color:darkgray; 
   /* font-weight:400;     */
}

.spotlightSenderNameIsSmall {
   padding-left: 6px; 
   /* font-size:6pt;  */
   color:darkgray; 
   /* font-weight:400;     */
}

.spotlightMessagePreview {
   display:flex;
   flex-direction:row;
   padding:clamp(4px, 0.5vh,10px);
   /*padding: 10px 10px 40px 10px;  /* Add padding at the bottom for the awards */
   width:100%;
   min-height:clamp(80px,10vh,120px);
   align-items:center;
   justify-content:flex-start;
   
   /*background-color: pink;*/

}

.spotlightMessagePreviewIsSmall {
   min-height:clamp(40px,6vh,80px);
   padding: clamp(2px,0.5vw,5px);
}

.spotlightSenderAvatarPortion {
   flex:1;
   /*background-color: yellow;*/
   display:flex;
   flex-direction:column;
   align-items:center;
   justify-content:center;
   max-width:clamp(60px,6vw,100px);
   border-right: 1px solid lightgrey;
}
.spotlightMessagePortion {
   flex:3;
   /*border-radius:20px;*/
   /*border:1px solid #5DADE2;*/
   display:flex;
   justify-content:flex-start;
   align-items:flex-start;
   text-align: left;
   vertical-align:middle;
   padding-left: clamp(2px,0.3vw,5px);
   /*height:auto;*/
   /*min-height: 75px;*/
   /*background-color: #fafafa; */
   /*background-color: lightblue;*/
   max-height: 165px;
   overflow: hidden;                     /* Hide HTML that goes outside of our allocated DIV */

   /* font-size: 1.0rem;
   line-height: 1.0rem;
   font-weight: 500; */
   color:var(--dark-grey);
}

.spotlightMessagePortionIsSmall {
   max-height: 100px;
} 

.showFullSpotlightMessage {
   max-height: none;
   /*border: 2px solid #5DADE2; */
   border-radius:clamp(4px,1vw,8px); 
   min-height: clamp(30px,10vh,70px);
   padding:clamp(4px,0.5vw,10px);
   background-color: #fafafa;
   margin-left: clamp(4px,0.6vw,20px);
   margin-right: clamp(4px,0.6vw,20px);
}

.spotlightMessagePortionDarkMode {
   color:white;
   background-color: var(--black);
}

.spotlightPreviewSenderWrapper {
   width:100%;
   height:30px;
   display:flex;
   /*background-color: pink;*/
}

.spotlightBottomRowWrapper {
   display:flex;
   flex-direction:row;
   padding: 4px 7px;  /* Add padding at the bottom for the awards */
   width:100%;
   min-height:40px;
   align-items:center;
   justify-content:space-between;
   /*background-color: pink;*/
   font-size:1.0rem;
   font-weight: 600;
   color:#6495ED

}

.spotlightBottomRowWrapperIsSmall {
   font-size:0.7rem;
    min-height:10px;
    padding: 2px 7px;
    font-weight: 500;
    color: #a1a6b5;
}

.spotlightPreviewAwardsWrapper {
   flex:1;
   display:flex;
   align-items: center;
   justify-content: flex-end; 
   /*margin: 0px 50px;    */
   flex-wrap:wrap;  
   /*background:#fffede;*/
   /*height:40px;*/
   /*width:150px;*/
   /*position: absolute;*/
   /*bottom:-4px;*/
   /*right:20px;*/
   color:#6495ED;
}

.spotlightPreviewCommentsWrapper {
   display:flex;
   align-items: center;
   justify-content: flex-end; 
   /*margin: 0px 50px;    */
   flex-wrap:wrap;  
   /*background:#fffede;*/
   height:40px;
   width:150px;
   position: absolute;
   bottom:-4px;
   left:20px;
   color:#6495ED;
}


/*                            */
/*     MEDIA ADJUSTMENTS      */
/*                            */

@media  only screen and (max-width: 1440px) {

   .spotlightCardHeaderText {
      font-size: 2.7rem !important;
      line-height: 2.8rem !important;
      }        
   .spotlightCardHeaderTextSmall {
      font-size: 1.5rem !important;
      line-height: 1.7rem !important;
   }
}

@media only screen and (max-width: 1325px){

   .spotlightCardHeaderText {
      font-size: 2.7rem !important;
      line-height: 2.8rem !important;
   }         
   .spotlightCardHeaderTextSmall {
      font-size: 1.5rem !important;
      line-height: 1.7rem !important;
  }
}

@media only screen and (max-width: 1200px){

   .spotlightCardHeaderText {
      font-size: 2.5rem !important;
      line-height: 2.6rem !important;
   }      
   .spotlightCardHeaderTextSmall {
      font-size: 1.3rem !important;
      line-height: 1.5rem !important;
    }   

}

@media  only screen and (max-width: 1024px) {


   .spotlightCardIsSmall {
      width:200px !important;
   }
   .spotlightCardHeaderText {
      font-size: 2.2rem !important;
      line-height: 2.3rem !important;
   }               
   .spotlightCardHeaderTextSmall {
      font-size: 1.2rem !important;
      line-height: 1.25rem !important;
   }  
}

@media only screen and (max-width: 993px){
   .spotlightCardHeaderText {
      font-size: 2.0rem !important;
      line-height: 2.1rem !important;
   }
   .spotlightCardHeaderTextSmall {
      font-size: 1.1rem !important;
      line-height: 1.15rem !important;
    } 

}

/* TABLET */

@media  only screen and (max-width: 768px) {



   .spotlightCardIsSmall {
      width:150px !important;
   }
            
   .spotlightCardHeaderText {
      font-size: 1.8rem !important;
      line-height: 1.9rem !important;
   }  
   .spotlightCardHeaderTextSmall {
      font-size: 0.9rem !important;
      line-height: 1.1rem !important;
    }            
}

/* PHONES */

@media  only screen and (max-width: 650px) {

   .spotlightCardIsSmall {
      width:120px !important;
   }
   .spotlightCardHeaderText {
      font-size: 1.4rem !important;
      line-height: 1.5rem !important;
   }          
   .spotlightCardHeaderTextSmall {
      font-size: 0.82rem !important;
      line-height: 0.88rem !important;
    } 
}

@media only screen and (max-width: 500px){
   .spotlightCardIsSmall {
      width:120px !important;
   }
   .spotlightCardHeaderText {
      font-size: 1.4rem !important;
      line-height: 1.5rem !important;
   }          
   .spotlightCardHeaderTextSmall {
      font-size: 0.82rem !important;
      line-height: 0.88rem !important;
    } 

}

@media only screen and (max-width: 450px){
   .spotlightCardIsSmall {
      width:120px !important;
   }
   .spotlightCardHeaderText {
      font-size: 1.4rem !important;
      line-height: 1.5rem !important;
   }          
   .spotlightCardHeaderTextSmall {
      font-size: 0.82rem !important;
      line-height: 0.88rem !important;
    }  
}

@media only screen and (max-width: 390px){
   .spotlightCardIsSmall {
      width:120px !important;
   }
   .spotlightCardHeaderText {
      font-size: 1.4rem !important;
      line-height: 1.5rem !important;
   }          
   .spotlightCardHeaderTextSmall {
      font-size: 0.82rem !important;
      line-height: 0.88rem !important;
    } 
}