
.analyticsReportControlsContainer {
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    border-radius: clamp(4px,0.4vw,10px);
    padding-top: 0.5rem; 
    padding-bottom: 0.5rem; 
    padding-left: clamp(0.5rem, 5vw, 3.0rem);        /*Adjust the padding based on screen width */
    padding-right: clamp(0.5rem, 5vw, 3.0rem);
    max-width: clamp(600px, 80vw, 1600px);
    border-radius: clamp(4px,0.4vw,10px);
    z-index: 0;
}

.noBoxShadow {
    box-shadow:none;
}

.analyticsTransactionNumbers {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    color: #6495ED;
    font-size: 1.2rem;
    font-weight: 700;
    border-radius: 100%;
    border: 4px solid #6495ED;
    /*border-radius: clamp(4px,0.4vw,10px);*/
    /*text-align: right;*/
    /*padding-right: 4px;*/
    height: 64px;
    width:64px;
    /*background-color: yellow;*/
}


.analyticsStatsText {
    font-weight: 600;
    font-size: 1.0rem;
    text-align: left;
    padding: 0px 10px;
    /*align-self:flex-start;*/
    /*background:#f7f8fa;*/
}