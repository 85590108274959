


.coulsonChartWrapper {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content: center;
    padding: clamp(5px,5vw,50px);
}


.coulsonUserSelectWrapper {
    /*width:100%;*/
    /*max-width: 800px;*/
    /*height:     auto;*/
    /*padding:    10px;*/
    /*margin:     20px 0px;*/
    /*border-radius: 20px;*/
    /*border: 1px solid blue;*/
}

.coulsonChartTitle {
    font-size:1.7rem;
    font-weight: 500;
    color: #6495ED;
}

.coulsonLegendWrapper {
    /*border: 1px solid red;*/
    /*background-color: yellow;*/
    max-width: 1200px;
    padding-bottom: clamp(5px,5vw,50px);
}

.coulsonLegendItem {
    width: auto;
    padding:clamp(5px,2vw,20px);
    /*background-color: yellow;*/
}


.coulsonLegendText {
    text-align:left;
    padding-left: clamp(2px,0.5vw,5px);
}

.coulsonInnerWrapper {
    position: relative;
    /*background-color: pink;*/
}

.coulsonAlertThresholdLabel {
    position: absolute;
    bottom:0;
    left:20;
    color: red;
    /*background-color: red;*/
    width:auto;
    height:auto;
}