.stackedAreaChart {
    display: flex;
    flex-direction: column;
    background:white;
    align-items: center;
    justify-content: center;
    
    margin: 0px 20px;
    padding: 0px 0px 10px 0px; 
    border-radius: clamp(4px,0.4vw,10px);
    
}

.stackedAreaChartTitle {
    font-size:x-large;
    font-weight: 600;
    color: #6495ED
    
}