.teamAdminWrapper{
    display: flex;
    align-items: center;
    justify-content:center;
    /*background-color: red;*/
    
}

.teamAdmin {
    height: 800px;
    width: 1200px;
    
    -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
    box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);
}

.teamTitleContainer {
    display:flex;
    align-items:center;
    justify-content:space-between;
    font-size: x-large;
    /*background-color: yellow;*/
}



.teamAdimActions{
    display: flex;
    align-items: center;
    justify-content:center;
}

.teamAdminButton{
    margin-right: 10px;
}

.teamAddButton{
    color: #5DADE2;
    cursor:pointer;
    width:80px;
    border:none;
    border-radius: 5px;
    padding:5px;
    background-color: teal;
    color:white;
    font-size: large;
}

.deleteButton{
    color: red;
    cursor: pointer;
}

.teamContainer {
    display: flex;
    margin-top: 20px;
    /*background: red;*/
}

.teamDisplay {
    flex:1;
    padding: 20px;
    -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
    box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);
    /*background-color: yellow;*/

}

.teamUpdate {
    flex:1;
    padding: 20px;
    -webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); 
    box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);
    margin-left: 20px;
    /*background-color: orange;*/
}

.teamDisplayData {
    display:flex;
    flex-direction: column;
    margin-left: 20px;
    /*background-color: green;*/
}

.teamDisplayFirstName {
    font-weight: 600;
}

.teamDisplayLastName {
    font-weight: 600;
}
.teamDisplayTitle {
    font-weight: 300;
}

.teamAccountDetails {
    font-weight: 600;
    font-size:large;
    margin-top:20px;
    /*color:blue;*/
    /*background-color: red;*/
}

.teamDisplayTeam {
    font-weight: 600;
    font-size:large;
    margin-top:20px;
    
}

.teamDisplayCustomer {
    font-weight: 600;
    font-size:large;
    margin-top:20px;
    
}

.teamDisplayInfo {
    display:  flex;
    align-items: center;
    /*margin: 20px 0px;*/
    color: #444;
}
.teamDisplayIcon {
    font-size: 16px;
    
}

.teamDisplayAccountInfo {
    margin-left: 10px;
}

.teamUpdateTitle {
    font-size: 24px;
    font-weight: 200;
    
}
.teamUpdateItem {
    display: flex;
    flex-direction: column;
    margin-top: clamp(4px,1vh,10px);
}

.teamUpdateForm {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.teamUpdateItem {
    display: flex;
    flex-direction: column;
    margin-top: clamp(4px,1vh,10px);
}

.teamUpdateItem>label {
    margin-bottom: 5px;
    font-size: 14px;
}

.teamUpdateInput {
    border: none;
    width: 250px;
    border-bottom: 1px solid gray;
    margin-left:20px;
}

.teamUpdateButton {
    border-radius: 5px;
    border: none;
    padding: 5px;
    cursor: pointer;
    background-color: darkblue;
    color: white;
    font-weight: 600;
}


.teamUpdateInput {
    flex: 1;
}

.teamUpdateForm {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.teamEditItem {
    display: flex;
    flex-direction: row;
    margin-top: clamp(4px,1vh,10px);
    margin-left: 20px;
    /*width: 200px;*/
    justify-content: flex-start;
    align-items: center
}

.teamEditItem>label {
    margin-bottom: 5px;
    font-size: 14px;
    width: 200px;
    /*background-color: yellow;*/

    
}
.teamEditInput {
    width: 400px;
    /*background-color: yellow;*/

    
}

.teamEditForm {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}


.adminTeamSummaryModal {
    padding-top: 50px;
    /*min-width: 80%;*/
    max-width: 1400px;
    min-height: 1400px;
}