.spotlightCardWrapper {
  display: flex;
  /* width: 100%; */
  min-height: clamp(240px, 20vh, 300px);
  border-radius: clamp(8px, 4vw, 16px);
  padding: clamp(4px, 0.4vw, 6px) clamp(4px, 0.4vw, 6px);
  /* padding: clamp(18px, 3vw, 22px) clamp(18px, 3vw, 22px); */
  cursor: default;
  position: relative;
  border-width: 6px;
  border-style: solid;
  background-color: var(--white);
  border-color: var(--BALANCE-blue);
  max-width: clamp(450px, 100%, 550px);
  overflow: hidden;
}

.spotlightNextPrevButtonContainer {
  position: relative;
  z-index: 1;
}

.cardShadow {
  box-shadow: 0px 1px 25px -9px rgba(77, 16, 99, 0.6);
}

.darkMode .spotlightCardWrapper {
  background-color: var(--black);
}

.spotlightCardInnerWrapper {
  position: relative;
  padding: clamp(15px, 2vw, 25px);
}

.spotlight-content {
  position: relative;
  z-index: 1;
  padding: clamp(8px, 1vw, 15px);
  padding-top: clamp(5px, 1.5vh, 10px);
}

.spotlight-content-innerContainer {
  padding-left: clamp(50px, 10vw, 75px);
  padding-right: clamp(50px, 10vw, 75px);
}

.spotlight-header {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.spotlight-header h2 {
  font-size: 1.2rem;
  margin: 0;
  color: #f4b400;
}

.avatars {
  display: flex;
  align-items: center;
}

.avatar {
  width: 36px;
  height: 36px;
  background-color: #ddd;
  border-radius: 50%;
  margin-left: -8px;
  border: 2px solid #fff;
  background-size: cover;
}

.more-avatars {
  background-color: #f4b400;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.9rem;
}

.spotlight-textContainer {
  flex-grow: 1;
  margin-top: 12px;
}

.spotlight-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 0.8rem;
  color: #777;
}

.castButtonContainer {
  /* padding-top: 15px; */
  padding-left: 10px;
  border-radius: 8px;
}

.castButtonContainerCentered {
  position:absolute;
  top:50%;
  left:50%;
  padding-left: 10px;
  border-radius: 8px;
  transform:translate(-50%,0%);
}

.fixHeight {
  height: clamp(130px, 15vh, 160px);
}

.overText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3; 
  max-height: calc(1.2em * 3)
}

@media only screen and (max-width: 1280px) {
  .overText {
    -webkit-line-clamp: 2;
    max-height: calc(1.2em * 2);
  }
}

.castButton {
  font-size: 0.75rem !important;
  font-weight: 600 !important;
  border: 1px solid var(--DEI-purple);
  background-color: var(--TEAM-purple);
  color: var(--white);
  border-radius: 0.6vw !important;
  padding-top: clamp(5px, 0.75vh, 8px);
  padding: 6px 12px;
}

.spotlight-actions {
  display: flex;
  align-items: center;
}

.spotlightCardActionWrapper {
  gap: 12px;
}

.spotlightSenderAvatarWrapper {
  flex:1;
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:center;
  max-width: clamp(60px,6vw,100px);
}

.like-btn {
  background-color: var(--BALANCE-blue);
  border-radius: 8px;
  padding: 8px;
  color: #fff;
  border: 1px solid var(--BALANCE-blue);
}

/* next prev button style start */

.spotlight-action-btn {
  position: absolute;
  top: 50%;
}

.spotlight-prev-button {
  left: clamp(20px, 3.5vw, 30px);
}

.spotlight-next-button {
  right: clamp(20px, 3.5vw, 30px);
}

/* next prev button style end */

/* crystal style start */

.crystal-position {
  position: absolute;
}

.spotlight-div {
  position: relative;
}

.crystal-top {
  top: -5px;
  right: -30px;
}

.crystal-center {
  top: 85px;
  right: 54px;
}
.crystal-bottom {
  top: 147px;
  left: 32px;
}

/* crystal style end */

/* spotlight torch component style start */

.torch {
  position: absolute;
  top: 0;
  left: clamp(20px, 2.5vw, 30px);
}

.torch-opposite {
  position: absolute;
  top: 0;
  right: clamp(20px, 2.5vw, 30px);
  transform: scaleX(-1);
}

.spotlight-focus-effect {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(circle, rgba(255, 223, 52, 0.7) 20%, transparent 60%);
  clip-path: polygon(45% 0%, 65% 0%, 90% 95%, 20% 95%);
  pointer-events: none;
  z-index: 1;
  opacity: 0;
  transform: scale(0.5);
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
}

.spotlight-focus-effect.active {
  opacity: 1;
  transform: scale(2.5);
}

.torch-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 175px;
  width: 200px;
  position: absolute;
  top: clamp(4px, 0.4vw, 6px);
  left: clamp(-32px, 2.5vw, -40px);
  rotate: -30deg;
}

.torch-container-opposite {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 175px;
  width: 200px;
  position: absolute;
  right: clamp(-20px, -1.5vw, -25px);
  top: clamp(0px, 1.5vh, -5px);
  rotate: -30deg;
}

.torch-img-container {
  height: 70px;
  width: 100px;
  position: relative;
  /* rotate: 10deg;
  left: 13px; */
}

.spotlight-effect {
  top: 0;
  left: 0;
  width: 125%;
  height: 130px;
  background: linear-gradient(
    to bottom,
    var(--white) 0%,
    rgba(255, 222, 52, 0.2) 15%,
    rgba(255, 222, 52, 0.3) 50%,
    transparent 100%
  );
  clip-path: polygon(45% 0%, 65% 0%, 100% 95%, -5% 95%);
  pointer-events: none;
  /* z-index: 1; */
  /* animation: spotlight-animation 3.5s infinite; */
  opacity: 1;
  animation: flash 7.9s ease-in-out infinite;
}

.spotlight-effect-wrapper {
  rotate: -66deg; 
  top: 12px;
  left: 50px;
  position: absolute;
}

.darkMode .spotlight-effect {
  background: linear-gradient(
    to bottom,
    rgba(255, 255, 255, 0.75) 0%,
    rgba(255, 222, 52, 0.2) 15%,
    rgba(255, 222, 52, 0.3) 50%,
    transparent 100%
  );
}

@keyframes flash {
  0%, 100% {
    opacity: 0; /* Start and end invisible */
  }
  10%, 90% {
    opacity: 1; /* Full visibility during the flash */
  }
}

/* .torch {
  animation: torch-animation 3s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

.torch-opposite {
  animation: torch-opposite-animation 3s cubic-bezier(0.42, 0, 0.58, 1) infinite;
}

@keyframes torch-animation {
  0% {
    rotate: -15deg;
  }
  50% {
    rotate: -50deg;
  }
  100% {
    rotate: -15deg;
  }
}

@keyframes torch-opposite-animation {
  0% {
    rotate: 15deg;
  }
  50% {
    rotate: 50deg;
  }
  100% {
    rotate: 15deg;
  }
}

.torch .spotlight-effect,
.torch-opposite .spotlight-effect {
  animation: spotlight-effect-animation 3s cubic-bezier(0.42, 0, 0.58, 1) infinite;
} */

@keyframes spotlight-effect-animation {
  0%,
  50%,
  100% {
    opacity: 0.5;
  }
  25%,
  75% {
    opacity: 1;
  }
}

@keyframes slideLeftCenterRight {
  0% {
    transform: translateX(-65%);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  90% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(65%);
    opacity: 0;
  }
}

@keyframes slideRightCenterLeft {
  0% {
    transform: translateX(65%);
    opacity: 0;
  }
  10% {
    transform: translateX(0);
    opacity: 1;
  }
  90% {
    transform: translateX(0);
    opacity: 1;
  }
  100% {
    transform: translateX(-65%);
    opacity: 0;
  }
}

.animated-slide {
  animation: slideRightCenterLeft 8s cubic-bezier(0.4, 0, 0.6, 1);
  margin-bottom: clamp(12px, 3vh, 18px);
}

.animated-slide-prev {
  animation: slideLeftCenterRight 8s cubic-bezier(0.4, 0, 0.6, 1);
  margin-bottom: clamp(12px, 3vh, 18px);
}

/* spotlight torch component style end */

/* dots style start */

.dots-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  gap: 5px;
}

.dot {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background: var(--BALANCE-blue);
  opacity: 0.5;
}

.active-dot {
  opacity: 1 !important;
}

.progressBar-v2 {
  width: 80px;
  height: 8px;
  background-color: #EADCF0;
  border-radius: 10px;
  overflow: hidden;
}

.progress-v2 {
  height: 100%;
  /* width: 20%; */
  background-color: #c78ac6;
  border-radius: 0 10px 10px 0;
  transition: width 0.5s ease-in-out;
}

/* dots style end */

.hover-text {
	opacity: 0;
	transition: opacity 0.2s ease;
}

.progressBar-v2:hover + .hover-text {
	opacity: 1;
}