
/* 

SPOTLIGHT TOOL

*/

.sendSpotlightTool {
    max-height: calc(90vh);
    /* max-width: 90vw; */
    max-width: clamp(200px,90vw,1400px);
    padding:clamp(14px,1.2vw,20px);
    /* background-color: var(--light-mode-card-background);
    border-top-width: clamp(4px,0.5vw,8px);
    border-top-color: var(--TEAM-purple);
    border-top-style: solid; */
    background-color: var(--light-grey);
}

.sendSpotlightPortionWrapper {
    display: flex;
    margin-right: clamp(12px,1vw,18px);
}

.sendSpotlightTool .sendSpotlightPortion {
    width:100%;
    /* margin-right:clamp(4px,1vw,10px); */
    /*padding-right:clamp(4px,1vw,10px);      /* A bit of room for the scroll bar */
    overflow-y:scroll;
    overflow-x:hidden;
    background-color: var(--white);
    /* min-height: 600px; */
    /*background-color:white;*/
    /*padding-top: 10px;*/
    /*padding-bottom: 10px;*/
    /*overflow: hidden;*/
}

.darkMode .sendSpotlightTool .sendSpotlightPortion {
    background-color: var(--page-background) !important;
}

.sendSpotlightTool .chatPortion {
    width: 100%;
    max-height: inherit;
    display:flex;
    flex-direction:column;
    align-items: center;
    /* overflow-y:hidden; */
    /* flex:0.5; */
    /* margin:clamp(10px,5vw,50px); */
    /* background-color: yellow; */
}

.spotlightSendToolInnerWrapper{
	width: 100%;
	/* background: black; */
	border-radius: clamp(4px,0.4vw,10px);
	padding: 0 clamp(12px,1vw,18px);
    padding-bottom: clamp(15px,1.4vw,22px);
	/* margin-top: 5px; 
	margin-bottom: 5px; */
	list-style: none;
	box-sizing: border-box;			
	font-family: 'Montserrat', sans-serif;
	text-align: center;
}

.userRecognizeOuterWrapper {
    padding: clamp(12px,1vw,18px);
}

.userRecognizeInnerWrapper {
    margin-top: clamp(20px,1.5vw,26px);
    margin-bottom: clamp(20px,1.5vw,26px);
}

.awardToolSection {
    border: 1px solid var(--light-grey);
    border-radius: clamp(10px,0.6vw,12px);
    padding: clamp(12px,1vw,18px);
    gap: 10px;
}

.awardValueContainer {
    padding: 3px 10px;
    border: 1px solid var(--light-grey);
    border-radius: clamp(4px,0.4vw,10px);
}

.spotlightSlider {
    color: var(--BALANCE-blue) !important;
}

.sliderValueLabel {
    background-color: var(--BALANCE-blue) !important;
    border-radius: 8px !important;
    padding: 7px 10px !important;
}

.coinImg {
    height: 12px;
    width: 12px;
}

.spotlightSendToolInnerColumn{
	width: 100%;
	padding: 0 clamp(4px,1vw,10px);
	/* background: white; */
	border-radius: clamp(4px,0.4vw,10px);
	/*padding: 10px;	*/
	/*margin-top: 5px; */
	/*margin-bottom: 5px;*/
	list-style: none;
	box-sizing: border-box;			
	font-family: 'Montserrat', sans-serif;
	/* min-height: 300px; */
    /*flex-grow:1;    /*Let this column expand to fill the card */
    max-width: clamp(400px,40vw,800px); /* Need to limit the expansion of the Giphy Search Experience or it just grows and crashes */
}

.spotlightSendToolBannerWrapper {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
	border-radius: clamp(4px,0.4vw,10px);
    background-color:black;
    width:100%;
    padding-bottom:20px;
    margin-bottom:clamp(4px,1vw,10px);
}

.spotlightSendToolBanner {
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    font-size:2.0rem;
    padding-top :clamp(2px,0.5vh,5px); 
    padding-bottom: clamp(4px,1vh,10px);
    position:relative;
    margin-bottom: clamp(8px,2vh,20px);    /*A bit of space for the lights */
}

.spotlightSendToolBannerText{
    font-weight: 600;
    color:gold;
    margin-left:clamp(8px,2vw,20px);
    margin-right:clamp(6px,1.8vw,18px);
}

.spotlightSendToolLightRingLeft {
    position:absolute;
    background-color: #fffffa;
    border-radius: 50%;
    height: 10px;
    width: 40px;
    bottom:-100px;
    left: 60%;
    transition: all 1s ease-in;

    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover,  #ffffff 31%, #dbdbdb 44%, #fcfcfc 55%, #fcfcfc 64%, #cecece 74%, #fcfcfc 82%, #fcfcfc 89%, #adadad 99%, #a0a0a0 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover,  #ffffff 31%,#dbdbdb 44%,#fcfcfc 55%,#fcfcfc 64%,#cecece 74%,#fcfcfc 82%,#fcfcfc 89%,#adadad 99%,#a0a0a0 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center,  #ffffff 31%,#dbdbdb 44%,#fcfcfc 55%,#fcfcfc 64%,#cecece 74%,#fcfcfc 82%,#fcfcfc 89%,#adadad 99%,#a0a0a0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}


.spotlightSendToolLightRingRight {
    position:absolute;
    background-color: #fffffa;
    border-radius: 50%;
    height: 10px;
    width: 40px;
    bottom:-100px;
    right: 60%;
    transition: all 1s ease-in;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+31,dbdbdb+44,fcfcfc+55,fcfcfc+64,cecece+74,fcfcfc+82,fcfcfc+89,adadad+99,a0a0a0+100 */
    background: #ffffff; /* Old browsers */
    background: -moz-radial-gradient(center, ellipse cover,  #ffffff 31%, #dbdbdb 44%, #fcfcfc 55%, #fcfcfc 64%, #cecece 74%, #fcfcfc 82%, #fcfcfc 89%, #adadad 99%, #a0a0a0 100%); /* FF3.6-15 */
    background: -webkit-radial-gradient(center, ellipse cover,  #ffffff 31%,#dbdbdb 44%,#fcfcfc 55%,#fcfcfc 64%,#cecece 74%,#fcfcfc 82%,#fcfcfc 89%,#adadad 99%,#a0a0a0 100%); /* Chrome10-25,Safari5.1-6 */
    background: radial-gradient(ellipse at center,  #ffffff 31%,#dbdbdb 44%,#fcfcfc 55%,#fcfcfc 64%,#cecece 74%,#fcfcfc 82%,#fcfcfc 89%,#adadad 99%,#a0a0a0 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}

.spotlightSendToolLightRingRightRotated {
    right:-42px;
    transform:scale(0.5);
    /*background-color: red;*/

}

.spotlightSendToolLightRingLeftRotated {
    left:-40px;
    transform:scale(0.5);
    /*background-color: red;*/
}


.spotlightSendToolLightRight {
    border-radius:50%;
    position:absolute; 
    right:-35px;
    bottom:0;
    height:40px;
    width:40px;
    background-color:black;
    transition: all 1s ease-in;
}

.spotlightSendToolLightLeft {
    border-radius:50%;
    position:absolute; 
    left:-35px;
    bottom:0;
    height:40px;
    width:40px;
    /*background-color:pink;*/
    transition: all 1s ease-in;
}


.spotlightSendToolLightRightRotated {
    transform: rotate(-50deg);

}

.spotlightSendToolLightLeftRotated {
    transform: rotate(+50deg);
}




.spotlightSendToolLightBeamRight {
    position:absolute; 
    right:15px;
    bottom:-85px;
    height:140px;
    width:40px;
    /*background-color:pink;*/
    transition: all 1s ease-in;
    transform: rotate(+60deg);
    opacity:0.3;
    
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,adadad+50,000000+100 */
    background: #000000; /* Old browsers */
    background: -moz-linear-gradient(left,  #000000 0%, #adadad 50%, #000000 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #000000 0%,#adadad 50%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #000000 0%,#adadad 50%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.spotlightSendToolLightBeamLeft {
   position:absolute; 
    left:15px;
    bottom:-85px;
    height:140px;
    width:40px;
    /*background-color:pink;*/
    transition: all 1s ease-in;
    transform: rotate(-60deg);
    opacity:0.3;

    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#000000+0,adadad+50,000000+100 */
    background: #000000; /* Old browsers */
    background: -moz-linear-gradient(left,  #000000 0%, #adadad 50%, #000000 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  #000000 0%,#adadad 50%,#000000 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  #000000 0%,#adadad 50%,#000000 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

}


.spotlightSendToolLightBeamRightRotated {
    right:-38px;
    transform: rotate(0deg);
    height:90px;
    width:30px;
}

.spotlightSendToolLightBeamLeftRotated {
    left:-33px;
    transform: rotate(0deg);
    height:90px;
    width:30px;
}


.spotlightSendToolBannerShadow {
  position:absolute;
  right:50%;
  bottom:-100px;
  transform:translate(50%,0);
  border-radius:50%;
  background:black;
  opacity:0.7;
  height:20px;
  width:100px;
 
}


