/* sidebar menu */

.sidebar {
  display: flex;
  flex-direction: column;
  width: 300px;
  min-width: 300px;
  height: 100%;
  /* margin: 20px; */
  border-radius: 15px;
  padding-block: 30px;
  padding-left: 10px;
  background-color: #41a2da;
}
.darkMode .sidebar {
  background-color: #3b3b3b;
}


.gap-10 {
  gap: 10px;
}


.sidebarMenu {
  display: flex;
  flex-direction: column;
  padding-top: 25px;
  overflow: auto;
  height: 100%;
}
.sidebarMenu::-webkit-scrollbar {
  width:0px; 
}
.sidebarMenu::-webkit-scrollbar * {
  background:transparent; 
}
.sidebarMenu::-webkit-scrollbar-thumb {
  background:rgba(195, 195, 195, 0.1) !important; 
}


.logOut{
  display: flex;
  margin-top: 30px;
  align-items: flex-end;
  padding: 10px 20px;
}


.colorWhite {
  color: white;
}

.darkMode .colorWhite {
  color: #909090;
}



.backGroundcolor {
  background-color: white;
}

.darkMode .backGroundcolor {
  background-color: black;
}


.menuItemStyle {
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: pointer;
  padding: 10px 20px;
}


.sidebarMenuItem {
  background-color: transparent;
  color: var(--white);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}


.sidebarActiveMenuItem {
  background-color: var(--white);
  color: #41a2da;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.darkMode .sidebarMenuItem {
  background-color: transparent;
  color: #909090;
}

.darkMode .sidebarActiveMenuItem {
  background-color: var(--black);
  color: var(--white);
}


.curveImgTop {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  bottom: -30px;
}

.curveImgBottom {
  height: 30px;
  width: 30px;
  position: absolute;
  right: 0;
  top: -30px;
}



.subMenu {
  background-color: transparent;
  color: var(--white);
  border-radius: 0px;
}

.subMenuActive {
  background-color: var(--white);
  color: #41a2da !important;
  border-radius: 10px;
}

.darkMode .subMenu {
  background-color: transparent;
  color: #909090;
  border-radius: 0px;
}

.darkMode .subMenuActive {
  background-color: var(--black);
  color: var(--white) !important;
  border-radius: 10px;
}



.menuItem {
  color: white;
}



.expandableMenuDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
}



.subMenuItem {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.expandableMenuItem {
  margin-left: 35px;
  margin-top: 10px;
  padding-block: 10px;
}

/*   
  .active {
    background-color: white;
    color: #41a2da;
    border-radius: 10px;
    padding: 10px 20px;
  }
     */



