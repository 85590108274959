

.ProductPurchaseRank {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    background-color: yellow;
    font-size: 24px;
    font-weight: 600;
    border-radius: clamp(4px,0.4vw,10px);
    height: 40px;
    min-width: 40px;
    width:auto;
    color:white;
    padding: 0px 5px;
}

.ProductSpendRank {
    display:flex;
    flex-direction:row;
    align-items: center;
    justify-content: center;
    text-align: center;
    vertical-align: middle;
    background-color: pink;
    font-size: 16px;
    font-weight: 600;
    border-radius: clamp(4px,0.4vw,10px);
    height: 40px;
    padding: 0px 5px;
    min-width: 40px;
    width:auto;
    color:white;
    /*margin-top:5px;*/
    /*margin-bottom: 5px;*/

    
}