/*
  PROPRIETARY AND CONFIDENTIAL
  copyright - 2020-2024
*/

.searchBarAnchor {
    position:absolute;
    top:5px;
    right:40px;    
    /*display: flex;*/
    /*align-items: center;*/
    /*justify-content: flex-end;*/
    /*min-height: 75px;*/
    /*cursor:pointer;*/
    /*width:0;*/
    /*overflow: hidden;*/
    /*transition: all 0.5s;*/
    /*transition-timing-function: ease-in;*/
   
}

.searchContainer {
    position:relative;
    padding-left: clamp(0.5rem, 5vw, 3.0rem);        /*Adjust the padding around the menu based on screen width */
    padding-right: clamp(0.5rem, 5vw, 3.0rem);
    margin-top:clamp(4px,0.4vw,10px);
    margin-bottom:clamp(4px,0.4vw,10px);
    /*opacity: 0;*/
    transition: all 0.5s ease-out;    /* Transition to be used when making the search bar visible*/
    overflow:hidden;    /* Hides buttons and filters when not active */
    height:auto;
    max-height: 0px;
    width:100%;
    /*width: 0%;*/
    max-width: clamp(400px,80vw,1400px);
}

.searchSummaryContainer {
    height:auto;
    max-height: 0;
    transition: all 0.5s ease-out;    /* Transition to be used when making the search bar visible*/
    overflow:hidden;
}

.searchActive {
    max-height:400px;
    transition: all 0.5s;
    overflow:visible;   /* necessary for drop-down menus to show */
}
.searchInput {
    border: none;
    padding:clamp(4px,0.4vw,4px);
    border-radius: clamp(4px,0.4vw,10px);
    width:100%;
}

.searchInputDark {
    border: 1px solid grey;
    background-color: #111;
    color: white;
}

.searchContainerDark input::placeholder {
    /* font-size: 1.0rem !important;
    font-weight: 400 !important; */
    color: white;
    opacity: 1;
}

.searchContainerLight input::placeholder {
    /* font-size: 1.0rem !important;
    font-weight: 400 !important; */
    color: var(--BALANCE-blue);
}
      