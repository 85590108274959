.launchRow{
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    /*background-color: white;*/
    /* background-color: black; */
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    padding-top: clamp(4px,0.5vh,50px);   /* Put some padding between rows so that a card can scale without shifting other rows around */
    /*min-height: 350px;              /* Fix the height so the row height won't change and shift the other rows */
}


.launchRow::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
    display:none;
}


.launchRowLarge{
    position:relative;
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content:flex-start;
    /*background-color: white;*/
    /* background-color: black; */
    /*min-width: 100vw;*/
    /*overflow-y: hidden;*/
    overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    padding-top: clamp(4px,1vh,50px);   /* Put some padding between rows so that a card can scale without shifting other rows around */
    padding-left:clamp(4px,1vw,10px);
    padding-right:clamp(4px,1vw,10px);
    /*min-height: clamp(200px,20vh,450px);              /* Fix the height so the row height won't change and shift the other rows */
}


.launchRowLarge::-webkit-scrollbar {    /* hide scrollbard but keep the functionality */
    display:none;
}

.launchRowNavigator {
    position: absolute;
    height: 100%;
    width: 75px;
    background-color: rgba(1,1,1,0.0);
    z-index: 99;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    /*opacity: 0;*/
    color:white;
    font-size:64px;
    /*font-weight: 600;*/
    transition: all 0.4s;
    opacity: 0;    /*Hide text, i.e., arrow */
}

.launchRowNavigatorLeft {
    left: 0vw;
    top: 0vw;
}

.launchRowNavigatorRight {
    right: 0vw;
    top: 0vw;
}

.launchRowNavigatorActive{
    /*opacity: 0.5;*/
    background-color: rgba(1,1,1,0.5);
    opacity: 1; /*Show text with full opacity*/
}

/*::-webkit-scrollbar {*/
/*    -webkit-appearance: none;*/
/*    width: 4px;*/
/*}*/
/*::-webkit-scrollbar-thumb {*/
/*    border-radius: 20px;*/
/*    background-color: rgba(0,0,0,.5);*/
/*    -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);*/
/*}*/


.launchRowTitle {
    /* position:absolute;
    top:0;
    left:0; */
    /* font-size:36px;
    font-weight:500; */
    /* color:var(--dark-grey); */
    padding-left:clamp(10px,10vw,100px);
    padding-top:clamp(10px,2vh,200px);
    /* padding-bottom:clamp(2px,0.3vh,4px); */
}

.launchButtonsWrapper {
    height:0;            /* NOTE HOW THIS STARTS AT ZERO */
    position:absolute;
    bottom:0;
    width:100%;
    background-color: rgba(51, 51, 51, 0.7);
    transition-timing-function: ease-in;
    transition: all 0.4s;
    overflow: hidden;
    max-height: 100px;
}

.launchButtonsShow {
    /*display:block;*/
    /*background-color: yellow;*/
    height:100%;
    padding-top: clamp(5px,1vw,10px);
    padding-bottom: clamp(10px,2vw,25px);    

}