.lineChart {
    margin: 0px;
    padding: 0px;
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
}

.lineChartTitle {
    margin-bottom: 20px;
}