/* Leader Board CSS classes */

.leaderboardContext {
	.ModalNoBackgroundFixedContainer, .ModalNoBackgroundContainer {
		z-index: 99999 !important;		/* Bump this up so modals from this context appear above modals from pages */
	}
}

/*.lb_wrapper{*/
	/*width: 100%;*/
	/*height: 100%;*/
	/*margin: 0;*/
/*	margin-top: 5px;*/
/*	padding: 0;*/
/*	list-style: none;*/
/*	box-sizing: border-box;*/
/*	font-family: 'Montserrat', sans-serif;*/
/*}*/


/* Outer wrapper from top to bottom */
.lboard_section{
	min-width:clamp(200px,40vw,800px);
	max-width: 90vw;
	max-height: 90vh;
	/*height: 400px;*/
	/*margin: 100px auto 0;*/
	background: #BB8FCE;
	/*position: relative;*/
	border-radius: clamp(4px,0.4vw,10px);
	
	
	padding: 10px;	/*provide some buffer around the inner leader boards so the outer color shows */
	
	margin-top: clamp(10px,2vh,20px); /*outer buffer above and below leaderboard container */
	margin-bottom: 5px;

	list-style: none;
	box-sizing: border-box;			/*setting such that padding and border are included in the Width paramter; box size does not equal the sum of the 3 */
	font-family: 'Montserrat', sans-serif;
	
}

.lboard_section .leaderboardSectionRow {
	height: calc(90vh - clamp(40px,8vh,80px));
	max-height: 95vh;
	/* padding:1em; */
}


.lboard_section .navigationPortion {
   max-height:inherit;
	overflow-y:scroll;	
	min-width: clamp(80px,10vw,150px);
	width: clamp(100px,20vw,200px);
	max-width:15vw;
}

.lboard_section .leaderboardPortion {
   max-height:inherit;
   overflow-y:scroll;	
	width:clamp(200px,40vw,800px);
	max-width: 80vw;
}

/* Flex row for links / tabs for the different leaderboards */
.lboard_section  ul{
	width:100%;
	margin-top:clamp(4px,1vw,10px);
	color: var(--dark-grey);
	/* display: flex;
	padding: 3px;
	align-items: center; */
	/*justify-content:space-between;*/
}

.lboard_section  ul li{
	position:relative;
/*	margin-right: 10px;    /*Right margin on each leaderboard tab */
/*	margin-left: 10px;    /*Left margin on each leaderboard tab */
	flex: 1;
	/* font-size: clamp(8px,3vw,20px); */
	font-weight: 600;
	cursor: pointer;
	list-style: none;
	text-align: right;
	margin:clamp(4px,1.5vw,16px) 0px;		/* provide space between tabs for our curves */
	/* padding-left:clamp(4px,1vw,10px);
	padding-right:clamp(4px,1vw,10px); */
	/* border-style: solid;
	border-width: 1px;
	border-color: var(--dark-grey); */
	/* margin: 0px clamp(2px,0.5vw,6px); */

}

/*Modify the attributes of the tabs for different states */
.lboard_section  ul li:hover {
	color: #fff;
	/*text-shadow: 10px 10px 20px white;	/*Let's highlight the active item and when and item is hovered over */
	/* font-size: clamp(8px,3.5vw,24px); */
}

.lboard_section  ul li.active{
	/*display:block;		/* Show the tab */
	/* background-color: var(--light-mode-card-background); */
	/* color: #fff; */
	/*text-shadow: 10px 10px 20px gold;	/*Let's highlight the active item and when and item is hovered over */
	/* font-size: clamp(8px,3.5vw,24px); */
	/* border-bottom: 2px solid currentColor; */
	/*text-decoration: underline;*/
}

.lboard_section  ul li.active:hover {
	color: currentColor;
}

.lboard_section  ul li.active::before{
	content:"";
	position:absolute;
	top:0;
	right: 0;
	height:clamp(8px,2vw,20px);
	width:clamp(8px,2vw,20px);
	background-color: transparent;
	border-bottom-right-radius:50%;
	box-shadow: 0 10px 0 0 white; /* This is where the magic happens! */
	transform: translate(0,-100%); /*translate the pseudo div up by its own height */ 
}

.lboard_section  ul li.active::after{
	content:"";
	position:absolute;
	bottom:0;
	right: 0;
	height:clamp(8px,2vw,20px);
	width:clamp(8px,2vw,20px);
	background-color: transparent;
	border-top-right-radius:50%;
	box-shadow: 0 -10px 0 0 white; /* This is where the magic happens! */
	transform: translate(0,100%); /*translate the pseudo div up by its own height */ 
}

.lboard_section ul li .tabLabel {
	padding-right:clamp(4px,1.5vw,16px) ;		/* provide padding around the text without changing the position of the curves */
}

.lboard_section ul li.active .tabLabel {
	padding:clamp(4px,1.5vw,16px) ;		/* provide padding around the text without changing the position of the curves */
	background-color: var(--light-mode-card-background);
	border-top-left-radius: clamp(4px,1vw,10px);
	border-bottom-left-radius: clamp(4px,1vw,10px); 
}

/* Next section- the actual list of employees */
.lboard_wrap{
	/*position: absolute;*/
	/*top: 70px;*/
	/*left: 0px;*/
	/*background: #ffd300;*/
	width: 100%;
	/* height: 100%; */
	z-index: 99;
	overflow: scroll;
	transition:max-height 600ms;
}

/*The DIV that wraps each of the different Leaderboards */
.lboard_wrap .lboard_list{
	padding: clamp(4px,0.2vw,16px) clamp(6px,1vw,16px);
	/* color: black; */
	/* background: #fafafa; */
	border-radius: clamp(4px,0.4vw,10px);
}

.challengeInfoAnchor {
	/* position:absolute;
	top:0;
	right:0%; */
	/*transform: translate(20%,10%);	/* move the Info button */
	transform: translate(-20%,-10%);	/* move the Info button */
}

/* Overwride how Info pop-up is displayed in the leaderboard */
.lboard_section .infoPopUp {
	min-width: clamp(100px, 40vw, 200px);
   transform: translate(-100%, calc(100% + 10px));     /* Move the info pop-up down 110% of its own height; but do NOT shift left  */
}

/*The DIV for each individual Employee; flex row across the individual employee */
.lboard_wrap .lboard_card{
	position:relative;
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: clamp(8px,1.5vw,20px) clamp(4px,0.5vw,10px);
	margin-bottom: clamp(8px,1vw,15px);
	border-radius: clamp(4px,0.4vw,10px);
	width:calc(100% - clamp(8px,1vw,15px));

    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);	*/
}

/*.lboard_wrap .lboard_card:first-child{*/
/*	padding-top: 0;*/
/*}*/

/*.lboard_wrap .lboard_card:last-child{*/
/*	padding-bottom: 0;*/
/*	border-bottom: 0;*/
/*}*/

/*CSS for the "img" class */
.lboard_wrap .lboard_card .avatarContainerPortion {
	flex-shrink:0;
	/* width: clamp(40px,10vw,140px); */
	position:relative;	/*parent for the rank to be absolute */
	padding: 0px clamp(2px,0.3vw,4px);	/*Blank space to the left of the Avatar  so we can offset the rank */
}

/*CSS for the "img" tag in the leaderboard*/
.lboard_wrap .lboard_card img{
	display: block;
	/* height: 100%; */
}

.lb_rank_anchor{
	position:absolute;		/*position on the avatar image */
	top: 0px;
	left: 0px;
}

.lb_rank{
	/* height:auto;
	width:auto; */
   /* height:clamp(16px,1.5vh,32px);
   min-width:clamp(16px,1.5vh,32px); */

    /*min-width:40px;*/
    /*margin: 0 0px 0px;*/
	/*text-shadow: 2px 2px 10px red;*/
	background-color: white;
	border-radius: 50%;

    /*border:solid 1px #409cf7;*/
    /*text-align:center;*/
    /*vertical-align: middle;*/
    font-weight: 600;
    overflow: hidden;

	-webkit-box-shadow: -3px -2px 13px -1px rgba(0,0,0,0.38); 
	box-shadow: -3px -2px 13px -1px rgba(0,0,0,0.38);    
}

.rankText {
	/* font-size: 18px;
	font-weight: 600; */
	color: var(--dark-grey);
	
    /*Center the rank */    
    margin: 0;    
    /* position: absolute;
    top: 50%;
    left: 50%; */
    /*transform: translate(-50%, -50%); /*Shift just the rank so the rank itself is centered.  Above two lines centers the top left point on the text */
	
}

.lboard_wrap .lboard_card .name_bar{
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	margin: 0 20px;
	width:100%;
	/* width: calc(70%); */
	/* font-size: large;
	font-weight: 600;	 */
}

.lboard_wrap .lboard_card .name_bar p {
	color: #3a3d51;
}

.lboard_wrap .lboard_card .name_bar p span{
	margin-right: 5px;
}


.lboard_wrap .lboard_card .points{
	position:absolute;
	top:0;
	right:0;
	/* padding:clamp(4px,1vw,10px);
	flex-shrink: 0; */
	/*min-width: 75px;*/
	/* color: #3a3d51;
	font-size: x-large;
	font-weight: 600;	 */
}

/*
.lboard_wrap .lboard_card .movement{
	width: 15%;
	color: #3a3d51;
}
*/

.lboard_wrap .lboard_card .leaderUp{
	min-width: 50px;
	text-align:center;
	color: #008000;
	font-size: large;
}

.lboard_wrap .lboard_card .leaderDown{
	min-width: 50px;
	text-align:center;
	color: #FF0000;
	font-size: large;
}

.lboard_wrap .lboard_card .leaderNoMove{
	min-width: 50px;
	text-align:center;
	color: #808080;
}
.lboard_wrap .lboard_card .name_bar .bar_wrap{
	width: 100%;
	height: 7px;
	background: #fef9e7;
	margin-top: 5px;
	border-radius: 5px;
	position: relative;
	/* overflow: hidden; */
}

.lboard_wrap .lboard_card .name_bar .bar_wrap .inner_bar {
	position: absolute;
	top: 0;
	left: 0;
	height: 7px;
	background: #F7DC6F;
	border-radius: 5px;
	border-top-right-radius: 0px;
	border-bottom-right-radius: 0px;
}

.lboard_card .goalMarker {
	position:absolute;
	top:0;
	transform:translate(-0%,-100%)
}

.lboard_card .progressMarker {
	position:absolute;
	top:0;
	transform:translate(-50%,-10%);
	z-index:1;
}

.lboard_card .tickMarker {
	position:absolute;
	top:0;
	border-left:1px solid rgba(0,0,0,0.05);
	height:200%;
	transform:translate(50%,-25%)
}

.leaderboardSlotCoinContainer {
	/* height:2.0rem;
	width:2.0rem; */
	padding:0px 5px;
}

.lb_participant {
	font-size: clamp(8px,1.5vw,32px);
}

.ConectivityDetailedViewContainer .lb_participant {
	font-size: clamp(8px,1vw,16px);
}


.lb_score {
	font-size: clamp(8px,2vw,32px);
	font-weight: 500;
}

.ConectivityDetailedViewContainer .lb_score {
	font-size: clamp(8px,1.2vw,24px);
}

.challengePrizesModal {
	/* background-color: var(--soft-grey) !important; */
	padding:clamp(10px,0.5vw,20px) clamp(10px,2vw,20px);
	width:clamp(200px,40vw,600px);
	max-width: 95vw;
	/* height:clamp(200px,30vh,600px);
	max-height: 95vh; */
}


.challengePrizesModal .prizesRow {
	background-color: var(--soft-grey) !important;
	margin:clamp(4px,1vw,10px);
	border: 0.5px solid rgba(0,0,0,0.05);
	border-radius: clamp(4px,1vw,10px);
	box-shadow: 0px 15px 10px -4px rgba(0,0,0,0.2);
	-webkit-box-shadow: 0px 15px 10x -4px rgba(0,0,0,0.2);
	-moz-box-shadow: 0px 15px 10px -4px rgba(0,0,0,0.2);
}

.challengePrizesModal .prizesRank {
	flex-shrink:0;
	flex-grow:0;
	padding:clamp(4px,1vw,12px);

	/* width:clamp(20px,7vw,100px); */
	text-align: center;
}

@media only screen and (max-width: 1520px){  

}

@media only screen and (max-width: 1440px){  

}

@media only screen and (max-width: 1325px){  

}

@media only screen and (max-width: 1200px){  

}

@media only screen and (max-width: 1024px){  

}

@media only screen and (max-width: 993px){  

}

@media only screen and (max-width: 768px){  

}


/* PHONES */

@media  only screen and (max-width: 650px) {
}


@media only screen and (max-width: 500px){  

}

@media only screen and (max-width: 390px){  

}

