.userHomePageWrapper {
    /* padding-bottom: clamp(400px,10vh,600px); */
    padding-bottom: clamp(10px, 2vh, 15px);
}

.userHomeMyConnectivityWrapper {
    gap: 10px
}

.userHomePageInnerWrapper {
    gap: clamp(20px, 3vw, 25px);
}

.myConnectivityCardsRow {
    display: flex;
    flex-direction: row;
    gap: 15;
    overflow-y: auto;
    overflow-x: hidden;
}

.userHomeConectivitiesRow{
    display: flex;
    flex-direction:row;
    flex-wrap: wrap;
    align-items: center;
    justify-content:space-between;
    /*background-color: pink;*/
    /*overflow-x: scroll;             /* Adds a scrollbar if the cards overflow in the X direction */
    /* padding: 10px;                  /* Put some padding between rows so that a card can scale without shifting other rows around */
    /*min-height: 350px; */             
    max-width: clamp(400px,80vw,1400px);
    
}

.dashboardSpotlightContainer {
    width: 40%;
    /* overflow: hidden; */
}

.userDashboardFirstRow {
    width: 100%;
    gap: clamp(20px, 3vw, 25px);
}

.dashboardRingViewContainer {
    width: 60%;
    gap: clamp(20px, 3vw, 25px);
}

.userHomeConectivitiesDisplayWrapper {
    /* max-width: 1200px; */
    position:relative;
    gap: 20px;
}

/* Outer wrapper to give some margin around the inner Select fields, which seem to need an outer wrapper */
.userHomeUpdateGroupOuterWrapper{
    position: absolute;
    top: 20px;
    left: 50%;        /*Center on the invitation row */
    transform: translate(-50%, 0);    /*Shift to right to center the menu itself rather than having it's corner centered in the row */
    width:100%;
    max-width: 1000px;
    padding:0px 10px;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content: flex-start;
    border: 4px solid red;
    border-radius: clamp(4px,0.4vw,10px);
    /*margin:10px;*/
    background-color:white;
    visibility: hidden;
     overflow:hidden; /*Show drop-down options as they extend downward onto other elements*/
   
}


.userHomeUpdateGroupInnerWrapper{
     display:flex;
    flex-direction:column;
    align-items:flex-start;
    justify-content: flex-start;
    /*min-height: 300px;*/
    height:100%;
    width:90%;
    /*overflow:hidden; /*This will hide the elements that overflow when the height is ZERO */
    font-size:1em;
    font-weight:400;
    color: #409cf7;
    /*border-radius: clamp(4px,0.4vw,10px);*/
    background-color: white;
    /*background-color: #fafafa;*/
    padding:10px;
    /*border: 1px solid #6495ED;*/
    /*margin:10px;*/
    /*background-color:red;*/
    /*transition-timing-function: linear;*/
    /*transition: all 1.2s;*/
    /*background-color:yellow;*/
}

.userHomeDynamicMenuBackground {
    background-color: black;
    position:absolute;
    top:0px;
    left:0px;
    height:200px;
    z-index: 1;
    visibility:hidden;
    opacity:0.6;
}

.userHomeRingsWrapper {
    gap: 30px;
}

/* Animated CSS for transition */
.showUpdateInviteFields {
    /*padding:10px;*/
    /*height: 100%;*/
    visibility:visible;
    overflow:visible; /*Show drop-down options as they extend downward onto other elements*/
    /*min-height:150px;*/
    /*height:auto;*/
    
}




/*.hideInviteeFields {*/
/*    height: 0px;*/
/*    overflow:hidden; */
/*    padding: 0px;*/
/*}*/

/* Enabled after timer fire to show Select and allow the DIV to expand */
/*.minHeightEnabled {*/
    /*overflow:visible; /*Show drop-down options as they extend downward onto other elements*/
    /*min-height: 300px;*/

    /*height: auto;*/
    /*background-color: #fafafa;*/
/*}*/

.userHomenUpdateGroupConnector{
    position: absolute;
    top: 0;
    left: 50%;        /*Center on the invitation row */
    transform: translate(-50%, -100%);    /*Shift to right to center; and up 100% of our connector height */
    /*transition: all 0.3s;*/
    margin:0;
    font-size:32px;
    height:40px;
    overflow:hidden;
    color:grey;
}

.userHomeUpdateGroupInnerSelect{
    padding-top:10px;
    width:100%;
    color:white;
    
    /*background-color:red;*/
}


.userHomeUpdateInvitationButtonRow {
    z-index: 1;
    position:absolute;
    top:0;
    right:0;
    /*background-color: pink;*/
    display:flex;
    flex-direction:row;
    align-items:flex-start;
    justify-content:flex-end;
}

.userHomeUpdateInvitationButton {
    font-size:1.5rem;
    font-weight: 800;
    color:white;
    /*width:60px;*/
    /*height:124px;*/
    /*border-radius: clamp(4px,0.4vw,10px);*/
    /*background-color: red;*/
    overflow: hidden;
    margin: 5px 5px 0px 5px;
    display:flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    /*opacity: .6*/
}

.userHomeDivider{
    width:100%;
    border-bottom: 3px solid #a1a6b5;
}


.messageAvatarImageMediumNoPadding {
  /*width: 100%;*/
  height: 38px;
  vertical-align: middle;
  overflow: hidden;
  border-radius: 50%;
  /*padding: 0px 5px;*/
}

.messageInputArea {
    width:100%;
    max-width: 100%;
    padding: 0px 10px;
    position: relative;
    /*background: yellow;*/
}
.messageInputIcon {
    color: #409cf7;
}

.unreadMessageBadge {
	position:absolute;		/*position on the message icon */
	top: 0;
	right: 0;         /*move to right of chat button */
    transform: translate(0%,0);    /*Shift right to center the badge on the right edge of the chat bubble */
	width:clamp(8px,2vw,16px);
	height:clamp(8px,2vw,16px);
	margin: 0px 0px;
	/* font-size: 0.7rem; */
	/*padding: 2px 5px; */
	color: white;
	/*text-shadow: 2px 2px 10px red;*/
	background-color: red;
	border-radius: 50%;
	/*display:inline-block;*/
    /*min-width:40px;*/
    /*height:25px;*/
    /*border:solid 2px #409cf7;*/
    /* display:flex;
    align-items:center;
    justify-content:center; */
    /* font-weight: 600; */
    z-index: 2;

    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);*/
    
}

.messageButtonWrapper {
    background-color: pink;
	/*width: 140px;*/
	position:relative;	/*parent for the rank to be absolute */
	/*padding-right: 20px;	/*Shift the image over so we can offset the unread message cout */
}


.userHomeInviteeAvatarRowOverlapping {
    display:flex;
    flex-direction: row;
    font-size:1rem;
}



/*.userHomeInvitationButtonAnchor:hover {*/
/*    transform: translate(-50%,0) scale(1.15);   */
/*    font-weight: 700;*/
/*}*/

.userHomeButtonStyle3 {
    cursor: pointer;
    color:white;
    outline: none;
    border-radius: 0.3vw;
    padding-left: 0.3rem;
    padding-right: 0.3rem;
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    background-color: #5DADE2;
    border: 1px solid #5DADE2;
    font-family: 'Abel', sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
}


.userHomeButtonStyle3:hover {
    color: #5DADE2;
    background-color: white;
    transition: all 0.5s;
    font-weight: 700;
}

.numberOfInvitationsBadge {
	position:absolute;		/*position on the message icon */
	top: 5px;
	left: 100px;
	margin: 0 0px 0px;
	font-size: 0.8rem;
	padding: 2px 5px; 
	color: white;
	background-color: #82E0AA;
	border-radius: 50%;
    /*text-align:center;*/
    /*vertical-align: middle;*/
    font-weight: 600;  
    height: 20px;
    width: 20px;
    display:flex;
    flex-direction:column;
    justify-content:center;
    align-items: center;
    z-index: 1;
}


.userHomeProcessMultipleInvitationsButtonAnchor {
    position: absolute;
    top: 50%;                        /* 1/2 way down the row */
    right: 0;
    transform: translate(150%, -50%);    /*Shift up to center the icon in the row */
   
    /*background-color: red;*/
}

/* Animated CSS for transition */
.showUserHomenvitationSubMenu {
    /*padding:10px;*/
    /*height: 300px;*/

}


/* Animated CSS for transition */
.showUserHomenvitationSubMenuConnector {
    /*padding:10px;*/
    height: 28px;
    /*background-color:red;*/

}

.userHomeInvitationSubMenuConfirmation {
    background-color: white;  
    overflow:hidden; /*This will hide the elements that overflow when the height is ZERO */
    height: 0px;
    /*transition: all 0.3s;*/
    color:#5DADE2;
    border-radius:10px;
    margin:0px 20px;
    width:200px;
}


/* Animated CSS for transition */
.showUserHomeInvitationSubMenuConfirmation {
    /*padding:10px;*/
    height: 100%;
}


.userHomeInvitationSubMenu {
    font-size: 14px;
    font-weight: 400;
    color:  #FFFFFF;
    background-color: #BB8FCE;
    width:100%;
    /*margin: 20px;*/
    /*padding: 20px 20px;*/
    border-radius: 20px;
    /*margin-bottom: 20px;*/
    /*margin-left: 20px;*/
    /*transition: all 0.3s;  */
    overflow:hidden;
    z-index:2;
    

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;

}

.userHomeInvitationSubMenuConfirmationText{
    /*font-family: 'Abel', sans-serif;*/
    padding: 5px 15px;
    font-weight: 500;
    font-size: 0.9rem;
    /*margin-left: 20px;*/
}

/* connectivity done modal style start */

.connectivityDoneModal {
    position: absolute;
    top:0px;
    left:0px;
    height:100vh;
    width:100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.doneModalCard {
    min-width: clamp(450px, 50vw, 600px);
    max-width:clamp(600px,50vw,1000px);
    border-radius: clamp(8px,0.8vw,15px);
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: clamp(15px, 5vw, 30px);
    overflow: hidden;
}

.doneModalTitleWrapper {
    padding-bottom: clamp(5px, 1.5vh, 8px);
    border-bottom: 1px solid var(--dust-grey);
}

.doneModalCommonPadding {
    padding-top: clamp(10px, 2vh, 15px);
}

.noteWrapper {
    padding: clamp(2px, 1vh, 5px) clamp(8px, 3vw, 12px);
    background-color: rgba(0, 0, 0, 0.45);
    border-radius: clamp(2px, 2vw, 5px);
}

.doneModalRightBorderWrapper {
    padding-right: clamp(10px, 3vw, 15px);
    border-right: 1px solid var(--dust-grey);
}

.participateOuterWrapper {
    padding-top: clamp(20px, 5vh, 30px);
}

.participateActionBtnWrapper {
    gap: clamp(25px, 7vw, 35px);
}

.participateUserWrapperGap {
    gap: clamp(10px, 2vh, 15px);
}

.participateInnerWrapper {
    flex-grow: 1;
    height: 100%;
    overflow: auto;
    padding: 5px;
    max-height: clamp(200px, 35vh, 375px);
}

.participateCard {
    padding: clamp(8px, 2vw, 15px);
    border-radius: clamp(4px, 1.5vw, 8px);
    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}

.activeParticipateCard {
    border: 2px solid var(--BALANCE-blue);
}

.participateCard:hover{
    box-shadow: none;
    background-color: rgba(0, 0, 0, 0.08);
}

.participateFooterActionBtnWrapper {
    gap: clamp(5px, 1vw, 105px);
}

/* connectivity done modal style end */

@media only screen and (max-width: 993px){
    .doneModalCard {
        min-width: 100% !important;
    }
}
