.AnimationTimeBar {
    width: 100%;
    height: 120px;
    padding: 40px 0px 0px 0px; 
    position: relative;
    /*background-color: red;*/
}


/*https://mui.com/customization/how-to-customize/*/


/*.TimeBarStyle {*/
/*    height:20px;*/
/*}*/

/*.TimeBarStyle:disabled {*/
/*    color:red;*/
/*}*/


/*.MenuItem {*/
/*  color: black;*/
/*}*/

/* Increase the specificity */
/*.MenuItem.Mui-disabled {*/
/*  color: blue;*/
/*}*/

