
.creditCardForm {
    margin:10px 0px;
    
}

 .validateButton
{
    display: inline-block; /*Display inline and maintain block characteristics.*/
    vertical-align: central; /*Makes sure all the divs are correctly aligned.*/
    white-space: normal; /*Prevents child elements from inheriting nowrap.*/
    color:white;
    background-color:#6495ED;
}
