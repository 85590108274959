.conectivityEditorClass {
    padding-top: 50px;
    /*min-width: 80%;*/
    max-width: 1600px;
    min-height: 1600px;
}

.AdminConectivitiesInstructionsRowWrapper{
    display:flex;
    align-items:flex-start;
    justify-content:space-between;
    padding: 10px 20px 0px 20px;
    /*background-color:yellow;*/
    width: 100%;
    flex-wrap:wrap;
}

.AdminConectivitiesModalTitle {
    color: white;
    font-size: 2.0rem;
    font-weight: 500;
}

.AdminConectivitiesModalPublishedKnob {
    /*background-color: yellow;*/
    font-size: 1.4rem;
    font-weight: 500;
    border-radius: clamp(4px,0.4vw,10px);
    height: 40px;
    width:auto;
    color:white;
    text-align: center;
    vertical-align: middle;
    padding: 0px 10px;
}

.AdminConectivitiesModalPublishedKnobGrid {
    /*background-color: yellow;*/
    font-size: 0.8rem;
    font-weight: 500;
    border-radius: 5px;
    height: 30px;
    width:100%;
    color:white;
    display:flex;
    align-items: center;
    justify-content: center;
    /*text-align: center;*/
    /*vertical-align: middle;*/
    padding: 0px 10px;
}

.adminConectivitiesOverviewInput{
    min-height: 150px;
}

.modalTemplateSelectionInnerCard {
    position:relative;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:center;
    background-color: white;
    opacity: 1;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    margin-top: clamp(4px,1vh,10px);
    /*margin: 100px 200px;  */
    /*margin-left: 100px;*/
    padding: 10px 20px;
    color: black;
}

.adminConectivitiesButtonStyle1 {
    /*border: 1px solid #BB8FCE;*/
    border:none;
    cursor: pointer;
    color:#fff;
    outline: none;
    /*border-radius: 0.3vw;*/
    border-radius: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background-color: #82E0AA;
    /*background-color: rgba(187, 143, 206, 1);*/
    font-family: 'Abel', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    transition: all 0.2s;
}


.adminConectivitiesButtonStyle1:hover {
    transform: scale(1.15);   
    font-weight: 700;
}


.adminConectivitiesButtonStyle2 {
    /*border: 1px solid #BB8FCE;*/
    border:none;
    cursor: pointer;
    color:#fff;
    outline: none;
    border-radius: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background-color: #F7DC6F;
    /*background-color: rgba(187, 143, 206, 1);*/
    font-family: 'Abel', sans-serif;
    font-weight: 400;
    font-size: 1.3rem;
    transition: all 0.2s;
}

.adminConectivitiesButtonStyle2:hover {
    transform: scale(1.15);   
    font-weight: 700;
}


.adminConectivitiesButtonStyle1:hover {
    transform: scale(1.15);   
    font-weight: 700;
}


.adminConectivitiesRowButtonStyle1 {
    /*border: 1px solid #BB8FCE;*/
    border:none;
    cursor: pointer;
    color:#fff;
    outline: none;
    border-radius: 5px;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 0.3rem;
    padding-bottom: 0.3rem;
    background-color: #F7DC6F;
    /*background-color: rgba(187, 143, 206, 1);*/
    font-family: 'Abel', sans-serif;
    font-weight: 500;
    font-size: 1.2rem;
    transition: all 0.2s;
    height:32px;
    width:auto;
    color:white;
    display:flex;
    align-items:center;
    justify-content:center;
}

.adminConectivitiesRowButtonStyle1:hover {
    transform: scale(1.15);   
    font-weight: 700;
}


.templateConectivityIconWrapper{
    height: 150px;
    width: 125px;
    border: 1px solid blue;
    background-color: #fff;
    border-radius: clamp(4px,0.4vw,10px);
    padding:10px 10px;
    overflow:hidden;
    cursor:pointer;
    transition: all 0.2s;
}

.templateConectivityIconWrapper:hover{
    transform: scale(1.15);   
}

.adminConectivitiesTemplateButtonsWrapper {
    display:flex;
    align-items: center;
    justify-content: center; 
    /*margin: 0px 50px;    */
    flex-wrap:wrap;  
    /*background:green;*/
    height: 60px;
    width:100%;
}

.scaledConectivityPreview {
  transform: scale(0.5); 
  /*max-height:50%;*/
}


.adminConectivitiesTabsWrapper {
    display:flex;
    flex-direction:column;
    align-items: center;
    justify-content:flex-start;
    /*background-color: pink;*/
    width:auto;
    margin-top:2.0rem;
}


.adminConectivitiesTabsHeader {
    /*padding-bottom: 1rem;*/
    /*background-color: yellow;*/
    /*border-top: 1px solid grey;*/
    /*border-right: 1px solid grey;*/
    /*border-left: 1px solid grey;*/
    background-color:#6495ED;
    /*border-bottom: 1px solid #6495ED;*/
    padding-bottom:10px;
    /*width:100%;*/
    
    /*-webkit-box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49); */
    /*box-shadow: 11px 8px 15px 2px rgba(0,0,0,0.49);    */
}


