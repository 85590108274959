
.notificationCardContainer {
    position:relative;
    height:auto;
    min-height: clamp(60px,8vh,100px);
    width: clamp(200px,50vw,500px);
    border-radius: 16px;
    margin-top: clamp(8px,2vh,20px);        /*Space between cards */
    padding:clamp(4px,1vw,10px);
    background:white;
    
    cursor: pointer;
    transition: all 0.6s;

    box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
    -webkit-box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
}



